/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Registration form
import RegisterForm from "layouts/authentication/components/RegisterForm";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Components
import AuthorizeUser from "layouts/authentication/is-authorized/non-protected-pages";

/**
 * Sign-up page component
 * @returns {JSX.Element}
 */
function Cover() {
  return (
    <AuthorizeUser>
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Присоединяйтесь
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Введите ваши данные для регистрации в системе
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <RegisterForm />
          </MDBox>
        </Card>
        <MDBox py={4} />
      </CoverLayout>
    </AuthorizeUser>
  );
}

export default Cover;
