/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";

// Models
import Product from "service/Models/Product";
import Cash from "service/Models/Cash";

// react
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import NotificationItem from "examples/Items/NotificationItem";
import ProductStatus from "layouts/products/components/ProductStatus";

// utilities
import { compareDates, getCurrentDate, dateWord } from "service/utilities";

export default function data(products, productsUpdateCallback, modalCallback) {
  const navigate = useNavigate();
  const rows = [];
  const [transactionChecked, setTransactionChecked] = useState({
    value: true,
    isDirty: false,
  });
  // const [products, setProducts] = useState(_products);
  const [menuOwner, setMenuOwner] = useState(null);

  const isProductInRent = (product) => {
    const nearestInterval = product.rent_objects[0];
    if (nearestInterval)
      return (
        compareDates(getCurrentDate(), ">=", nearestInterval.rent.rent_date) &&
        compareDates(getCurrentDate(), "<=", nearestInterval.return_date)
      );
    return false;
  };

  const isProductOverdue = (product) => {
    const nearestInterval = product.rent_objects[0];
    if (nearestInterval) return compareDates(getCurrentDate(), ">", nearestInterval.return_date);
    return false;
  };

  // handle context menu open
  const handleOpenMenu = (owner) => setMenuOwner(owner);

  // handle close context menu
  const handleCloseMenu = () => setMenuOwner(null);

  // handle delete product
  const handleDeleteProduct = () => {
    modalCallback({
      content: (
        <DialogContent>
          <DialogContentText>
            Вы действительно хотите безвозвратно удалить данный товар?
          </DialogContentText>
          <DialogContentText>Восстановить его уже не удастся.</DialogContentText>
        </DialogContent>
      ),
      agreeText: "Удалить",
      agreeColor: "error",
      disagreeColor: "light",
      async agreeAction() {
        setMenuOwner(null);
        const response = await Product.Delete(menuOwner.id);
        if (response) {
          const nProducts = [...products];
          products.forEach((product, index) => {
            if (product.id === menuOwner.id) nProducts.splice(index, 1);
          });
          productsUpdateCallback(nProducts);
        } else {
          // TODO сделать обработку ошибки через уведомление и/или модальное окно
          console.log(response);
        }
      },
      disagreeAction: () => modalCallback(null),
    });
  };

  const createTransactionRequest = async (product) => {
    const response = await Cash.Create({
      date: getCurrentDate(),
      note: `Подготовка товара ${product.name}`,
      value: -parseFloat(product.prepare_price),
      products: [
        {
          ...product,
          currentPrice: {
            price: -parseFloat(product.prepare_price),
            days: null,
          },
        },
      ],
    });
    if (response.success !== true) console.log(response);
  };

  const changeStatusDialogInfo = () => ({
    content: (
      <DialogContent>
        {menuOwner.active ? (
          <DialogContentText>
            {isProductInRent(menuOwner) || isProductOverdue(menuOwner)
              ? "Вы не можете изменить статус товара пока он находиться в аренде."
              : "Вы действительно хотите изменить готовность данного товара?"}
          </DialogContentText>
        ) : (
          <DialogContentText>
            Вы не можете изменить статус товара пока он не будет активирован.
          </DialogContentText>
        )}
        {Boolean(parseFloat(menuOwner.prepare_price)) &&
          !menuOwner.ready &&
          !isProductInRent(menuOwner) &&
          !isProductOverdue(menuOwner) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={transactionChecked.value}
                  onClick={() =>
                    setTransactionChecked({
                      value: !transactionChecked.value,
                      isDirty: true,
                    })
                  }
                />
              }
              label={
                <MDTypography variant="caption" color="text">
                  Произвести операцию списания стоимости подготовки
                </MDTypography>
              }
            />
          )}
      </DialogContent>
    ),
    agreeText:
      menuOwner.active && !isProductInRent(menuOwner) && !isProductOverdue(menuOwner)
        ? "изменить"
        : "принять",
    agreeColor:
      menuOwner.active && !isProductInRent(menuOwner) && !isProductOverdue(menuOwner)
        ? "success"
        : "info",
    disagreeColor: "light",
    async agreeAction() {
      setMenuOwner(null);
      if (menuOwner.active && !isProductInRent(menuOwner) && !isProductOverdue(menuOwner)) {
        const product = { ...menuOwner };
        product.ready = !product.ready;
        const response = await Product.Update(product.id, product, product.images);
        if (response.success === true) {
          createTransactionRequest(product);
          const rowIndex = Product.IndexOfProductById([...products], response.product.id);
          const nProducts = [...products];
          nProducts[rowIndex] = response.product;
          productsUpdateCallback([...nProducts]);
        } else {
          console.log(response.data);
        }
      }
    },
    disagreeAction: () => {
      modalCallback(null);
      setTransactionChecked({
        value: true,
        isDirty: false,
      });
    },
  });

  // handle change status
  const handleChangeStatus = () => modalCallback(changeStatusDialogInfo());

  useEffect(() => {
    if (transactionChecked.isDirty) modalCallback(changeStatusDialogInfo());
  }, [transactionChecked]);

  const handleTrashProduct = () => {
    modalCallback({
      content: (
        <DialogContent>
          <DialogContentText>Вы действительно хотите удалить данный товар?</DialogContentText>
        </DialogContent>
      ),
      agreeText: "удалить",
      agreeColor: "error",
      disagreeColor: "light",
      async agreeAction() {
        setMenuOwner(null);
        const response = await Product.Trash(menuOwner.id);
        if (response.json.success === true) {
          const nProducts = [...products];
          products.forEach((product, index) => {
            if (product.id === menuOwner.id) nProducts[index] = response.json.product;
          });
          productsUpdateCallback(nProducts);
        } else {
          console.log(response);
        }
      },
      disagreeAction: () => modalCallback(null),
    });
  };

  const handleRestoreProduct = () => {
    modalCallback({
      content: (
        <DialogContent>
          <DialogContentText>Вы действительно хотите восстановить данный товар?</DialogContentText>
        </DialogContent>
      ),
      agreeText: "восстановить",
      agreeColor: "success",
      disagreeColor: "light",
      async agreeAction() {
        setMenuOwner(null);
        const response = await Product.Restore(menuOwner.id);
        if (response.json.success === true) {
          const nProducts = [...products];
          products.forEach((product, index) => {
            if (product.id === menuOwner.id) nProducts[index] = response.json.product;
          });
          productsUpdateCallback(nProducts);
        } else {
          console.log(response);
        }
      },
      disagreeAction: () => modalCallback(null),
    });
  };

  // product component
  const ProductThumb = ({ image, name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar variant="rounded" src={image} name={name} size="sm" bgColor="light">
        <Icon fontSize="small" color="disabled">
          no_photography
        </Icon>
      </MDAvatar>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography
          component={Link}
          to={`/crm/products/${id}`}
          variant="button"
          color="text"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  // group component
  const Group = ({ name }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="button" color="text" fontWeight="regular">
        {name}
      </MDTypography>
    </MDBox>
  );

  // render context menu
  const renderMenu = () =>
    menuOwner.deleted_at === null ? (
      <Menu
        anchorEl={menuOwner ? menuOwner.anchor : null}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuOwner)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <NotificationItem onClick={handleTrashProduct} icon={<Icon>delete</Icon>} title="Удалить" />
        <NotificationItem
          onClick={handleChangeStatus}
          icon={<Icon>change_circle</Icon>}
          title="Изменить статус"
        />
        <NotificationItem
          onClick={() => navigate(`/crm/products/${menuOwner.id}/update`)}
          icon={<Icon>edit</Icon>}
          title="Редактировать"
        />
      </Menu>
    ) : (
      <Menu
        anchorEl={menuOwner ? menuOwner.anchor : null}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuOwner)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <NotificationItem
          onClick={handleRestoreProduct}
          icon={<Icon>restore</Icon>}
          title="Восстановить"
        />
        <NotificationItem
          onClick={handleDeleteProduct}
          icon={<Icon>delete_forever</Icon>}
          title="Удалить"
        />
      </Menu>
    );

  const renderBooking = (product) => {
    if (product.rent_objects.length) {
      if (isProductInRent(product))
        return (
          <MDTypography variant="button" color="text" fontWeight="regular">
            До {dateWord(product.rent_objects[0].return_date)}
          </MDTypography>
        );
      if (!isProductOverdue(product))
        return (
          <MDTypography variant="button" color="text" fontWeight="regular">
            Свободен до {dateWord(product.rent_objects[0].rent.rent_date)}
          </MDTypography>
        );
    }
    return (
      <MDTypography variant="button" color="text" fontWeight="regular">
        --/--/--
      </MDTypography>
    );
  };

  if (products.length !== 0) {
    products
      .filter((product) => product.view === true)
      .forEach((product, i) => {
        rows[i] = {
          id: {
            link: `/crm/products/${product.id}`,
            jsx: (
              <MDTypography
                component={Link}
                to={`/crm/products/${product.id}`}
                variant="button"
                color="inherit"
                fontWeight="medium"
              >
                #{product.id}
              </MDTypography>
            ),
          },
          product: {
            link: `/crm/products/${product.id}`,
            jsx: (
              <ProductThumb
                image={product.main_image ? product.main_image.path : ""}
                name={product.name}
                id={product.id}
              />
            ),
          },
          group: {
            link: `/crm/products/${product.id}`,
            jsx: <Group name={product.group.name} id={product.group.id} />,
          },
          status: {
            link: `/crm/products/${product.id}`,
            jsx: (
              <ProductStatus
                product={{
                  ...product,
                  in_rent: isProductInRent(product),
                  overdue: isProductOverdue(product),
                }}
              />
            ),
          },
          employed: {
            link: `/crm/products/${product.id}`,
            jsx: renderBooking(product),
          },
          action: (
            <MDBox>
              <MDTypography
                onClick={(e) => handleOpenMenu({ ...product, anchor: e.target })}
                component="div"
                color="text"
                sx={{ cursor: "pointer" }}
              >
                <Icon>more_vert</Icon>
              </MDTypography>
              {menuOwner !== null && renderMenu()}
            </MDBox>
          ),
        };
      });
  }

  return {
    columns: [
      { Header: "ID", accessor: "id", width: "5%", align: "left", Cell: ({ value }) => value.jsx },
      {
        Header: "Товар",
        accessor: "product",
        width: "35%",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      { Header: "Группа", accessor: "group", align: "left", Cell: ({ value }) => value.jsx },
      { Header: "Статус", accessor: "status", align: "center", Cell: ({ value }) => value.jsx },
      {
        Header: "Забронирован",
        accessor: "employed",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      { Header: "Действия", accessor: "action", align: "right" },
    ],

    rows,
  };
}
