// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import DataTable from "examples/Tables/DataTable";
import Filter from "examples/Filter";

// Data
import productGroupsTableData from "layouts/product_groups/data/productGroupsTableData";

// Models
import ProductGroup from "service/Models/ProductGroup";

// React
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// Components
import CustomizableDialog from "layouts/products/components/CustomizableDialog";

function ProductGroups() {
  const [customizableDialogInfo, setCustomizableDialogInfo] = useState(null);
  const updateCustomizableDialogInfo = (value) => setCustomizableDialogInfo(value);
  const handleCustomizableDialogClose = () => setCustomizableDialogInfo(null);

  const [groups, setGroups] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [filters, setFilters] = useState([
    {
      name: "statusFilter",
      label: "По активности",
      value: {
        label: "Все группы",
        id: 0,
      },
      options: [
        {
          label: "Все группы",
          id: 0,
        },
        {
          label: "Активные",
          id: 1,
        },
        {
          label: "Неактивные",
          id: 2,
        },
        {
          label: "Архивные",
          id: 3,
        },
      ],
      select: true,
    },
  ]);
  const updateGroupsCallback = (value) => {
    setGroups(value);
    setFilters([...filters]);
  };
  const updateFiltersCallback = (value) => setFilters(value);

  const handleResetFilters = () => {
    setSearchField("");
    setFilters([
      {
        name: "statusFilter",
        label: "По активности",
        value: {
          label: "Все группы",
          id: 0,
        },
        options: [
          {
            label: "Все группы",
            id: 0,
          },
          {
            label: "Активные",
            id: 1,
          },
          {
            label: "Неактивные",
            id: 2,
          },
          {
            label: "Архивные",
            id: 3,
          },
        ],
      },
    ]);
  };

  const handleInputChange = (event) => {
    setSearchField(event.target.value);
    setFilters([...filters]);
  };

  // get groups
  useEffect(() => {
    const getGroups = async () => {
      const response = await ProductGroup.GetAll();
      if (response.success === true) {
        setGroups(response.groups);
        setFilters([...filters]);
        return true;
      }
      // TODO добавить обработку ошибки
      console.log(response);
      return false;
    };

    getGroups();
  }, []);

  const { columns, rows } = productGroupsTableData(
    groups,
    updateGroupsCallback,
    updateCustomizableDialogInfo
  );

  // filter out
  useEffect(() => {
    // reset the visibility of all products
    groups.forEach((group, index) => {
      groups[index].view = false;
    });

    if (searchField !== "") {
      groups.forEach((group, index) => {
        if (group.name.toLowerCase().indexOf(searchField.toLowerCase()) !== -1)
          groups[index].view = true;
      });
    } else {
      groups.forEach((group, index) => {
        groups[index].view = true;
      });
    }
    console.log(groups);
    groups.forEach((group, index) => {
      if (group.view === true) {
        if (filters[0].value.id === 0 && !group.deleted_at) groups[index].view = true;
        else if (filters[0].value.id === 1 && group.active && !group.deleted_at)
          groups[index].view = true;
        else if (filters[0].value.id === 2 && !group.active && !group.deleted_at)
          groups[index].view = true;
        else if (filters[0].value.id === 3 && group.deleted_at) groups[index].view = true;
        else groups[index].view = false;
      }
    });

    setGroups([...groups]);
  }, [filters]);

  return (
    <AuthorizeUser>
      {Boolean(customizableDialogInfo) && (
        <CustomizableDialog
          message={customizableDialogInfo.message}
          agreeColor={customizableDialogInfo.agreeColor}
          agreeText={customizableDialogInfo.agreeText}
          agreeAction={customizableDialogInfo.action}
          disagreeAction={handleCustomizableDialogClose}
        />
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Группы товаров
                  </MDTypography>
                  <Link to="/crm/groups/create">
                    <MDButton variant="outlined" color="white" size="small">
                      <Icon>add</Icon>&nbsp;Новая группа
                    </MDButton>
                  </Link>
                </MDBox>
                <Grid
                  container
                  px={3}
                  mt={3}
                  columnSpacing={2}
                  rowSpacing={0.5}
                  alignItems="flex-end"
                >
                  <Grid item xs={12} textAlign="right">
                    <MDButton variant="outlined" size="small" sx={{ padding: "0" }}>
                      <Icon color="error">refresh</Icon>&nbsp;
                      <MDTypography
                        fontWeight="medium"
                        variant="caption"
                        color="error"
                        onClick={handleResetFilters}
                        sx={{ cursor: "pointer" }}
                      >
                        Сбросить фильтры
                      </MDTypography>
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      name="searchField"
                      label="Поиск по названию"
                      type="text"
                      size="small"
                      sx={{ width: "100%" }}
                      value={searchField}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Filter
                      widthParams={{ xs: 12, md: 3 }}
                      filters={filters}
                      onChangeFilters={updateFiltersCallback}
                    />
                  </Grid>
                </Grid>
                <MDBox>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={{
                      defaultValue: 20,
                      entries: [5, 10, 20, 50, 100],
                    }}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default ProductGroups;
