/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";

// react
import { Link } from "react-router-dom";

export default function data(products) {
  const rows = [];

  // product component
  const ProductThumb = ({ image, name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar variant="rounded" src={image} name={name} size="sm" bgColor="light">
        <Icon fontSize="small" color="disabled">
          no_photography
        </Icon>
      </MDAvatar>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography
          component={Link}
          to={`/crm/products/${id}`}
          variant="button"
          color="inherit"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  if (products !== undefined) {
    products.forEach((product, i) => {
      rows[i] = {
        product: (
          <ProductThumb
            image={product.main_image ? product.main_image.path : ""}
            name={product.name}
            id={product.id}
          />
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={product.active ? "активен" : "не активен"}
              color={product.active ? "success" : "dark"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        booked: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            --/--/--
          </MDTypography>
        ),
      };
    });
  }

  return {
    columns: [
      { Header: "Товар", accessor: "product", width: "35%", align: "left" },
      { Header: "Статус", accessor: "status", align: "center" },
      { Header: "Забронирован", accessor: "booked", align: "center" },
    ],

    rows,
  };
}
