// mui components
import Icon from "@mui/material/Icon";

// material dashboard 2 components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// proptypes
import PropTypes from "prop-types";

function ProductStatus({ product }) {
  if (!product.active)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="error">
          not_interested
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Не активен
        </MDTypography>
      </MDBox>
    );
  if (product.rent_objects.length) {
    if (product.in_rent)
      return (
        <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
          <Icon fontSize="inherit" color="info">
            circle
          </Icon>
          &nbsp;
          <MDTypography variant="button" fontWeight="regular" color="text">
            В аренде
          </MDTypography>
        </MDBox>
      );
    if (product.overdue)
      return (
        <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
          <Icon fontSize="inherit" color="error" sx={{ transform: "rotate(45deg)" }}>
            square
          </Icon>
          &nbsp;
          <MDTypography variant="button" fontWeight="regular" color="text">
            Просрочен
          </MDTypography>
        </MDBox>
      );
  }
  if (!product.ready)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="warning">
          square
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Не готов
        </MDTypography>
      </MDBox>
    );
  return (
    <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
      <Icon fontSize="inherit" color="success">
        circle
      </Icon>
      &nbsp;
      <MDTypography variant="button" fontWeight="regular" color="text">
        Готов
      </MDTypography>
    </MDBox>
  );
}

// RenewalObjectForm prop-types
ProductStatus.propTypes = {
  product: PropTypes.shape({
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([0, 1])]).isRequired,
    ready: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([0, 1])]).isRequired,
    rent_objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
    in_rent: PropTypes.bool.isRequired,
    overdue: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ProductStatus;
