// mui components
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// Components
import CustomDialog from "examples/CustomDialog";

// proptypes
import PropTypes from "prop-types";

// utilities
import { reduceAccByColName, getCurrentDate } from "service/utilities";

// Models
import Cash from "service/Models/Cash";
import RentObject from "service/Models/RentObject";

function CloseRentDialog({ open, rent, onAgreeSuccess, onAgreeFailure, onClose }) {
  const closeRentDialogContent = () => (
    <DialogContent>
      <DialogContentText mb={1}>
        Вы действительно хотите закрыть данный договор? В кассу будет внесена недостающая по
        договору сумма и все арендованные товары будут отмечены как возвращенные.
      </DialogContentText>
    </DialogContent>
  );

  const returnProductRequest = (object) =>
    RentObject.Update({ ...object, is_returned: true }).then((response) => {
      if (response.success === true) return response.rent_object;
      throw new Error(response);
    });

  const returnProductsRequest = () => Promise.all(rent.objects.map(returnProductRequest));

  const createTransactionRequest = () => {
    const value =
      reduceAccByColName(rent.objects, "price") +
      parseFloat(rent.shipping_cost || 0) -
      reduceAccByColName(rent.cash, "value");
    if (value !== 0) {
      return Cash.Create({
        value,
        note: `Оплата договора аренды №${rent.id}`,
        date: getCurrentDate(),
        rent_id: rent.id,
      }).then((response) => {
        if (response.success === true) return response.cash;
        throw new Error(response);
      });
    }
    return null;
  };

  const freshDialogInfo = () => ({
    content: closeRentDialogContent(),
    title: "Подтвердите действие",
    buttons: [
      {
        name: "Отменить",
        color: "light",
        action: () => {
          onClose();
        },
      },
      {
        name: "Подтвердить",
        color: "success",
        action: () => {
          returnProductsRequest()
            .then(createTransactionRequest)
            .then((response) => {
              onAgreeSuccess(response);
            })
            .catch((err) => {
              onAgreeFailure(err);
              console.error(err);
            });
        },
      },
    ],
    onClose: () => {
      onClose();
    },
  });

  return <CustomDialog open={open} {...freshDialogInfo()} />;
}

// CancelRentDialog default prop-types
CloseRentDialog.defaultProps = {
  onAgreeSuccess: () => {},
  onAgreeFailure: () => {},
};

// CancelRentDialog prop-types
CloseRentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
    shipping_cost: PropTypes.string.isRequired,
  }).isRequired,
  onAgreeSuccess: PropTypes.func,
  onAgreeFailure: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default CloseRentDialog;
