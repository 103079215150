import { API_DOMAIN, GET_LOCAL_ACCESS_TOKEN } from "service/requests/requestConfig";

export default class User {
  /*
   * Read users
   * */
  static async Read(userId = null) {
    const url = `${API_DOMAIN}api/user${userId ? `/${userId}` : ""}`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
}
