import {
  API_DOMAIN,
  GET_LOCAL_ACCESS_TOKEN,
  REMOVE_LOCAL_TOKEN,
} from "service/requests/requestConfig";

export default class Product {
  /**
   * Get clients of company
   */
  static async Read(clientId = null) {
    const url = `${API_DOMAIN}api/clients${clientId ? `/${clientId}` : ""}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Get clients of company
   */
  static async ReadRents(clientId, rentId = null) {
    const url = `${API_DOMAIN}api/clients/${clientId}/rents${rentId ? `/${rentId}` : ""}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Create new client
   *
   */
  static async Create(clientData) {
    const url = `${API_DOMAIN}api/clients`;
    const response = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(clientData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Update client
   */
  static async Update(id, clientData) {
    const url = `${API_DOMAIN}api/clients/${id}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(clientData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Trash client
   */
  static async Trash(id) {
    const url = `${API_DOMAIN}api/clients/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Restore client
   */
  static async Restore(id) {
    const url = `${API_DOMAIN}api/clients/${id}/restore`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Delete client
   */
  static async Delete(id) {
    const url = `${API_DOMAIN}api/clients/${id}/force_delete`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    return response.ok;
  }

  /**
   * Find client index in product array by id
   */
  static IndexOfClientById(clientsArray, clientId) {
    let searchResult = -1;
    clientsArray.forEach((client, index) => {
      if (client.id === clientId) {
        searchResult = index;
      }
    });
    return searchResult;
  }
}
