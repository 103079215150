// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import CreateTransactionForm from "layouts/cash/components/CreateTransactionForm";

function CreateTransaction() {
  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title="Новая операция" />
        <MDBox pt={2} px={2} mx="auto" sx={{ maxWidth: "md", width: "100%" }}>
          <Card>
            <MDBox pt={2} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Создание новой операции
              </MDTypography>
            </MDBox>
            <CreateTransactionForm />
          </Card>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default CreateTransaction;
