// todo свериться с правилами валидации на сервере
const inputs = {
  days: {
    defaultValue: 0,
    validationNeeded: true,
    validationRules: ["between:1, 1000", "fixed:0", "required"],
    attribute: "'Дни'",
  },
  price: {
    defaultValue: "0.0",
    validationNeeded: true,
    validationRules: ["between:0.01, 100000", "fixed:2", "required"],
    attribute: "'Цена'",
  },
};

export default inputs;
