/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// utilities
import { dateWord, reduceAccByColName, compareDates } from "service/utilities";

// react
import { Link } from "react-router-dom";

// material dashboard 2 components
import MDTypography from "components/MDTypography";

export default function data(rents) {
  const rows = [];

  const isRentClosed = (rent) => rent.objects.filter((object) => !object.is_returned).length === 0;

  const getReturnDate = (rent) => {
    const objects = [...rent.objects];
    const maxReturnDateObject = objects.reduce((acc, curr) =>
      compareDates(acc.return_date, ">", curr.return_date) ? acc : curr
    );
    return maxReturnDateObject.return_date;
  };

  if (rents.length !== 0) {
    rents.forEach((rent) => {
      rows[rows.length] = {
        id: {
          link: `/crm/rents/${rent.id}`,
          jsx: (
            <MDTypography
              component={Link}
              to={`/crm/rents/${rent.id}`}
              variant="button"
              fontWeight="regular"
              color="text"
            >
              {rent.id}
            </MDTypography>
          ),
        },
        rentDate: {
          link: `/crm/rents/${rent.id}`,
          jsx: dateWord(rent.rent_date),
        },
        returnDate: {
          link: `/crm/rents/${rent.id}`,
          jsx: dateWord(getReturnDate(rent)),
        },
        price: {
          link: `/crm/rents/${rent.id}`,
          jsx: reduceAccByColName(rent.objects, "price"),
        },
        isClose: {
          link: `/crm/rents/${rent.id}`,
          jsx: isRentClosed(rent) ? "Да" : "Нет",
        },
      };
    });
  }

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left", Cell: ({ value }) => value.jsx },
      {
        Header: "Дата аренды",
        accessor: "rentDate",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Дата возврата",
        accessor: "returnDate",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      { Header: "Стоимость", accessor: "price", align: "left", Cell: ({ value }) => value.jsx },
      { Header: "Закрыт", accessor: "isClose", align: "left", Cell: ({ value }) => value.jsx },
    ],
    rows,
  };
}
