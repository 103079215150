// mui components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";

// material dashboard 2 components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";

// proptypes
import PropTypes from "prop-types";

// utilities
import { dateWord } from "service/utilities";

// React
import { useState, useEffect } from "react";

// Validator
import { Validate } from "service/validator";

// data
import rentObjectsTableData from "layouts/rents/data/rentObjectTableData";

function ConfirmationRentDialog({
  open,
  rentInfo,
  updateRentInfoCallback,
  onCloseAction,
  onAcceptAction,
}) {
  const { columns, rows } = rentObjectsTableData(rentInfo);
  const prepaymentProductsSumm = rentInfo.products.reduce(
    (acc, { prepayment }) => acc + parseFloat(prepayment),
    0
  );
  const [prepaymentState, setPrepaymentState] = useState(() => {
    const isBooking = rentInfo.is_booking;
    return {
      type: !isBooking ? "1" : "2",
      value: isBooking ? prepaymentProductsSumm : rentInfo.value,
      validation: {
        isValid: true,
        message: [],
      },
    };
  });

  const handlePrepaymentChange = (event) => {
    const { value } = event.target;
    const validationErrors = Validate(
      value,
      [`between:0,${parseFloat(rentInfo.value)}`, "fixed:2", "required"],
      "'Предоплата'"
    );
    setPrepaymentState({
      ...prepaymentState,
      value,
      validation: {
        isValid: validationErrors.length === 0,
        message: validationErrors,
      },
    });
  };

  const handleChangePrepaymentType = (event) => {
    const { value } = event.target;
    let prepaymentValue = 0;
    if (value === "1") prepaymentValue = parseFloat(rentInfo.value);
    else if (rentInfo.is_booking) prepaymentValue = prepaymentProductsSumm;
    setPrepaymentState({
      type: value,
      value: prepaymentValue,
      validation: {
        isValid: true,
        message: [],
      },
    });
  };

  useEffect(() => {
    updateRentInfoCallback({ ...rentInfo, prepayment: prepaymentState.value });
  }, [prepaymentState]);

  return (
    <Dialog open={open} onClose={onCloseAction} maxWidth="md" fullWidth>
      <DialogTitle>Подтвердите действие</DialogTitle>
      <DialogContent>
        <DialogContentText mb={2}>
          <MDTypography variant="button" color="text">
            Будет создан договор об аренде со следующими данными:
          </MDTypography>
        </DialogContentText>
        <MDBox>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography variant="h6" color="text" fontWeight="regular">
              Тип договора:&nbsp;
            </MDTypography>
            <MDTypography variant="h6">
              {rentInfo.is_booking ? "бронирование" : "аренда"}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography variant="h6" color="text" fontWeight="regular">
              Дата выдачи товара:
            </MDTypography>
            <MDTypography variant="h6">{dateWord(rentInfo.rent_date)}</MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography variant="h6" color="text" fontWeight="regular">
              Клиент:
            </MDTypography>
            <MDTypography variant="h6">{rentInfo.client_name}</MDTypography>
          </MDBox>
          <MDBox mt={2}>
            <DataTable
              entriesPerPage={false}
              table={{ columns, rows }}
              isSorted={false}
              showTotalEntries={false}
              noEndBorder
            />
            <MDBox display="flex" justifyContent="flex-end" px="24px">
              <MDTypography variant="h5" mr={2}>
                Всего:
              </MDTypography>
              <MDTypography variant="h5">{parseFloat(rentInfo.value)} руб.</MDTypography>
            </MDBox>
          </MDBox>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={prepaymentState.type}
              onChange={handleChangePrepaymentType}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={
                  <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                    Полная оплата
                  </MDTypography>
                }
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={
                  <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                    Частичная предоплата
                  </MDTypography>
                }
              />
            </RadioGroup>
          </FormControl>
          {/* Вывод ошибок валидации полей */}
          <Collapse in={!prepaymentState.validation.isValid}>
            <Alert severity="error" icon={false}>
              {Object.keys(prepaymentState.validation.message).map((validatedValue) => (
                <MDTypography
                  color="error"
                  variant="subtitle2"
                  fontWeight="medium"
                  sx={{ fontSize: "caption.fontSize" }}
                  key={validatedValue}
                >
                  {prepaymentState.validation.message[0]}
                </MDTypography>
              ))}
            </Alert>
          </Collapse>
          {prepaymentState.type === "2" && (
            <MDInput
              name="prepayment"
              type="number"
              label="Предоплата"
              sx={{ width: "100%" }}
              size="small"
              value={prepaymentState.value}
              onChange={handlePrepaymentChange}
              success={prepaymentState.validation.isValid}
              error={!prepaymentState.validation.isValid}
            />
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onCloseAction} color="error">
          Отмена
        </MDButton>
        <MDButton onClick={onAcceptAction} color="success">
          Подтвердить
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

// ConfirmationRentDialog prop-types
ConfirmationRentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rentInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateRentInfoCallback: PropTypes.func.isRequired,
  onCloseAction: PropTypes.func.isRequired,
  onAcceptAction: PropTypes.func.isRequired,
};

export default ConfirmationRentDialog;
