const inputs = {
  first_name: {
    defaultValue: "",
    validationNeeded: true,
    attribute: "'Имя'",
    validationRules: ["required", "max:15"],
  },
  last_name: {
    defaultValue: "",
    validationNeeded: true,
    attribute: "'Фамилия'",
    validationRules: ["required", "max:15"],
  },
  patronymic: {
    defaultValue: "",
    validationNeeded: true,
    attribute: "'Отчество'",
    validationRules: ["max:15"],
  },
  email: {
    defaultValue: "",
    validationNeeded: false,
  },
  phone: {
    defaultValue: "",
    validationNeeded: true,
    // TODO добавить возможность быть Null
    validationRules: ["max:12"],
    attribute: "'Телефон'",
  },
  telegram_id: {
    defaultValue: "",
    validationNeeded: false,
  },
  commentary: {
    defaultValue: "",
    validationNeeded: true,
    validationRules: ["max:255"],
    attribute: "'Комментарий'",
  },
};

export default inputs;
