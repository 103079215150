// react
import { useState } from "react";

// validate
import validate, { minLength } from "service/Validate/validate";

/**
 * use text input hook
 * @param id {string}
 * @param validators {[Validators]}
 * @param init {string}
 */
export default function usePhoneInput(id, validators, init = "") {
  const [value, setValue] = useState(init);
  const [internationalValue, setInternetionalValue] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState(null);

  const getInputNumbers = (val) => val.replace(/\D/g, "");

  const handleChangeInput = async (event) => {
    let val = getInputNumbers(event.target.value);
    let resultValue = "";

    if (val || event.target.value === "+") {
      if (["7", "8", "9"].indexOf(val[0]) !== -1) {
        if (val[0] === "9") val = `7${val}`;
        const firstSymbols = val[0] === "8" ? "8" : "+7";
        resultValue = `${firstSymbols}`;
        if (val.length > 1) resultValue = `${resultValue} (${val.substring(1, 4)}`;
        if (val.length >= 5) resultValue = `${resultValue}) ${val.substring(4, 7)}`;
        if (val.length >= 8) resultValue = `${resultValue}-${val.substring(7, 9)}`;
        if (val.length >= 10) resultValue = `${resultValue}-${val.substring(9, 11)}`;
      } else {
        resultValue = event.target.value === "+" ? "+" : `+${val.substring(0, 16)}`;
      }
    }

    let internationalFormat = resultValue.replaceAll(/[\\+\s\\(\\)\\-]/g, "");
    if (internationalFormat[0] === "8") internationalFormat = internationalFormat.replace("8", "7");

    setValue(resultValue);
    setInternetionalValue(internationalFormat);
    setIsDirty(true);

    const copyValidators = [...validators];
    if (internationalFormat[0] === "7")
      copyValidators[copyValidators.length] = {
        validator: minLength,
        param: 11,
      };
    setError(await validate(resultValue.replaceAll(/[\s\\(\\)\\-]/g, ""), copyValidators));
  };

  const handleBlur = async (event) => {
    const val = event.target.value;

    setError(await validate(val, validators));
  };

  return {
    id,
    value,
    internationalValue,
    error,
    handleBlur,
    handleChangeInput,
    isDirty,
  };
}
