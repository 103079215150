import {
  API_DOMAIN,
  GET_LOCAL_ACCESS_TOKEN,
  REMOVE_LOCAL_TOKEN,
} from "service/requests/requestConfig";

export default class Product {
  /**
   * Get total products
   */
  static async GetTotal(id = null) {
    let url = `${API_DOMAIN}api/products/total`;
    if (id !== null) url = `${url}/${id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const code = response.status;
    const json = await response.json();
    return {
      code,
      json,
    };
  }

  /**
   * Get all products of company
   */
  static async GetAll() {
    const url = `${API_DOMAIN}api/products`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Get product by id
   */
  static async GetById(id) {
    const url = `${API_DOMAIN}api/products/${id}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Getting rent objects linked with specify product
   */
  static async GetRentObjects(productId, rentObjectId = null) {
    const url = `${API_DOMAIN}api/products/${productId}/rent_objects${
      rentObjectId ? `/${rentObjectId}` : ""
    }`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Getting rents linked with specify product
   */
  static async ReadRents(productId, rentId = null) {
    const url = `${API_DOMAIN}api/products/${productId}/rents${rentId ? `/${rentId}` : ""}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Create new product
   *
   * @return object
   * {
   *    success: <bool>,
   *    (message: <string>(validation errors)
   *    ||
   *    user: <object>)
   * }
   */
  static async Create(productData, images = []) {
    const formData = productData;
    formData.images = images;
    const url = `${API_DOMAIN}api/products`;
    const response = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(formData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Update product
   *
   * @return object
   * {
   *    success: <bool>,
   *    (message: <string>(validation errors)
   *    ||
   *    user: <object>)
   * }
   * @param
   */
  static async Update(id, productData, images = []) {
    const formData = productData;
    formData.images = images;
    delete formData.anchor;
    const url = `${API_DOMAIN}api/products/${id}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(formData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Invert status of product
   */
  static async InvertStatus(id) {
    const url = `${API_DOMAIN}api/products/${id}/invert_status`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Trash product
   */
  static async Trash(id) {
    const url = `${API_DOMAIN}api/products/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    const code = response.status;
    const json = await response.json();
    return {
      code,
      json,
    };
  }

  /**
   * Restore product
   */
  static async Restore(id) {
    const url = `${API_DOMAIN}api/products/${id}/restore`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    const code = response.status;
    const json = await response.json();
    return {
      code,
      json,
    };
  }

  /**
   * Delete product
   */
  static async Delete(id) {
    const url = `${API_DOMAIN}api/products/${id}/force_delete`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    return response.ok;
  }

  /**
   * Find product index in product array by id
   * @param
   */
  static IndexOfProductById(productArray, productId) {
    let searchResult = -1;
    productArray.forEach((product, index) => {
      if (product.id === productId) {
        searchResult = index;
      }
    });
    return searchResult;
  }
}
