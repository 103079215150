/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// prop types
import PropTypes from "prop-types";

// models
import Settings from "service/Models/Settings";

// components
import Preloader from "layouts/preloader";

function PlatformSettings({ settings }) {
  if (!settings) return <Preloader fullScreen={false} />;

  const [userSettings, setUserSettings] = useState(settings);

  const handleChangeSettings = (name) => {
    const newSettingsData = { ...userSettings, [name]: !userSettings[name] };
    Settings.Update(newSettingsData, settings.user_id)
      .then((response) => response.json())
      .then((response) => {
        if (response.success !== true) return Promise.reject(response);

        setUserSettings({ ...response.settings, user_id: settings.user_id });
        return true;
      })
      .catch((err) => console.error(err));
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Настройки
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Каналы уведомлений
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={Boolean(userSettings.email_notify)}
              onChange={() => handleChangeSettings("email_notify")}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Отправлять мне уведомления по Email
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={Boolean(userSettings.telegram_notify)}
              onChange={() => handleChangeSettings("telegram_notify")}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Отправлять мне уведомления в Telegram
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Уведомления
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={Boolean(userSettings.notify_must_give_product)}
              onChange={() => handleChangeSettings("notify_must_give_product")}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Уведомлять меня о товарах, которые должны быть выданы
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={Boolean(userSettings.notify_must_return_product)}
              onChange={() => handleChangeSettings("notify_must_return_product")}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Уведомлять меня о товарах, которые должны быть возвращены
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch
              checked={Boolean(userSettings.notify_overdue_product)}
              onChange={() => handleChangeSettings("notify_overdue_product")}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Уведомлять меня о товарах, которые не возвращены вовремя
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

PlatformSettings.propTypes = {
  settings: PropTypes.shape({
    user_id: PropTypes.number.isRequired,
    email_notify: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    telegram_notify: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    notify_must_give_product: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool])
      .isRequired,
    notify_must_return_product: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool])
      .isRequired,
    notify_overdue_product: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool])
      .isRequired,
  }).isRequired,
};

export default PlatformSettings;
