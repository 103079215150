// Material dashboard 2 examples components
import DataTable from "examples/Tables/DataTable";

// Material dashboard 2 components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// prop-types
import PropTypes from "prop-types";

// utilities
import { dateWord } from "service/utilities";

// react
import { useNavigate } from "react-router-dom";

function HistoryTimeline({ rent }) {
  const rows = [];
  const navigate = useNavigate();
  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };
  const eventsTableData = () => {
    rent.cash.forEach((transaction) => {
      rows[rows.length] = {
        id: {
          link: `/crm/cash/${transaction.id}`,
          jsx: (
            <MDTypography variant="button" color="text" fontWeight="medium">
              #{transaction.id}
            </MDTypography>
          ),
        },
        date: {
          link: `/crm/cash/${transaction.id}`,
          jsx: (
            <MDTypography variant="button" color="text" fontWeight="regular">
              {dateWord(transaction.date)}
            </MDTypography>
          ),
        },
        note: {
          link: `/crm/cash/${transaction.id}`,
          jsx: (
            <MDTypography variant="button" color="text" fontWeight="regular">
              {transaction.note}
            </MDTypography>
          ),
        },
        payment: {
          link: `/crm/cash/${transaction.id}`,
          jsx: (
            <MDTypography variant="button" color="text" fontWeight="regular">
              {transaction.value}
            </MDTypography>
          ),
        },
      };
    });

    return {
      columns: [
        {
          Header: "ID",
          accessor: "id",
          width: "5%",
          align: "left",
          Cell: ({ value }) => value.jsx,
        },
        { Header: "Дата", accessor: "date", align: "left", Cell: ({ value }) => value.jsx },
        {
          Header: "Событие",
          accessor: "note",
          width: "35%",
          align: "left",
          Cell: ({ value }) => value.jsx,
        },
        { Header: "Оплата", accessor: "payment", align: "left", Cell: ({ value }) => value.jsx },
      ],
      rows,
    };
  };

  return (
    <MDBox>
      <DataTable
        entriesPerPage={false}
        showTotalEntries={false}
        isSorted={false}
        table={eventsTableData()}
        onCellClick={onCellClick}
        noEndBorder
      />
    </MDBox>
  );
}

HistoryTimeline.propTypes = {
  rent: PropTypes.shape({
    cash: PropTypes.arrayOf(
      PropTypes.shape({
        note: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default HistoryTimeline;
