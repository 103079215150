const DEFAULT_REQUIRED_MESSAGE = "Поле обязательно для заполнения";

/**
 * required validator
 * @param value
 * @param message
 * @returns {Promise<null|string>}
 */
const required = async (value, message = DEFAULT_REQUIRED_MESSAGE) => (value ? null : message);

export default required;
