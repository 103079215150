import {
  API_DOMAIN,
  GET_LOCAL_ACCESS_TOKEN,
  REMOVE_LOCAL_TOKEN,
} from "service/requests/requestConfig";

export default class Price {
  /**
   * Create new price
   */
  static async Create(priceData, productId) {
    const url = `${API_DOMAIN}api/products/${productId}/prices/create`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(priceData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Update price
   */
  static async Update(priceData, productId, priceId) {
    const url = `${API_DOMAIN}api/products/${productId}/prices/${priceId}/update`;
    const response = await fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(priceData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Invert status of price
   */
  static async InvertStatus(productId, priceId) {
    const url = `${API_DOMAIN}api/products/${productId}/prices/${priceId}/invert_status`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Delete price
   */
  static async Delete(productId, priceId) {
    const url = `${API_DOMAIN}api/products/${productId}/prices/${priceId}/delete`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    return true;
  }

  /**
   * Find price interval index in product array by id
   */
  static IndexOfIntervalById(priceIntervalsArray, priceIntervalId) {
    let searchResult = -1;
    priceIntervalsArray.forEach((product, index) => {
      if (product.id === priceIntervalId) {
        searchResult = index;
      }
    });
    return searchResult;
  }
}
