// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// validate func
import { Validate } from "service/validator";

// data
import inputs from "layouts/rents/data/createRentFormInputs";

// Models
import Rent from "service/Models/Rent";
import Product from "service/Models/Product";
import Client from "service/Models/Client";

// utilities
import { getCurrentDate, compareDates, shiftDate } from "service/utilities";

// components
import ConfirmationRentDialog from "layouts/rents/components/ConfirmationRentDialog";
// import ProductAlertDialog from "layouts/rents/components/ProductAlertDialog";

function CreateRentForm() {
  const navigate = useNavigate();
  // default form state or product info form state
  const [formState, setFormState] = useState(() => {
    const freshFormStateObject = {};
    const inputsNames = Object.keys(inputs);
    inputsNames.forEach((inputName) => {
      freshFormStateObject[inputName] = inputs[inputName].defaultValue;
    });
    return freshFormStateObject;
  });
  // default form validation or product info form validation
  const [formValidation, setFormValidation] = useState(() => {
    const freshFormValidationObject = {};
    Object.keys(inputs).forEach((inputName) => {
      if (inputs[inputName].validationNeeded) {
        const errorsArray = inputs[inputName].initialValidate
          ? Validate(
              inputs[inputName].defaultValue,
              inputs[inputName].validationRules,
              inputs[inputName].attribute || inputName
            )
          : [];
        freshFormValidationObject[inputName] = {
          isValid: errorsArray.length === 0,
          validationErrorMessage: errorsArray,
          isDirty: false,
        };
      }
    });
    return freshFormValidationObject;
  });

  // calculate value and set form state
  const calculateValue = (productsArray = null) => {
    formState.value = 0.0;
    if (productsArray !== null) formState.products = productsArray;
    formState.products.forEach(({ currentPrice }) => {
      formState.value += parseFloat(currentPrice ? currentPrice.price : 0);
    });
    formState.value += parseFloat(formState.shipping_cost);
    setFormState({ ...formState });

    const errorsArray = Validate(
      formState.value - formState.shipping_cost,
      inputs.value.validationRules,
      inputs.value.attribute
    );
    const newFormValidation = {
      isValid: errorsArray.length === 0,
      validationErrorMessage: errorsArray,
      isDirty: true,
    };
    setFormValidation({ ...formValidation, value: newFormValidation });
  };

  // function check is input valid
  const isValid = (inputName) => formValidation[inputName].isValid;
  // function check full form validate
  const formValidate = () => {
    let formValid = true;
    Object.keys(formValidation).forEach((input) => {
      if (!formValidation[input].isValid) {
        formValid = false;
      }
    });
    return formValid;
  };

  // active products state
  const [products, setProducts] = useState([]);
  // clients state
  const [clients, setClients] = useState([]);

  // get active products
  useEffect(() => {
    const getPricesOptions = (pricesArray) => {
      const entries = [];
      pricesArray
        .filter((price) => price.price_type === 0)
        .forEach((price) => {
          entries[entries.length] = {
            days: price.days !== null ? price.days.toString() : "",
            price: price.price,
            id: price.id,
          };
        });
      return entries;
    };
    const getProducts = async () => {
      const response = await Product.GetAll();
      if (response.success === true) {
        const activeProducts = response.products.filter(({ active }) => active === true);
        const entries = [];
        activeProducts.forEach((product) => {
          entries[entries.length] = {
            id: product.id,
            name: product.name,
            currentPrice: null,
            prices: getPricesOptions(product.prices),
            rent_objects: product.rent_objects,
            ready: product.ready,
            prepayment: product.prepayment_price,
          };
        });
        setProducts(entries);
        return true;
      }
      // TODO сделать обработку ошибки
      console.log(response);
      return false;
    };
    const getClients = async () => {
      const response = await Client.Read();
      if (response.success === true) {
        const activeClients = response.clients.filter((client) => client.deleted_at === null);
        const entries = [];
        activeClients.forEach((client) => {
          entries[entries.length] = {
            label: client.full_name,
            id: client.id,
          };
        });
        setClients(entries);
        return true;
      }
      // TODO добавить обработку ошибок
      console.log(response);
      return false;
    };
    getProducts();
    getClients();
  }, []);

  const handleDisableBooking = () => compareDates(formState.rent_date, ">", getCurrentDate());

  const handleInputChange = (event) => {
    const [target, name] = [event.target, event.target.name];
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (inputs[name].validationNeeded) {
      const errorsArray = Validate(value, inputs[name].validationRules, inputs[name].attribute);
      const newFormValidation = {
        isValid: errorsArray.length === 0,
        validationErrorMessage: errorsArray,
        isDirty: true,
      };
      setFormValidation({ ...formValidation, [name]: newFormValidation });
    }
    if (name === "is_shipping" && !value) {
      setFormValidation({
        ...formValidation,
        shipping_cost: {
          isValid: true,
          validationErrorMessage: [],
          isDirty: false,
        },
      });
    } else {
      const errorsArray = Validate(
        value,
        inputs.shipping_cost.validationRules,
        inputs.shipping_cost.attribute
      );
      const newFormValidation = {
        isValid: errorsArray.length === 0,
        validationErrorMessage: errorsArray,
        isDirty: true,
      };
      setFormValidation({ ...formValidation, shipping_cost: newFormValidation });
    }
    if (name === "rent_date" && compareDates(value, ">", getCurrentDate())) {
      setFormState({ ...formState, rent_date: value, is_booking: true });
    } else if (name === "is_shipping" && !value) {
      formState.is_shipping = value;
      formState.shipping_cost = inputs.shipping_cost.defaultValue;
      calculateValue(formState.products);
    } else if (name === "shipping_cost") {
      formState.shipping_cost = value;
      calculateValue(formState.products);
    } else setFormState({ ...formState, [name]: value });
  };

  // product not ready alert dialog state
  // const [openProductAlertDialog, setOpenProductAlertDialog] = useState(null);
  // const updateProductAlertDialog = (value) => setOpenProductAlertDialog(value);

  const handleProductsChange = (event, value) => {
    const savePrice = (pId) => {
      let result = null;
      formState.products.forEach(({ id, currentPrice }) => {
        if (id === pId) result = currentPrice;
      });
      return result;
    };

    const productsData = [];
    if (value) {
      value.forEach(({ id, name, prices, ready, prepayment }) => {
        productsData[productsData.length] = {
          id,
          prices,
          name,
          ready,
          prepayment,
          currentPrice: savePrice(id),
        };
        // if (!ready) setOpenProductAlertDialog(true);
      });
    }

    calculateValue(productsData);
  };

  const handleDaysChange = (value, productIndex) => {
    const productsArray = formState.products;
    productsArray[productIndex].currentPrice = value || null;
    calculateValue(productsArray);
  };

  const handleClientChange = (event, value) => {
    const clientId = value ? value.id : null;
    setFormState({ ...formState, client_id: clientId });
    const errorsArray = Validate(
      clientId,
      inputs.client_id.validationRules,
      inputs.client_id.attribute
    );
    const newFormValidation = {
      isValid: errorsArray.length === 0,
      validationErrorMessage: errorsArray,
      isDirty: true,
    };
    setFormValidation({ ...formValidation, client_id: newFormValidation });
  };

  // open confirmation window state
  const [openConfirmationRentDialog, setOpenConfirmationRentDialog] = useState(false);
  const handleOpenDialog = () => setOpenConfirmationRentDialog(true);
  const handleCloseDialog = () => {
    setFormState({ ...formState, prepayment: 0 });
    setOpenConfirmationRentDialog(false);
  };

  const handleConfirm = async () => {
    const data = { ...formState, issue_date: !formState.is_booking ? getCurrentDate() : null };
    const response = await Rent.Create(data);
    if (response.success === true) {
      navigate("/crm/rents");
      return true;
    }
    // TODO добавить обрабоку ошибки
    console.log(response);
    return false;
  };

  const rentInfo = () => ({
    ...formState,
    client_name: clients.filter((client) => client.id === formState.client_id)[0].label,
  });

  const productAvailable = (productId) => {
    const prod = products.filter((product) => product.id === productId)[0];
    let isAvailable = true;
    prod.rent_objects.forEach((interval) => {
      if (
        compareDates(interval.rent.rent_date, "<=", formState.rent_date) &&
        compareDates(interval.return_date, ">=", formState.rent_date)
      )
        isAvailable = false;
    });
    return isAvailable;
  };

  const productDaysAvailable = (productId, days) => {
    const prod = products.filter((product) => product.id === productId)[0];
    let isAvailable = true;
    prod.rent_objects
      .filter((interval) => compareDates(interval.return_date, ">", formState.rent_date))
      .forEach((interval) => {
        if (compareDates(interval.rent.rent_date, "<=", shiftDate(formState.rent_date, 0, 0, days)))
          isAvailable = false;
      });
    return isAvailable;
  };

  return (
    <div>
      {/* Окно подтверждения */}
      {openConfirmationRentDialog && (
        <ConfirmationRentDialog
          onCloseAction={handleCloseDialog}
          onAcceptAction={handleConfirm}
          open={openConfirmationRentDialog}
          rentInfo={rentInfo()}
          updateRentInfoCallback={(value) => setFormState(value)}
        />
      )}
      {/* Окно предупреждения о неготовности товара */}
      {/* openProductAlertDialog && (
        <ProductAlertDialog productId="1" updateOpenCallback={updateProductAlertDialog} />
      ) */}
      {/* Вывод ошибок валидации полей */}
      <Collapse in={!formValidate()}>
        <Alert severity="error" icon={false}>
          {Object.keys(formValidation).map((validatedValue) => (
            <MDTypography
              color="error"
              variant="subtitle2"
              fontWeight="medium"
              sx={{ fontSize: "caption.fontSize" }}
              key={validatedValue}
            >
              {formValidation[validatedValue].validationErrorMessage[0]}
            </MDTypography>
          ))}
        </Alert>
      </Collapse>
      <MDBox p={2}>
        <Grid container spacing={4}>
          <Grid
            container
            item
            xs={12}
            md={4}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            {/* Значение */}
            <Grid item>
              <MDInput
                label="Сумма"
                name="value"
                type="number"
                sx={{ width: "100%" }}
                size="small"
                value={formState.value}
                onChange={handleInputChange}
                {...(isValid("value") ? { success: true } : { error: true })}
                disabled
              />
            </Grid>
            {/* Дата */}
            <Grid item>
              <MDInput
                name="rent_date"
                label="Дата"
                type="date"
                sx={{ width: "100%" }}
                size="small"
                value={formState.rent_date}
                InputProps={{ inputProps: { min: "2023-01-01" } }}
                onChange={handleInputChange}
              />
            </Grid>
            {/* Указатель на бронирование */}
            <Grid item>
              <MDBox display="flex" alignItems="center">
                <Checkbox
                  name="is_booking"
                  onClick={handleInputChange}
                  checked={formState.is_booking}
                  disabled={handleDisableBooking()}
                />
                <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                  Договор о бронировании
                </MDTypography>
              </MDBox>
            </Grid>
            {/* Указатель на необходимость доставки */}
            <Grid item>
              <MDBox display="flex" alignItems="center">
                <Checkbox
                  name="is_shipping"
                  onClick={handleInputChange}
                  checked={formState.is_shipping}
                />
                <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                  Оформить доставку
                </MDTypography>
              </MDBox>
            </Grid>
            {/* Значение */}
            <Collapse in={formState.is_shipping}>
              <Grid item>
                <MDInput
                  label="Стоимость доставки"
                  name="shipping_cost"
                  type="number"
                  sx={{ width: "100%" }}
                  size="small"
                  value={formState.shipping_cost}
                  onChange={handleInputChange}
                  {...(isValid("shipping_cost") ? { success: true } : { error: true })}
                />
              </Grid>
            </Collapse>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <MDInput
                label="Комментарий к аренде"
                name="commentary"
                multiline
                sx={{ width: "100%" }}
                size="small"
                rows={3}
                value={formState.commentary}
                onChange={handleInputChange}
                min="1"
                max="100"
                success
              />
            </Grid>
          </Grid>
          {/* Товары */}
          <Grid item xs={12}>
            <MDTypography variant="h6">Товары</MDTypography>
            <Autocomplete
              name="products"
              options={products}
              size="small"
              sx={{ width: "100%" }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              getOptionDisabled={(option) => !productAvailable(option.id)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <MDInput {...params} label="Товары" />}
              onChange={handleProductsChange}
              multiple
              value={formState.products}
            />
            <MDBox mt={2}>
              {formState.products.map(({ currentPrice, name, prices, id }, productIndex) => (
                <Grid container mb={1} key={name}>
                  <Grid item xs={3}>
                    <MDTypography variant="button" color="text" fontWeight="medium">
                      {name}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Autocomplete
                      name={`${name}_days`}
                      options={prices}
                      size="small"
                      sx={{ width: "100%" }}
                      groupBy={(option) => option.type}
                      getOptionLabel={(option) => option.days}
                      getOptionDisabled={(option) =>
                        !productDaysAvailable(id, parseInt(option.days, 10))
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => <MDInput {...params} label="Кол-во дней" />}
                      value={currentPrice}
                      onChange={(event, value) => handleDaysChange(value, productIndex)}
                    />
                  </Grid>
                  <Grid item xs={3} md={6} textAlign="right">
                    <MDTypography variant="button" color="text" fontWeight="medium">
                      {currentPrice ? currentPrice.price : 0} руб.
                    </MDTypography>
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          </Grid>
          {/* Клиент */}
          <Grid item xs={12}>
            <MDTypography variant="h6">Клиент</MDTypography>
            <Autocomplete
              name="client"
              options={clients}
              size="small"
              sx={{ width: "100%" }}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <MDInput {...params} label="Клиенты" />}
              onChange={handleClientChange}
              value={clients.filter((client) => client.id === formState.client_id)[0] || ""}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            {formValidate() ? (
              <MDButton variant="gradient" color="success" size="small" onClick={handleOpenDialog}>
                <Icon>add</Icon>&nbsp;Подтвердить
              </MDButton>
            ) : (
              <MDButton variant="gradient" color="success" size="small" disabled>
                <Icon>add</Icon>&nbsp;Подтвердить
              </MDButton>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default CreateRentForm;
