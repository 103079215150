/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// mui components
import Badge from "@mui/material/Badge";

// material dashboard 2 react components
import MDTypography from "components/MDTypography";

// utilities
import { compareDates, dateWord, getCurrentDate } from "service/utilities";

// react
import { Link } from "react-router-dom";

export default function data(objects) {
  const rows = [];
  if (objects.length !== 0) {
    objects.forEach((object) => {
      rows[rows.length] = {
        product_name: {
          link: `/crm/rents/${object.rent_id}`,
          jsx:
            !object.is_returned && compareDates(getCurrentDate(), ">", object.return_date) ? (
              <Badge badgeContent="!" color="error">
                <MDTypography
                  component={Link}
                  to={`/crm/rents/${object.rent_id}`}
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {object.product.name}
                </MDTypography>
              </Badge>
            ) : (
              <MDTypography
                component={Link}
                to={`/crm/rents/${object.rent_id}`}
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {object.product.name} {!object.rent.issue_date ? "(Бронирование)" : ""}
              </MDTypography>
            ),
        },
        return_date: {
          link: `/crm/rents/${object.rent_id}`,
          jsx: dateWord(object.return_date),
        },
      };
    });
  }

  return {
    columns: [
      {
        Header: "Товар",
        accessor: "product_name",
        width: "70%",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Дата возврата",
        accessor: "return_date",
        width: "20%",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
    ],
    rows,
  };
}
