// proptypes
import PropTypes from "prop-types";

// utilities
import { compareDates, getCurrentDate, reduceAccByColName } from "service/utilities";

export default function data(rent) {
  if (rent.is_cancel) return "canceled";

  if (rent.is_booking && !rent.issue_date) return "booked";

  const overdueProducts = rent.objects.filter(
    (object) => !object.is_returned && compareDates(getCurrentDate(), ">", object.return_date)
  );
  if (overdueProducts.length !== 0) return "overdue";

  const isAllProductsReturned = rent.objects.filter((object) => !object.is_returned).length === 0;
  const transactionsSum = reduceAccByColName(rent.cash, "value");
  const rentTotalSum =
    reduceAccByColName(rent.objects, "price") + parseFloat(rent.shipping_cost || 0);
  if (isAllProductsReturned && rentTotalSum - transactionsSum === 0) return "closed";

  return "open";
}

// data prop-types
data.propTypes = {
  rent: PropTypes.shape({
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
    is_cancel: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    is_booking: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    issue_date: PropTypes.string.isRequired,
    shipping_cost: PropTypes.string.isRequired,
  }).isRequired,
};
