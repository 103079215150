/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// mui components
import Card from "@mui/material/Card";

// Material dashboard 2 Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";

function NotFound() {
  return (
    <AuthorizeUser>
      <DashboardLayout>
        <MDBox
          py={1}
          px={{ xs: 4, sm: 3, lg: 2 }}
          mt={12}
          mb={8}
          mx="auto"
          width="90%"
          borderRadius="lg"
          maxWidth="xl"
        >
          <Card>
            <MDBox
              p={3}
              sx={{ minHeight: "70vh" }}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <MDTypography variant="h1">404</MDTypography>
              <MDTypography variant="button">Страница не найдена</MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default NotFound;
