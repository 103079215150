// mui components
import Icon from "@mui/material/Icon";

// material dashboard 2 components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// proptypes
import PropTypes from "prop-types";

function TransactionStatus({ transactionStatus }) {
  if (transactionStatus === "+")
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="success">
          circle
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Пополнение
        </MDTypography>
      </MDBox>
    );

  return (
    <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
      <Icon fontSize="inherit" color="error" sx={{ transform: "rotate(45deg)" }}>
        square
      </Icon>
      &nbsp;
      <MDTypography variant="button" fontWeight="regular" color="text">
        Списание
      </MDTypography>
    </MDBox>
  );
}

// RenewalObjectForm prop-types
TransactionStatus.propTypes = {
  transactionStatus: PropTypes.oneOf(["+", "-"]).isRequired,
};

export default TransactionStatus;
