// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";

// React
import { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// propTypes
import PropTypes from "prop-types";

function ImageUploader({ compressImagesCallback, imagesLimit, images, _mainImage }) {
  const [originalImages, setOriginalImages] = useState([...images]);
  const [compressedImages, setCompressedImages] = useState([...images]);
  const [mainImage, setMainImage] = useState(_mainImage);
  let compressImagesBuf = [];
  let newCompressImagesCount = 0;
  let compressImagesMain = "";

  // const compressImage = (image, length, pMainImageName) => {
  const compressImage = (newImage) => {
    const imageElement = document.createElement("img");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const MAX_WIDTH = 400;
    const MAX_HEIGHT = 400;

    imageElement.src = URL.createObjectURL(newImage);
    imageElement.onload = () => {
      let { width, height } = imageElement;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(imageElement, 0, 0, width, height);
      compressImagesBuf[compressImagesBuf.length] = {
        src: canvas.toDataURL("image/png", 0.7),
        name: newImage.name,
        size: newImage.size,
        is_main: newImage.name === compressImagesMain,
      };
      if (compressImagesBuf.length === newCompressImagesCount) {
        setCompressedImages([...compressedImages, ...compressImagesBuf]);
        compressImagesCallback([...compressedImages, ...compressImagesBuf]);
        compressImagesBuf = [];
      }
    };
  };

  const compressImages = (newImagesArray) => {
    newImagesArray.forEach((newImage) => {
      compressImage(newImage);
    });
  };

  const retrieveUniqueImages = (newImagesArray) => {
    const uploadedImagesArray = originalImages;
    const uploadedImagesNames = [];
    uploadedImagesArray.forEach((uploadedImage, i) => {
      uploadedImagesNames[i] = uploadedImage.name;
    });

    const newImagesNames = [];
    newImagesArray.forEach((newImage, i) => {
      newImagesNames[i] = newImage.name;
    });

    const deletingImagesNames = [];
    newImagesNames.forEach((newImageName) => {
      if (uploadedImagesNames.includes(newImageName)) {
        deletingImagesNames[deletingImagesNames.length] = newImageName;
      }
    });

    const deletingImagesNamesSet = new Set(deletingImagesNames);
    const uniqueNewImagesNames = newImagesNames.filter(
      (newImage) => !deletingImagesNamesSet.has(newImage)
    );

    const uniqueNewImages = [];
    newImagesArray.forEach((newImage) => {
      if (uniqueNewImagesNames.includes(newImage.name)) {
        uniqueNewImages[uniqueNewImages.length] = newImage;
      }
    });
    return uniqueNewImages;
  };

  const handleFile = (event) => {
    const uniqueNewImages = retrieveUniqueImages([...event.target.files]);
    if (mainImage.name === undefined) {
      setMainImage(uniqueNewImages[0]);
      compressImagesMain = uniqueNewImages[0].name;
    }
    if (uniqueNewImages.length <= imagesLimit - originalImages.length) {
      newCompressImagesCount = uniqueNewImages.length;
      compressImages(uniqueNewImages);
      setOriginalImages([...originalImages, ...uniqueNewImages]);
    }
  };

  const handleMainClick = (event, id) => {
    event.preventDefault();
    setMainImage(originalImages[id]);
    compressImagesMain = originalImages[id].name;

    const newCompressedImages = compressedImages;
    compressedImages.forEach((compressedImage, index) => {
      if (compressedImage.name === compressImagesMain) {
        newCompressedImages[index].is_main = true;
      } else {
        newCompressedImages[index].is_main = false;
      }
    });
    setCompressedImages([...compressedImages]);
    compressImagesCallback([...compressedImages]);
  };

  const handleRemoveFile = (event, id) => {
    event.preventDefault();
    const removingFileName = originalImages[id].name;

    originalImages.splice(id, 1);
    setOriginalImages([...originalImages]);

    let removingCompressImageIndex = -1;
    compressedImages.forEach((compressedImage, index) => {
      if (compressedImage.name === removingFileName) {
        removingCompressImageIndex = index;
      }
    });
    compressedImages.splice(removingCompressImageIndex, 1);

    if (mainImage.name === removingFileName) {
      const newMainImage = originalImages[0] || {};
      setMainImage(newMainImage);
      compressImagesMain = newMainImage ? newMainImage.name : "";
      if (compressImagesMain) {
        const newCompressedImages = compressedImages;
        compressedImages.forEach((compressedImage, index) => {
          if (compressedImage.name === compressImagesMain) {
            newCompressedImages[index].is_main = true;
          }
        });
      }
    }
    setCompressedImages([...compressedImages]);
    compressImagesCallback([...compressedImages]);
  };

  const renderImages = originalImages.map((originalImage, id) => (
    <MDBox
      m={1}
      key={originalImage.name}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Badge
        badgeContent={
          <Icon
            sx={{ cursor: "pointer" }}
            fontSize="small"
            color="error"
            onClick={(event) => handleRemoveFile(event, id)}
          >
            close
          </Icon>
        }
      >
        <MDAvatar
          alt="photo"
          size="xxl"
          bgColor="light"
          variant="rounded"
          sx={{ cursor: mainImage.name === originalImage.name ? "default" : "pointer" }}
          onClick={(event) => handleMainClick(event, id)}
          src={originalImage.path || URL.createObjectURL(originalImage)}
        >
          <Icon fontSize="lg" color="disabled">
            hourglass_top
          </Icon>
        </MDAvatar>
      </Badge>
      {mainImage.name === originalImage.name && (
        <MDTypography variant="caption" mt={1}>
          Основное фото
        </MDTypography>
      )}
    </MDBox>
  ));

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <MDTypography variant="h6">Изображения</MDTypography>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-start">
        <Grid item xs={12} mb={1}>
          <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
            {renderImages}
            {originalImages.length < imagesLimit && (
              <MDBox m={1}>
                <MDAvatar
                  alt="add-photo"
                  size="xxl"
                  bgColor="light"
                  variant="rounded"
                  sx={{ cursor: "pointer" }}
                  component="label"
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple="multiple"
                    hidden
                    onChange={handleFile}
                  />
                  <Icon fontSize="lg" color="info">
                    add_a_photo
                  </Icon>
                </MDAvatar>
              </MDBox>
            )}
          </MDBox>
          <MDTypography variant="caption" fontWeight="medium">
            Изображений добавлено: {originalImages.length} из {imagesLimit}.
          </MDTypography>
        </Grid>
      </Grid>
    </Grid>
  );
}

ImageUploader.defaultProps = {
  imagesLimit: 5,
  images: [],
  _mainImage: {},
};

ImageUploader.propTypes = {
  imagesLimit: PropTypes.number,
  compressImagesCallback: PropTypes.func.isRequired,
  images: PropTypes.oneOfType([PropTypes.array]),
  _mainImage: PropTypes.oneOfType([PropTypes.object]),
};

export default ImageUploader;
