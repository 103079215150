/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import Menu from "@mui/material/Menu";
import MDBox from "components/MDBox";

// Models
import Client from "service/Models/Client";

// react
import { useState } from "react";
import { Link } from "react-router-dom";

// components
import NotificationItem from "examples/Items/NotificationItem";

export default function data(
  clients,
  clientsUpdateCallback,
  modalCallback,
  clientModalFormCallback
) {
  const rows = [];
  const [menuOwner, setMenuOwner] = useState(null);

  // handle context menu open
  const handleOpenMenu = (owner) => setMenuOwner(owner);

  // handle close context menu
  const handleCloseMenu = () => setMenuOwner(null);

  // handle disagree action on modal window
  const disagreeAction = () => {
    setMenuOwner(null);
    modalCallback(null);
  };

  // handle delete client
  const handleDeleteClient = () => {
    modalCallback({
      content: (
        <DialogContent>
          <DialogContentText>
            Вы действительно хотите безвозвратно удалить данного клиента?
          </DialogContentText>
        </DialogContent>
      ),
      agreeText: "Удалить",
      agreeColor: "error",
      disagreeColor: "light",
      agreeAction: async () => {
        setMenuOwner(null);
        const response = await Client.Delete(menuOwner.id);
        if (response) {
          const copyClients = [...clients];
          clients.forEach(({ id }, i) => {
            if (id === menuOwner.id) copyClients.splice(i, 1);
          });
          clientsUpdateCallback([...copyClients]);
        } else {
          // TODO сделать обработку ошибки через уведомление и/или модальное окно
          console.log(response);
        }
      },
      disagreeAction,
    });
  };

  const handleTrashClient = () => {
    modalCallback({
      content: (
        <DialogContent>
          <DialogContentText>Вы действительно хотите удалить данного клиента?</DialogContentText>
          <DialogContentText>
            <MDTypography variant="caption">
              После удаления запись о клиенте попадет в архив.
            </MDTypography>
          </DialogContentText>
        </DialogContent>
      ),
      agreeText: "удалить",
      agreeColor: "error",
      disagreeColor: "light",
      agreeAction: async () => {
        setMenuOwner(null);
        const response = await Client.Trash(menuOwner.id);
        if (response.success === true) {
          const copyClients = [...clients];
          clients.forEach(({ id }, i) => {
            if (id === menuOwner.id) copyClients[i] = response.client;
          });
          clientsUpdateCallback([...copyClients]);
        } else {
          // TODO сделать обработку ошибки через уведомление и/или модальное окно
          console.log(response);
        }
      },
      disagreeAction,
    });
  };

  const handleRestoreClient = () => {
    modalCallback({
      content: (
        <DialogContent>
          <DialogContentText>
            Вы действительно хотите восстановить данные данного клиента из архива?
          </DialogContentText>
        </DialogContent>
      ),
      agreeText: "восстановить",
      agreeColor: "success",
      disagreeColor: "light",
      agreeAction: async () => {
        setMenuOwner(null);
        const response = await Client.Restore(menuOwner.id);
        if (response.success === true) {
          const copyClients = [...clients];
          clients.forEach(({ id }, i) => {
            if (id === menuOwner.id) copyClients[i] = response.client;
          });
          clientsUpdateCallback([...copyClients]);
        } else {
          console.log(response);
        }
      },
      disagreeAction,
    });
  };

  // edit client handler
  const handleEditClient = () => {
    setMenuOwner(null);
    clientModalFormCallback(menuOwner);
  };

  // render context menu
  const renderMenu = () =>
    menuOwner.deleted_at === null ? (
      <Menu
        anchorEl={menuOwner ? menuOwner.anchor : null}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuOwner)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <NotificationItem onClick={handleTrashClient} icon={<Icon>delete</Icon>} title="Удалить" />
        <NotificationItem
          onClick={handleEditClient}
          icon={<Icon>edit</Icon>}
          title="Редактировать"
        />
      </Menu>
    ) : (
      <Menu
        anchorEl={menuOwner ? menuOwner.anchor : null}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuOwner)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <NotificationItem
          onClick={handleRestoreClient}
          icon={<Icon>restore</Icon>}
          title="Восстановить"
        />
        <NotificationItem
          onClick={handleDeleteClient}
          icon={<Icon>delete_forever</Icon>}
          title="Удалить"
        />
      </Menu>
    );

  if (clients.length !== 0) {
    clients
      .filter((client) => client.view === true)
      .forEach((client, i) => {
        rows[i] = {
          name: {
            link: `/crm/clients/${client.id}`,
            jsx: (
              <MDBox>
                <MDTypography
                  component={Link}
                  to={`/crm/clients/${client.id}`}
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {client.full_name}
                </MDTypography>
              </MDBox>
            ),
          },
          phone: {
            link: `/crm/clients/${client.id}`,
            jsx: (
              <MDBox>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {client.phone || "—"}
                </MDTypography>
              </MDBox>
            ),
          },
          email: {
            link: `/crm/clients/${client.id}`,
            jsx: (
              <MDBox>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {client.email || "—"}
                </MDTypography>
              </MDBox>
            ),
          },
          action: (
            <MDBox>
              <MDTypography
                onClick={(e) => handleOpenMenu({ ...client, anchor: e.target })}
                component="div"
                color="text"
                sx={{ cursor: "pointer" }}
              >
                <Icon>more_vert</Icon>
              </MDTypography>
              {menuOwner !== null && renderMenu()}
            </MDBox>
          ),
        };
      });
  }

  return {
    columns: [
      {
        Header: "ФИО",
        accessor: "name",
        width: "35%",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      { Header: "Телефон", accessor: "phone", align: "left", Cell: ({ value }) => value.jsx },
      { Header: "Email", accessor: "email", align: "left", Cell: ({ value }) => value.jsx },
      { Header: "Действия", accessor: "action", align: "right" },
    ],

    rows,
  };
}
