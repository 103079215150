// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Preloader from "layouts/preloader";
import ClientCard from "layouts/cash/components/clientCard";

// React
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Models
import Cash from "service/Models/Cash";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

// utilities
import { dateWord } from "service/utilities";

// components
// import ProductsCard from "layouts/cash/components/ProductsCard";

function Transaction() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { id } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  document.title = `Операция ${id}`;

  useEffect(() => {
    const getTransaction = async () => {
      const response = await Cash.Read(id);
      if (response.success === true) return response.cash;
      navigate("/crm/cash");
      throw new Error(response);
    };

    getTransaction()
      .then((response) => {
        setTransaction(response);
        setIsLoaded(true);
      })
      .catch((err) => console.error(err));
  }, []);

  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title={`Операция ${id}`} />
        <MDBox py={2} px={2} mx="auto" sx={{ maxWidth: "md", width: "100%" }}>
          <Card>
            <MDBox py={2} px={2}>
              <MDBox display="flex" flexDirection="column">
                <MDTypography variant="h6" fontWeight="medium">
                  Детали операции
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="bold">
                  Операция №{id} от {dateWord(transaction.date)}
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="bold"
                    sx={{ marginRight: "0.3rem" }}
                  >
                    Комментарий:
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {transaction.commentary || "-"}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="bold"
                    sx={{ marginRight: "0.3rem" }}
                  >
                    Примечание:
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {transaction.note || "-"}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Divider light={darkMode} />
              {/* transaction.products.length !== 0 && (
                <MDTypography variant="h6" fontWeight="medium">
                  Товары
                </MDTypography>
              )}
              {transaction.products.length !== 0 && (
                <ProductsCard products={transaction.products} />
              ) */}
              {transaction.client_id && (
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">
                    Клиент
                  </MDTypography>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <ClientCard client={transaction.client} noGutter />
                  </MDBox>
                </MDBox>
              )}
              {transaction.products.length !== 0 && <Divider light={darkMode} />}
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium">
                  Итог
                </MDTypography>
                <MDBox>
                  {transaction.products.map((product) => (
                    <MDBox display="flex" justifyContent="space-between" key={product.name}>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        {product.name}:
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        color={product.pivot.price > 0 ? "success" : "error"}
                      >
                        {product.pivot.price}
                      </MDTypography>
                    </MDBox>
                  ))}
                  <MDBox display="flex" justifyContent="space-between">
                    <MDTypography variant="body1" fontWeight="regular" color="text">
                      Всего:
                    </MDTypography>
                    <MDTypography
                      variant="body1"
                      fontWeight="bold"
                      color={transaction.status === "+" ? "success" : "error"}
                    >
                      {transaction.value} руб.
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Transaction;
