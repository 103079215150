// выражения для валидации
// TODO: обсудить выражение для валидации компании
// возможно следует добавить короткое название компании и официальное название компании
// или название компании нужно заводить автоматом при заключении контракта??
const validateRegExps = {
  email: /^(?=.{7,45}$)([A-Z0-9._%+-]+@[A-Z0-9-]+[.]+[A-Z]{2,4})$/i, // compared
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(.){8,}$/g, // compared
  companyName: /^[?!,.а-яА-ЯёЁ0-9\s]{4,45}$/i, // compared
  name: /^([а-яё]{2,15}|[a-z]{2,15})$/i, // compared
  productName: /^.{1,25}$/,
  // phone: /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
  phone: /^8[0-9]{10}$/, // compared
  price: /^(\d+(\.\d{1,2})|\d+|^$)?$/,
  preparingTime: /^([0]{1}|[1-9]{1}|[1-9]{1}[0-9]{1}|^$)$/, // compared
  ageRange: /^(0|[1-9][0-9]*|^$)$/,
};

// функция валидации
const validate = (pValidateValue, pRegExp) => {
  const res = pValidateValue.match(pRegExp) || [];
  if (res.length && res[0].length === pValidateValue.length) {
    return res[0];
  }
  return "";
};

const validationFunctions = {
  min: (value, minCharactersCount) => {
    if (value.length < minCharactersCount) {
      return `Поле :attribute не должно содержать меньше ${minCharactersCount} символов`;
    }
    return "";
  },
  max: (value, maxCharactersCount) => {
    if (value.length > maxCharactersCount) {
      return `Поле :attribute не должно содержать больше ${maxCharactersCount} символов`;
    }
    return "";
  },
  required: (value) => {
    if (value === null || value === undefined || value === "") {
      return `Поле :attribute не может быть пустым`;
    }
    return "";
  },
  between: (value, between) => {
    const numericValue = parseInt(value, 10);
    let error = "";
    if (typeof numericValue === "number") {
      let [betweenBottom, betweenTop] = between.split(",");
      betweenBottom = betweenBottom.trim();
      betweenTop = betweenTop.trim();
      if (numericValue < betweenBottom) {
        error = `Поле :attribute не должно быть меньше ${betweenBottom}`;
      }
      if (numericValue > betweenTop) {
        error = `Поле :attribute не должно быть больше ${betweenTop}`;
      }
    }
    return error;
  },
  fixed: (value, fixedCount) => {
    let error = "";
    const floatNumbers = `${value}`.split(".")[1];
    const numericFixedCount = parseInt(fixedCount, 10);
    if (floatNumbers && floatNumbers.length > numericFixedCount) {
      if (numericFixedCount === 0) {
        error = `Поле :attribute должно быть целым числом`;
      } else {
        error = `Поле :attribute должно содержать не больше ${fixedCount} чисел после запятой`;
      }
    }
    return error;
  },
};

const Validate = (value, rules, attribute) => {
  const validationErrors = [];
  rules.forEach((rule) => {
    const [ruleName, ruleProp] = rule.split(":");
    let validationResult = "";
    if (ruleProp) {
      validationResult = validationFunctions[ruleName](value, ruleProp);
    } else {
      validationResult = validationFunctions[ruleName](value);
    }
    if (validationResult) {
      validationResult = validationResult.replace(":attribute", attribute);
      validationErrors[validationErrors.length] = validationResult;
    }
  });
  return validationErrors;
};

export { validateRegExps, validate, Validate };
