// mui components
import Collapse from "@mui/material/Collapse";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";

// material dashboard 2 components
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Components
import CustomDialog from "examples/CustomDialog";

// proptypes
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";

// Models
import Rent from "service/Models/Rent";
import RentObject from "service/Models/RentObject";
import Cash from "service/Models/Cash";

// utilities
import { reduceAccByColName, getCurrentDate } from "service/utilities";

// Validator
import { Validate } from "service/validator";

function CancelRentDialog({ open, rent, onAgreeSuccess, onAgreeFailure, onClose }) {
  const getTransactionsSum = () => reduceAccByColName(rent.cash, "value");
  const getRefundPrepayment = () => {
    let prepaymentPricesArray = [];
    rent.objects.forEach((object) => {
      prepaymentPricesArray = [
        ...prepaymentPricesArray,
        object.product.prices.filter((price) => price.price_type === 3)[0],
      ];
    });
    const prepaymentSum = reduceAccByColName(prepaymentPricesArray, "price");
    const transactionsSum = getTransactionsSum();
    if (prepaymentSum <= transactionsSum) return transactionsSum - prepaymentSum;
    return 0;
  };

  const [formState, setFormState] = useState({
    transaction_checked: {
      value: false,
      validation: {
        is_dirty: false,
      },
    },
    refund_payment: {
      value: getRefundPrepayment(),
      validation: {
        is_valid: true,
        message: [],
        is_dirty: false,
      },
    },
  });

  const returnObjectRequest = (id) =>
    RentObject.Update({ id, is_returned: true }).then((response) => {
      if (response.success !== true) throw new Error(response);
      return response.objects;
    });

  const cancelRentRequest = () =>
    Rent.Update(rent.id, { is_cancel: true }).then((response) => {
      if (response.success !== true) throw new Error(response);
      const returnObjectPromises = rent.objects
        .filter((object) => object.is_actual)
        .map(({ id }) => returnObjectRequest(id));
      return Promise.all(returnObjectPromises);
    });

  const createTransactionRequest = async () =>
    Cash.Create({
      note: `Возврат средств по договору бронирования №${rent.id}`,
      value: -formState.refund_payment.value,
      date: getCurrentDate(),
      client_id: rent.client_id,
      rent_id: rent.id,
    }).then((response) => {
      if (response.success !== true) throw new Error(response);
      return response.cash;
    });

  const handleInputChange = (event) => {
    const { value } = event.target;
    const validationErrors = Validate(
      value,
      [`between:0,${getTransactionsSum()}`, "fixed:2", "required"],
      "'Сумма возврата'"
    );
    setFormState({
      ...formState,
      refund_payment: {
        value,
        validation: {
          is_valid: validationErrors.length === 0,
          message: validationErrors,
          is_dirty: true,
        },
      },
    });
  };

  const handleCheckboxChanged = (event) => {
    const value = event.target.checked;
    const newFormState = { ...formState };
    newFormState.transaction_checked = {
      value,
      validation: {
        is_dirty: true,
      },
    };
    if (!value)
      newFormState.refund_payment = {
        value: getRefundPrepayment(),
        validation: {
          is_valid: true,
          message: [],
          is_dirty: false,
        },
      };
    setFormState(newFormState);
  };

  const cancelRentDialogContent = () => (
    <DialogContent>
      <DialogContentText>Вы действительно хотите отменить бронирование?</DialogContentText>
      {getTransactionsSum() > 0 && (
        <MDBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.transaction_checked.value}
                onClick={handleCheckboxChanged}
              />
            }
            label={
              <MDTypography variant="caption" color="text">
                Создать операцию возврата средств
              </MDTypography>
            }
          />
          <Collapse in={formState.transaction_checked.value}>
            {/* Подсказка */}
            <MDBox mb={1}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                Справочные данные:
              </MDTypography>
              {rent.objects.map((object) => (
                <MDBox display="flex" justifyContent="space-between" key={object.product.name}>
                  <MDTypography variant="button" color="text">
                    Стоимость предоплаты товара {object.product.name}:
                  </MDTypography>
                  <MDTypography variant="button" color="text" fontWeight="medium">
                    {object.product.prices.filter((price) => price.price_type === 3)[0].price} руб.
                  </MDTypography>
                </MDBox>
              ))}
              <Divider sx={{ marginY: 0 }} />
              <MDBox display="flex" justifyContent="flex-end">
                <MDTypography variant="body2" color="text" mr={2}>
                  Внесено средств:
                </MDTypography>
                <MDTypography variant="body2" color="text" fontWeight="medium">
                  {parseFloat(getTransactionsSum())} руб.
                </MDTypography>
              </MDBox>
            </MDBox>
            {/* Вывод ошибок валидации полей */}
            <Collapse in={!formState.refund_payment.validation.is_valid}>
              <Alert severity="error" icon={false}>
                {formState.refund_payment.validation.message.map((message) => (
                  <MDTypography
                    color="error"
                    variant="subtitle2"
                    fontWeight="medium"
                    sx={{ fontSize: "caption.fontSize" }}
                    key={message}
                  >
                    {message}
                  </MDTypography>
                ))}
              </Alert>
            </Collapse>
            {/* Поле ввода */}
            <MDInput
              sx={{ marginTop: 3, width: "100%" }}
              name="refund_payment"
              type="number"
              label="Сумма возврата"
              value={formState.refund_payment.value}
              onChange={handleInputChange}
              success={formState.refund_payment.validation.is_valid}
              error={!formState.refund_payment.validation.is_valid}
            />
          </Collapse>
        </MDBox>
      )}
    </DialogContent>
  );

  const freshDialogInfo = () => ({
    content: cancelRentDialogContent(),
    title: "Подтвердите действие",
    buttons: [
      {
        name: "Отменить",
        color: "light",
        action: () => {
          setFormState({
            transaction_checked: { value: false, validation: { is_dirty: false } },
            refund_payment: {
              value: getRefundPrepayment(),
              validation: { is_valid: true, message: [], is_dirty: false },
            },
          });
          onClose();
        },
      },
      {
        name: "Подтвердить",
        disabled: !formState.refund_payment.validation.is_valid,
        color: "error",
        action: () => {
          cancelRentRequest()
            .then(() => {
              if (formState.transaction_checked.value && formState.refund_payment.value !== 0)
                return createTransactionRequest();
              return null;
            })
            .then((response) => {
              const copyRent = { ...rent };
              const actualObjects = copyRent.objects.filter((object) => object.is_actual);
              actualObjects.forEach((object, i) => {
                actualObjects[i].is_returned = true;
              });
              copyRent.is_cancel = true;
              if (response !== null) copyRent.cash = [...rent.cash, response];
              onAgreeSuccess(copyRent);
            })
            .catch((err) => {
              console.error(err);
              onAgreeFailure(err);
            });
          onClose();
        },
      },
    ],
    onClose: () => {
      setFormState({
        transaction_checked: { value: false, validation: { is_dirty: false } },
        refund_payment: {
          value: getRefundPrepayment(),
          validation: { is_valid: true, message: [], is_dirty: false },
        },
      });
      onClose();
    },
  });

  const [dialogInfo, setDialogInfo] = useState(freshDialogInfo());

  useEffect(() => {
    if (formState.transaction_checked.validation.is_dirty) setDialogInfo(freshDialogInfo());
  }, [formState]);

  return <CustomDialog open={open} {...dialogInfo} />;
}

// CancelRentDialog default prop-types
CancelRentDialog.defaultProps = {
  onAgreeSuccess: () => {},
  onAgreeFailure: () => {},
};

// CancelRentDialog prop-types
CancelRentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
  }).isRequired,
  onAgreeSuccess: PropTypes.func,
  onAgreeFailure: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default CancelRentDialog;
