// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import ProductGroupInfoCard from "components/InfoCard";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import DataTable from "examples/Tables/DataTable";

// Models
import ProductGroup from "service/Models/ProductGroup";

// Data
import preparedProductGroupData from "layouts/product_groups/data/preparedProductGroupData";
import productsOfGroupTableData from "layouts/products/data/productsOfGroupTableData";

// Components
import Header from "layouts/product_groups/components/Header";
import Preloader from "layouts/preloader";

// react
import { useEffect, useState } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";

function Overview() {
  // modal windows variables and states
  // fresh modal window function
  const freshModalWindow = {
    row: null,
    message: "",
    action: null,
    agreeText: "",
    agreeColor: "info",
  };

  const [modalWindow, setModalWindow] = useState(freshModalWindow);

  const handleCloseWithAgree = () => {
    modalWindow.action();
    setModalWindow(freshModalWindow);
  };
  const handleCloseWithDisagree = () => setModalWindow(freshModalWindow);

  // get product information
  const { groupId } = useParams();
  const [productGroup, setProductGroup] = useState({});
  const { productGroupInfo } = preparedProductGroupData(productGroup);
  const [isLoaded, setIsLoaded] = useState(false);
  const [criticalError, setCriticalError] = useState(false);
  const { columns, rows } = productsOfGroupTableData(productGroup.products);
  const navigate = useNavigate();

  // get product group info
  useEffect(async () => {
    const response = await ProductGroup.GetById(groupId);
    if (response.success === true) {
      setProductGroup(response.groups);
      setIsLoaded(true);

      document.title = response.groups.name;
      return true;
    }
    setCriticalError(true);
    return false;
  }, []);

  const headerActions =
    productGroup.deleted_at === null
      ? [
          {
            route: `/crm/groups/${groupId}/update`,
            tooltip: "Редактировать группу",
            icon: "edit",
          },
          {
            route: "#",
            tooltip: productGroup.active ? "Сделать неактивной" : "Сделать активной",
            icon: productGroup.active ? "close" : "done",
            color: productGroup.active ? "dark" : "success",
            onClick: (event) => {
              event.preventDefault();
              setModalWindow({
                message: "Вы действительно хотите изменить статус группы?",
                agreeText: "изменить",
                agreeColor: "info",
                async action() {
                  const response = await ProductGroup.InvertStatus(groupId);
                  if (response.success === true) {
                    setProductGroup(response.group);
                  } else {
                    console.log(response);
                  }
                },
              });
            },
          },
          {
            route: `#`,
            tooltip: "Поместить в архив",
            icon: "delete",
            color: "error",
            onClick: (event) => {
              event.preventDefault();
              setModalWindow({
                row: productGroup,
                message: "Вы действительно хотите поместить данную группу в архив?",
                agreeText: "архивировать",
                agreeColor: "error",
                async action() {
                  const response = await ProductGroup.Trash(groupId);
                  if (response.success === true) {
                    setProductGroup(response.group);
                  } else {
                    // TODO сделать обработку ошибки через уведомление и/или модальное окно
                    console.log(response);
                  }
                },
              });
            },
          },
        ]
      : [
          {
            route: "#",
            tooltip: "Восстановить",
            icon: "restore",
            color: "success",
            onClick: (event) => {
              event.preventDefault();
              setModalWindow({
                message: "Вы действительно хотите восстановить данный товар?",
                agreeText: "восстановить",
                agreeColor: "success",
                async action() {
                  const response = await ProductGroup.Restore(groupId);
                  if (response.success === true) {
                    setProductGroup(response.group);
                  } else {
                    // TODO сделать обработку ошибки через уведомление и/или модальное окно
                    console.log(response);
                  }
                },
              });
            },
          },
          {
            route: "#",
            tooltip: "Удалить",
            icon: "delete_forever",
            color: "error",
            onClick: (event) => {
              event.preventDefault();
              setModalWindow({
                message: "Вы действительно хотите безвозвратно удалить данный товар?",
                agreeText: "удалить",
                agreeColor: "error",
                async action() {
                  const response = await ProductGroup.Delete(groupId);
                  if (response) {
                    navigate("/crm/group");
                  } else {
                    // TODO сделать обработку ошибки через уведомление и/или модальное окно
                    console.log(response);
                  }
                },
              });
            },
          },
        ];

  if (criticalError) {
    return <Navigate to="/crm/groups" />;
  }

  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      <Dialog
        open={modalWindow.row !== null}
        onClose={handleCloseWithDisagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <MDTypography>Подтвердите действие</MDTypography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{modalWindow.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseWithDisagree}>Отмена</MDButton>
          <MDButton onClick={handleCloseWithAgree} color={modalWindow.agreeColor}>
            {modalWindow.agreeText}
          </MDButton>
        </DialogActions>
      </Dialog>
      <DashboardLayout>
        <DashboardNavbar title={productGroup.name} />
        <MDBox mb={3} />
        <Header
          image={productGroup.main_image ? productGroup.main_image.path : ""}
          name={productGroup.name}
          actions={headerActions}
        >
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} xl={6} sx={{ display: "flex", justifyContent: "space-between" }}>
                <ProductGroupInfoCard title="информация" info={productGroupInfo} shadow={false} />
                <Divider
                  orientation="vertical"
                  sx={{ mx: 0, display: { xs: "none", xl: "block" } }}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <MDBox pt={2} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Товары
                  </MDTypography>
                </MDBox>
                <DataTable
                  entriesPerPage={{
                    defaultValue: 20,
                    entries: [5, 10, 15, 20, 40],
                  }}
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Overview;
