// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";

// Overview page components
import Header from "layouts/profile/components/Header";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// react
import { useState, useEffect } from "react";

// requests
import { API_GET_MANAGERS } from "service/request";

function Overview() {
  const [user, setUser] = useState({});
  const [managers, setManagers] = useState([]);
  const updateUser = (value) => {
    setUser(value);
  };

  // get managers
  useEffect(async () => {
    const managersRequestResult = await API_GET_MANAGERS();
    if (managersRequestResult.ok) {
      const managersArr = await managersRequestResult.json();
      setManagers(managersArr);
      return true;
    }
    return false;
  }, []);

  return (
    <AuthorizeUser userReturnRequired={user.id === undefined} userCallback={updateUser}>
      <DashboardLayout>
        <DashboardNavbar title="Профиль" />
        <MDBox mb={8} />
        <Header user={user}>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <PlatformSettings settings={user.settings} />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                <Divider
                  orientation="vertical"
                  sx={{ ml: -2, mr: 1, display: { xs: "none", md: "block" } }}
                />
                <ProfileInfoCard
                  title="Информация"
                  description={user.description || "Нет описания."}
                  info={{
                    имя: `${user.last_name} ${user.first_name} ${user.patronymic}`,
                    телефон: user.phone,
                    email: user.email,
                    telegramID: user.telegram_id || "-",
                  }}
                  social={[]}
                  action={{ route: "$", tooltip: "Редактировать профиль" }}
                  shadow={false}
                />
              </Grid>
              <Grid item xs={12} xl={4}>
                <ProfilesList title="сотрудники" profiles={managers} shadow={false} />
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Overview;
