/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// utilities
import { dateWord } from "service/utilities";

// react
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// material dashboard 2 components
import MDTypography from "components/MDTypography";

// Models
import RentObject from "service/Models/RentObject";

// components
import ObjectStatus from "layouts/rents/components/ObjectStatus";

export default function data() {
  const [rentObjects, setRentObjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const rows = [];

  useEffect(() => {
    const getRentObjects = async () => {
      const response = await RentObject.Read();
      if (response.success === true) return response.rent_objects;
      throw new Error(response);
    };

    getRentObjects()
      .then((respRentObjects) => {
        const actualObjects = respRentObjects.filter(
          (object) => !object.is_returned && object.is_actual && object.rent.issue_date !== null
        );
        setRentObjects(actualObjects);
        setIsLoaded(true);
      })
      .catch((err) => console.error(err));
  }, []);

  if (rentObjects.length !== 0) {
    rentObjects.forEach((object) => {
      rows[rows.length] = {
        product_name: {
          link: `/crm/rents/${object.rent_id}`,
          jsx: (
            <MDTypography
              component={Link}
              to={`/crm/rents/${object.rent_id}`}
              variant="button"
              fontWeight="regular"
              color="text"
            >
              {object.product.name}
            </MDTypography>
          ),
        },
        client: {
          link: `/crm/rents/${object.rent_id}`,
          jsx: (
            <MDTypography
              component={Link}
              to={`/crm/clients/${object.rent.client_id}`}
              variant="button"
              color="text"
              fontWeight="regular"
            >
              {object.rent.client.full_name}
            </MDTypography>
          ),
        },
        status: {
          link: `/crm/rents/${object.rent_id}`,
          jsx: <ObjectStatus rent={object.rent} object={object} />,
        },
        return_date: {
          link: `/crm/rents/${object.rent_id}`,
          jsx: dateWord(object.return_date),
        },
      };
    });
  }

  return {
    columns: [
      {
        Header: "Товар",
        accessor: "product_name",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Статус",
        accessor: "status",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Клиент",
        accessor: "client",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Дата возврата",
        accessor: "return_date",
        width: "20%",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
    ],
    rows,
    isLoaded,
  };
}
