// Утилиты
import { isXSRFExist } from "./utilities";

class RequestException extends Error {
  constructor(name, message) {
    super(message); // (1)
    this.name = name; // (2)
  }
}

// DEFINE API DOMAIN
const API_DOMAIN =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_DOMAIN
    : process.env.REACT_APP_DEV_API_DOMAIN;

// API functions
/**
 * Get the XSRF-token from the server.
 *
 * @return bool
 */
const GET_XSRF = async () => {
  const url = `${API_DOMAIN}sanctum/csrf-cookie`;
  const response = await fetch(url, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new RequestException("Network exception", "Server side issues");
  }
  // проверяем на то установились ли возвращенные cookie
  if (!isXSRFExist()) {
    throw new RequestException("Domain stateful", "Incorrectly configured Sanctum Stateful Domain");
  }
  // если все ОК то возвращаем true
  return true;
};

/**
 * Retrieve token from storage.
 *
 * @return string, null
 */
function RETRIEVE_TOKEN() {
  const storageToken = sessionStorage.getItem("API_TOKEN") || localStorage.getItem("API_TOKEN");
  if (storageToken === null) {
    return null;
  }
  return storageToken;
}

/**
 * Remove token from storage.
 *
 */
function REMOVE_LOCAL_TOKEN() {
  sessionStorage.removeItem("API_TOKEN");
  localStorage.removeItem("API_TOKEN");
}

/**
 * Retrieve xsrf from storage.
 *
 * @return string, null
 */
function RETRIEVE_XSRF() {
  if (document.cookie.indexOf("XSRF-TOKEN=") !== -1) {
    return document.cookie.split("XSRF-TOKEN=")[1].split("%3D")[0];
  }
  return null;
}

/**
 * Get user data using session and XSRF
 *
 * @return object
 */
const API_GET_USER = async (pBearerToken) => {
  const url = `${API_DOMAIN}api/user`;
  const request = {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
  };
  if (pBearerToken !== null) {
    request.headers.Authorization = `Bearer ${pBearerToken}`;
  }
  const result = await fetch(url, request);
  const resultJSON = await result.json();
  // if user not auth returned 401
  if (result.status === 401) {
    REMOVE_LOCAL_TOKEN();
    return {};
  }
  // if user auth return user data
  return resultJSON;
};

/**
 * Get access token
 *
 * @return object
 * {
 *    success: <bool>,
 *    (message: <string>(validation errors)
 *    ||
 *    access_token: <string>)
 * }
 */
const API_LOGIN = async (pEmail, pPassword, pRememberMe = false) => {
  const formData = {
    email: pEmail,
    password: pPassword,
    rememberMe: pRememberMe,
  };
  const url = `${API_DOMAIN}api/login`;
  const requestResult = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(formData),
  });
  const resJson = await requestResult.json();
  return resJson;
};

/**
 * Remove access token
 *
 * @return bool
 */
const API_LOGOUT = async (pBearerToken) => {
  const url = `${API_DOMAIN}api/logout`;
  const requestResult = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${pBearerToken}`,
      Accept: "application/json",
    },
  });
  return requestResult.ok;
};

/**
 * Register new company/user
 *
 * @return object
 * {
 *    success: <bool>,
 *    (message: <string>(validation errors)
 *    ||
 *    user: <object>)
 * }
 */
const API_REGISTER = async (
  pCompanyName,
  pFirstName,
  pLastName,
  pPatronymic,
  pEmail,
  pPhone,
  pPassword,
  pPasswordConfirmation
) => {
  const formData = {
    company_name: pCompanyName,
    first_name: pFirstName,
    last_name: pLastName,
    patronymic: pPatronymic,
    email: pEmail,
    phone: pPhone,
    password: pPassword,
    password_confirmation: pPasswordConfirmation,
  };
  const url = `${API_DOMAIN}api/register`;
  const requestResult = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(formData),
  });
  const requestResultJSON = await requestResult.json();
  return requestResultJSON;
};

/**
 * Get product by id
 *
 * @return object
 */
const API_GET_PRODUCT_BY_ID = async (pId) => {
  const url = `${API_DOMAIN}api/products/${pId}`;
  const requestResult = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${RETRIEVE_TOKEN()}`,
    },
  });
  return requestResult;
};

/**
 * Get all company products groups
 *
 * @return object
 */
const API_GET_PRODUCT_GROUPS = async () => {
  const url = `${API_DOMAIN}api/product_groups`;
  const requestResult = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${RETRIEVE_TOKEN()}`,
    },
  });
  const requestResultJSON = await requestResult.json();
  return requestResultJSON;
};

/**
 * Create new product
 *
 * @return object
 * {
 *    success: <bool>,
 *    (message: <string>(validation errors)
 *    ||
 *    user: <object>)
 * }
 * @param
 */
const API_CREATE_PRODUCT = async (
  pProductGroupId,
  pName,
  pActive,
  pPrice,
  pDescription,
  pSiteDescription,
  pPreparingTime,
  pComplectation,
  pAgeRange
) => {
  const formData = {
    product_group_id: pProductGroupId,
    name: pName,
    active: pActive,
    price: pPrice,
    description: pDescription,
    site_description: pSiteDescription,
    preparing_time: pPreparingTime,
    complectation: pComplectation,
    age_range_bottom: pAgeRange[0],
    age_range_top: pAgeRange[1],
  };
  const url = `${API_DOMAIN}api/products/create`;
  const requestResult = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${RETRIEVE_TOKEN()}`,
    },
    body: JSON.stringify(formData),
  });
  const requestResultJSON = await requestResult.json();
  return requestResultJSON;
};

const API_RESEND_EMAIL = async () => {
  const url = `${API_DOMAIN}api/email/verification-notification`;
  const requestResult = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${RETRIEVE_TOKEN()}`,
    },
  });
  return requestResult;
};

const API_GET_MANAGERS = async () => {
  const url = `${API_DOMAIN}api/managers`;
  const requestResult = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${RETRIEVE_TOKEN()}`,
    },
  });
  return requestResult;
};

const API_EMAIL_VERIFY = async (pHash) => {
  const url = `${API_DOMAIN}api/email/verify/${pHash}`;
  const requestResult = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${RETRIEVE_TOKEN()}`,
    },
  });
  return requestResult;
};

export {
  API_EMAIL_VERIFY,
  API_GET_MANAGERS,
  API_RESEND_EMAIL,
  GET_XSRF,
  API_GET_USER,
  API_LOGIN,
  API_LOGOUT,
  API_REGISTER,
  RETRIEVE_TOKEN,
  REMOVE_LOCAL_TOKEN,
  RETRIEVE_XSRF,
  API_GET_PRODUCT_BY_ID,
  API_GET_PRODUCT_GROUPS,
  API_CREATE_PRODUCT,
};
