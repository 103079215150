// react
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

// components
import Preloader from "layouts/preloader";

// propTypes
import PropTypes from "prop-types";

// Model
import User from "service/Models/User";

/**
 * Authorize user for non-protected pages components
 * @param children {JSXElement}
 * @param userCallback {function}
 * @returns {JSX.Element}
 */
function AuthorizeUser({ children, userCallback }) {
  const [user, setUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // future functional variables
  // const [criticalError, setCriticalError] = useState({});

  // get authentication
  useEffect(async () => {
    User.Read()
      .then((response) => {
        if (response.ok) return response.json();
        return Promise.reject(response);
      })
      .then((json) => {
        setUser(json);
        userCallback(json);
        setIsLoaded(true);
      })
      .catch((err) => {
        if (err.status === 401) setIsLoaded(true);
        console.error(err);
      });
  }, []);

  // future functional
  // if (objectIsNotEmpty(criticalError)) {
  //   return <Navigate to="/error" />;
  //   // return <Error />;
  // }

  /* if the loading is not completed, show the preloader
  if loaded complete skip ahead */
  if (!isLoaded) return <Preloader />;

  /* if the user is found, then the token is valid,
  we send it to the main page */
  if (user) return <Navigate to="/crm" />;

  /* if the user is not found show required non-protected page */
  return <div>{children}</div>;
}

// Setting default props for the Header
AuthorizeUser.defaultProps = {
  children: <div />,
  userCallback: () => false,
};

// Typechecking props for the Header
AuthorizeUser.propTypes = {
  children: PropTypes.node,
  userCallback: PropTypes.func,
};

export default AuthorizeUser;
