// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Filter from "examples/Filter";

// Data
import transactionsTableData from "layouts/cash/data/transactionsTableData";

// React
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import CustomizableDialog from "layouts/products/components/CustomizableDialog";

// Models
import Cash from "service/Models/Cash";

function AllTransactions() {
  const [customizableDialogInfo, setCustomizableDialogInfo] = useState(null);
  const updateCustomizableDialogInfo = (value) => setCustomizableDialogInfo(value);
  const handleCustomizableDialogClose = () => setCustomizableDialogInfo(null);
  const navigate = useNavigate();
  const getCurrentDate = (jsDate) => {
    const year = jsDate.getFullYear();
    const month = jsDate.getMonth() + 1;
    const day = jsDate.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
  };
  const shiftDate = (date, yearShift = 0, monthShift = 0, dayShift = 0) => {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + yearShift);
    dateCopy.setMonth(dateCopy.getMonth() + monthShift);
    dateCopy.setDate(dateCopy.getDate() + dayShift);
    return getCurrentDate(dateCopy);
  };

  const [filters, setFilters] = useState([
    {
      name: "statusFilter",
      label: "По статусу",
      value: {
        label: "Все операции",
        id: 0,
      },
      options: [
        {
          label: "Все операции",
          id: 0,
        },
        {
          label: "Пополнение",
          id: 1,
        },
        {
          label: "Списание",
          id: 2,
        },
      ],
      select: true,
    },
  ]);
  const [dateFilters, setDateFilters] = useState({
    startDate: shiftDate(new Date(), 0, -1, 0),
    endDate: getCurrentDate(new Date()),
  });

  const updateFiltersCallback = (value) => setFilters(value);

  const [cash, setCash] = useState([]);
  const updateCashCallback = (value) => {
    setCash(value);
    setFilters([...filters]);
  };

  const calculateCash = () => {
    const result = {
      value: 0,
      icon: "expand_less",
      color: "success",
    };
    cash.forEach((transaction) => {
      result.value += parseFloat(transaction.value);
    });
    if (result.value === 0) {
      result.icon = "priority_high";
      result.color = "dark";
    } else if (result.value < 0) {
      result.icon = "expand_more";
      result.color = "error";
    }
    return result;
  };

  const handleChangeDateFilter = (event) => {
    setDateFilters({ ...dateFilters, [event.target.name]: event.target.value });
    setFilters([...filters]);
  };

  // filter out
  useEffect(() => {
    // reset the visibility of all products
    cash.forEach((transaction, index) => {
      cash[index].view = false;
    });

    cash.forEach((transaction, index) => {
      if (filters[0].value.id === 0) cash[index].view = true;
      else if (filters[0].value.id === 1 && transaction.status === "+") cash[index].view = true;
      else if (filters[0].value.id === 2 && transaction.status === "-") cash[index].view = true;
      else cash[index].view = false;
    });

    const startDate = new Date(dateFilters.startDate).getTime();
    const endDate = new Date(dateFilters.endDate).getTime();
    cash.forEach((transaction, index) => {
      if (transaction.view === true) {
        const date = new Date(transaction.date).getTime();
        if (startDate <= date && date <= endDate) cash[index].view = true;
        else cash[index].view = false;
      }
    });

    setCash([...cash]);
  }, [filters]);

  useEffect(() => {
    const getCash = async () => {
      const response = await Cash.Read();
      if (response.success === true) {
        setCash(response.cash);
        setFilters([...filters]);
        return true;
      }
      // TODO добавить обработку неудачного запроса
      console.log(response);
      return false;
    };

    getCash();
  }, []);

  const { columns, rows } = transactionsTableData(
    cash,
    updateCashCallback,
    updateCustomizableDialogInfo
  );

  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };

  return (
    <AuthorizeUser>
      {Boolean(customizableDialogInfo) && (
        <CustomizableDialog
          message={customizableDialogInfo.message}
          agreeColor={customizableDialogInfo.agreeColor}
          agreeText={customizableDialogInfo.agreeText}
          agreeAction={customizableDialogInfo.action}
          disagreeAction={handleCustomizableDialogClose}
        />
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={3}>
          <Card sx={{ heigth: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Состояние кассы
              </MDTypography>
              <MDBox display="flex" alignItems="flex-start">
                <MDBox color="text" mr={0.5} lineHeight={0}>
                  <Icon color="inherit" fontSize="small">
                    date_range
                  </Icon>
                </MDBox>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  За все время
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
              <MDBox display="flex" alignItems="center">
                <MDBox mr={2}>
                  <MDButton variant="outlined" color={calculateCash().color} iconOnly circular>
                    <Icon sx={{ fontWeight: "bold" }}>{calculateCash().icon}</Icon>
                  </MDButton>
                </MDBox>
                <MDTypography
                  variant="button"
                  color={calculateCash().color}
                  fontWeight="medium"
                  textGradient
                >
                  {calculateCash().value} руб.
                </MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox pt={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Операции
                  </MDTypography>
                  <Link to="/crm/cash/create">
                    <MDButton variant="outlined" color="white" size="small">
                      <Icon>add</Icon>&nbsp;Добавить операцию
                    </MDButton>
                  </Link>
                </MDBox>
                <Grid
                  container
                  px={3}
                  mt={3}
                  columnSpacing={2}
                  rowSpacing={0.5}
                  alignItems="flex-end"
                >
                  <Grid item xs={12} md={2}>
                    <Filter
                      filters={filters}
                      onChangeFilters={updateFiltersCallback}
                      widthParams={{ xs: 12 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MDInput
                      name="startDate"
                      type="date"
                      label="Начало"
                      value={dateFilters.startDate}
                      onChange={handleChangeDateFilter}
                      size="small"
                      sx={{ width: "100%" }}
                      InputProps={{
                        inputProps: { min: "2023-01-01", max: getCurrentDate(new Date()) },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MDInput
                      name="endDate"
                      type="date"
                      label="Конец"
                      value={dateFilters.endDate}
                      size="small"
                      sx={{ width: "100%" }}
                      onChange={handleChangeDateFilter}
                      InputProps={{
                        inputProps: { min: "2023-01-01", max: getCurrentDate(new Date()) },
                      }}
                    />
                  </Grid>
                </Grid>
                <MDBox>
                  <DataTable
                    entriesPerPage={{
                      defaultValue: 20,
                      entries: [5, 10, 15, 20, 25],
                    }}
                    table={{ columns, rows }}
                    isSorted={false}
                    showTotalEntries={false}
                    onCellClick={onCellClick}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default AllTransactions;
