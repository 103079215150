const EMAIL_REGEXP = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const DEFAULT_MESSAGE = "Поле должно быть корректным email адресом";

/**
 * required validator
 * @param value
 * @param message
 * @returns {Promise<null|string>}
 */
const email = async (value, message = DEFAULT_MESSAGE) =>
  value.match(EMAIL_REGEXP) ? null : message;

export default email;
