/**
 * Validate value by validation rules array
 * @param value {string}
 * @param validators {[{ validator: Validator<T>, message: string, param: undefined|number|string }]}
 * @returns {Promise<ValidationResult>}
 */
const validate = async (value, validators) => {
  let validationResult = null;
  let i = 0;

  while (validationResult === null && i < validators.length) {
    const { param, validator, message } = validators[i];
    // eslint-disable-next-line no-await-in-loop
    const res = param ? await validator(value, param, message) : await validator(value, message);
    if (res) validationResult = res;
    i += 1;
  }

  return validationResult;
};

export { default as required } from "service/Validate/Validators/required";
export { default as maxLength } from "service/Validate/Validators/maxLength";
export { default as minLength } from "service/Validate/Validators/minLength";
export { default as email } from "service/Validate/Validators/email";
export { default as mixedCase } from "service/Validate/Validators/mixedCase";
export default validate;
