// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

import BasicLayout from "examples/LayoutContainers/PageLayout";
import AuthorizeUser from "layouts/authentication/is-authorized/email-notice-pages";
import GreetingsNavbar from "examples/Navbars/GreetingsNavbar";

import { API_RESEND_EMAIL, API_LOGOUT, REMOVE_LOCAL_TOKEN, RETRIEVE_TOKEN } from "service/request";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function EmailNotification() {
  const mailAddress = [
    {
      domain: "mail.ru",
      name: "Почта Mail.Ru",
      address: "https://e.mail.ru/",
    },
    {
      domain: "bk.ru",
      name: "Почта Mail.Ru (bk.ru)",
      address: "https://e.mail.ru/",
    },
    {
      domain: "list.ru",
      name: "Почта Mail.Ru (list.ru)",
      address: "https://e.mail.ru/",
    },
    {
      domain: "inbox.ru",
      name: "Почта Mail.Ru (inbox.ru)",
      address: "https://e.mail.ru/",
    },
    {
      domain: "yandex.ru",
      name: "Яндекс.Почта",
      address: "https://mail.yandex.ru/",
    },
    {
      domain: "ya.ru",
      name: "Яндекс.Почта",
      address: "https://mail.yandex.ru/",
    },
    {
      domain: "yandex.ua",
      name: "Яндекс.Почта",
      address: "https://mail.yandex.ua/",
    },
    {
      domain: "yandex.by",
      name: "Яндекс.Почта",
      address: "https://mail.yandex.by/",
    },
    {
      domain: "yandex.kz",
      name: "Яндекс.Почта",
      address: "https://mail.yandex.kz/",
    },
    {
      domain: "yandex.com",
      name: "Yandex.Mail",
      address: "https://mail.yandex.com/",
    },
    {
      domain: "gmail.com",
      name: "Gmail",
      address: "https://mail.google.com/",
    },
    {
      domain: "googlemail.com",
      name: "Gmail",
      address: "https://mail.google.com/",
    },
    {
      domain: "outlook.com",
      name: "Outlook.com",
      address: "https://mail.live.com/",
    },
    {
      domain: "hotmail.com",
      name: "Outlook.com",
      address: "https://mail.live.com/",
    },
    {
      domain: "live.ru",
      name: "Outlook.com (live.ru)",
      address: "https://mail.live.com/",
    },
    {
      domain: "live.com",
      name: "Outlook.com (live.com)",
      address: "https://mail.live.com/",
    },
    {
      domain: "me.com",
      name: "iCloud Mail",
      address: "https://www.icloud.com/",
    },
    {
      domain: "icloud.com",
      name: "iCloud Mail",
      address: "https://www.icloud.com/",
    },
    {
      domain: "rambler.ru",
      name: "Рамблер-Почта",
      address: "https://mail.rambler.ru/",
    },
    {
      domain: "yahoo.com",
      name: "Yahoo! Mail",
      address: "https://mail.yahoo.ru/",
    },
    {
      domain: "ukr.net",
      name: "Почта ukr.net",
      address: "https://mail.ukr.net/",
    },
    {
      domain: "i.ua",
      name: "Почта I.UA",
      address: "https://mail.i.ua/",
    },
    {
      domain: "bigmir.net",
      name: "Почта Bigmir.net",
      address: "http://mail.bigmir.net/",
    },
    {
      domain: "tut.by",
      name: "Почта tut.by",
      address: "https://mail.tut.by/",
    },
    {
      domain: "inbox.lv",
      name: "Inbox.lv",
      address: "https://www.inbox.lv/",
    },
    {
      domain: "mail.kz",
      name: "Почта mail.kz",
      address: "http://mail.kz/",
    },
  ];
  const getMailAddressByDomain = (domain) => {
    let resultMail = {};
    mailAddress.forEach((item) => {
      if (item.domain === domain) {
        resultMail = item;
      }
    });
    return resultMail;
  };

  const navigate = useNavigate();
  const [successResend, setSuccessResend] = useState(null);
  const [user, setUser] = useState({});
  const userUpdate = (value) => {
    setUser(value);
  };

  const handleBackToAuthPage = async (event) => {
    event.preventDefault();
    const result = await API_LOGOUT(RETRIEVE_TOKEN());
    if (result) {
      REMOVE_LOCAL_TOKEN();
      navigate("/authentication/sign-up");
    }
  };

  const delayResendTime = 360;
  const [seconds, setSeconds] = useState(delayResendTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds >= 1 ? prevSeconds - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getMinutesFromSeconds = (_seconds) => {
    const minutes = Math.floor(_seconds / 60);
    const modSeconds = _seconds % 60;
    if (modSeconds < 10) return `${minutes}:0${modSeconds}`;
    return `${minutes}:${modSeconds}`;
  };

  const handleResendVerifyClick = async (event) => {
    event.preventDefault();
    const result = await API_RESEND_EMAIL();
    if (result.ok) {
      setSuccessResend(true);
      setSeconds(delayResendTime);
    } else {
      setSuccessResend(false);
    }
  };

  return (
    <AuthorizeUser userReturnRequired={user.id === undefined} userCallback={userUpdate}>
      <BasicLayout>
        <GreetingsNavbar searchProps={{}} mainLink signIn={false} />
        <MDBox
          py={1}
          px={{ xs: 4, sm: 3, lg: 2 }}
          mt={12}
          mb={8}
          mx="auto"
          width="90%"
          borderRadius="lg"
          maxWidth="xl"
        >
          <MDBox mb={2}>
            <MDButton variant="contained" color="white" onClick={handleBackToAuthPage}>
              <Icon fontSize="small" color="secondary">
                arrow_back
              </Icon>
              &nbsp;
              <MDTypography variant="button" color="secondary">
                Вернуться на страницу регистрации
              </MDTypography>
            </MDButton>
          </MDBox>
          <Card>
            <MDBox py={3} px={2}>
              <Grid container columnSpacing={6} py={2} px={1}>
                <Grid item xs={12} md={6}>
                  <MDTypography variant="h1">Письмо отравлено</MDTypography>
                  <MDTypography variant="body1" color="text" m={2}>
                    На указанную вами электронную почту было отправлено письмо с подтверждением. Для
                    того чтобы завершить регистрацию перейдите по ссылке указанной в письме.
                  </MDTypography>
                  {user.id !== undefined && getMailAddressByDomain(user.email.split("@")[1]).name && (
                    <MDBox>
                      <MDTypography variant="body1" color="text" m={2} mb={0}>
                        Пожалуйста, проверьте вашу почту
                      </MDTypography>
                      <MDBox textAlign="center">
                        <MDTypography
                          textAlign="center"
                          component="a"
                          href={getMailAddressByDomain(user.email.split("@")[1]).address}
                          variant="h3"
                          mx="auto"
                          color="info"
                          textGradient
                          target="blank"
                        >
                          {getMailAddressByDomain(user.email.split("@")[1]).name}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDTypography variant="body2" color="text" mt={2}>
                    <Icon fontSize="small">question_mark</Icon>
                    Мне не пришло письмо
                  </MDTypography>
                  <MDTypography variant="body2" color="text" my={2}>
                    Письмо могло попасть в раздел спам. Пожалуйства, проверьте данный раздел.
                  </MDTypography>
                  <MDTypography variant="body2" color="text" mt={2}>
                    <Icon fontSize="small">question_mark</Icon>В разделе спам нет письма
                  </MDTypography>
                  <MDTypography variant="body2" color="text" my={2}>
                    Возможно при отправке письма возникли ошибки. Если вы не получили письмо его
                    можно отправить заново. Повторно отправить письмо можно через&nbsp;
                    {getMinutesFromSeconds(seconds)} минут.
                  </MDTypography>
                  {successResend === true && (
                    <MDAlert color="success" width="100%" dismissible>
                      <Icon fontSize="small" alignItems>
                        done
                      </Icon>
                      &nbsp;Новое письмо отправлено на вашу почту.
                    </MDAlert>
                  )}
                  {successResend === false && (
                    <MDAlert color="error" width="100%" dismissible>
                      <Icon fontSize="small" alignItems>
                        done
                      </Icon>
                      &nbsp;Ошибка. Попробуйте позже.
                    </MDAlert>
                  )}
                  {seconds === 0 ? (
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleResendVerifyClick}
                    >
                      Отправить письмо заново
                    </MDButton>
                  ) : (
                    <MDButton variant="gradient" color="info" size="small" disabled>
                      Отправить письмо заново
                    </MDButton>
                  )}
                  {/* <MDTypography variant="body2" color="text" mt={2}>
                    <Icon fontSize="small">question_mark</Icon>Я хочу изменить данные введенные при
                    регистрации
                  </MDTypography>
                  <MDTypography variant="body2" color="text" my={2}>
                    После изменения данных Вам будет выслано новое письмо для подтвержедния почты.
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={handleResendVerifyClick}
                  >
                    Изменить данные
                  </MDButton> */}
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </BasicLayout>
    </AuthorizeUser>
  );
}

export default EmailNotification;
