// mui components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 Components
import MDInput from "components/MDInput";

// Prop-types
import PropTypes from "prop-types";

function Filter({ filters, onChangeFilters, widthParams }) {
  const handleChangeFilter = (value, filterName) => {
    const nFilters = [...filters];
    filters.forEach((filter, index) => {
      if (filter.name === filterName) nFilters[index].value = value;
    });
    onChangeFilters([...filters]);
  };

  const renderFilters = filters.map((filter) => (
    <Grid key={filter.name} item {...widthParams}>
      <Autocomplete
        name={filter.name}
        disableClearable
        options={filter.options}
        size="small"
        autoHighlight={false}
        selectOnFocus={false}
        sx={{ width: "100%", marginTop: "0.4rem" }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        defaultValue={filter.options[0]}
        value={filter.value}
        renderInput={(params) =>
          filter.select ? (
            <MDInput
              {...params}
              inputProps={{ ...params.inputProps, readOnly: true }}
              label={filter.label}
            />
          ) : (
            <MDInput {...params} label={filter.label} />
          )
        }
        onChange={(event, value) => handleChangeFilter(value, filter.name)}
      />
    </Grid>
  ));

  return (
    <Grid container spacing={3}>
      {renderFilters}
    </Grid>
  );
}

Filter.defaultProps = {
  widthParams: {
    lg: 2,
    sm: 3,
    xs: 12,
  },
};

Filter.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        label: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }).isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
        })
      ).isRequired,
      select: PropTypes.bool,
    })
  ).isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  widthParams: PropTypes.oneOfType([PropTypes.object]),
};

export default Filter;
