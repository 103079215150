// Material dashboard 2 examples components
import DataTable from "examples/Tables/DataTable";

// prop-types
import PropTypes from "prop-types";

// Models
import Client from "service/Models/Client";
import Product from "service/Models/Product";

// react
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import Preloader from "layouts/preloader";

// data
import rentProductsTableData from "layouts/clients/data/rentProductsTableData";

function RentProductsTable({ clientId }) {
  const [objects, setObjects] = useState([]);
  const { columns, rows } = rentProductsTableData(objects);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getRents = async () => {
      const response = await Client.ReadRents(clientId);
      if (response.success === true) return response.rents;
      throw new Error(response);
    };
    const getProduct = async (object) => {
      const copyObject = object;
      const response = await Product.GetById(object.product_id);
      if (response.success === true) {
        copyObject.product = response.products;
        return copyObject;
      }
      throw new Error(response);
    };

    getRents()
      .then(async (response) => {
        let resultObjects = [];
        response.forEach((rent) => {
          const notReturnedObjects = rent.objects.filter((object) => !object.is_returned);
          notReturnedObjects.forEach((notReturnedObject, index) => {
            notReturnedObjects[index].rent = rent;
          });
          resultObjects = [...resultObjects, ...notReturnedObjects];
        });
        const promises = resultObjects.map(getProduct);
        const objectsWithProd = await Promise.all(promises);
        return objectsWithProd;
      })
      .then((response) => {
        setObjects(response);
        setIsLoaded(true);
      })
      .catch((error) => console.log(error));
  }, []);

  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };

  if (!isLoaded) return <Preloader fullScreen={false} />;

  return (
    <DataTable
      entriesPerPage={{ entries: [5, 10, 15, 20, 50] }}
      showTotalEntries={false}
      isSorted={false}
      table={{ columns, rows }}
      onCellClick={onCellClick}
      noEndBorder
    />
  );
}

RentProductsTable.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RentProductsTable;
