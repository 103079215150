import MDBadge from "components/MDBadge";

export default function data(productGroup) {
  return {
    productGroupInfo: [
      {
        key: "siteDescription",
        label: "Описание для сайта",
        value: productGroup.site_description || "Нет описания",
      },
      {
        key: "description",
        label: "Внутренне описание",
        value: productGroup.description || "Нет описания",
      },
      {
        key: "active",
        label: "Статус",
        value:
          productGroup.deleted_at !== null ? (
            <MDBadge badgeContent="В архиве" color="error" variant="gradient" size="sm" />
          ) : (
            <MDBadge
              badgeContent={productGroup.active ? "активна" : "не активна"}
              color={productGroup.active ? "success" : "dark"}
              variant="gradient"
              size="sm"
            />
          ),
      },
    ],
  };
}
