// Material dashboard 2 examples components
import DataTable from "examples/Tables/DataTable";

// prop-types
import PropTypes from "prop-types";

// react
import { useNavigate } from "react-router-dom";

// material dashboard 2 components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// components
import Preloader from "layouts/preloader";

// mui components
import Card from "@mui/material/Card";

function BookingTable({ columns, rows, isLoaded }) {
  const navigate = useNavigate();

  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };

  if (!isLoaded) return <Preloader fullScreen={false} />;

  return (
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Бронирование
        </MDTypography>
      </MDBox>
      <DataTable
        entriesPerPage={{ entries: [5, 10, 15, 20, 50] }}
        showTotalEntries={false}
        isSorted={false}
        table={{ columns, rows }}
        onCellClick={onCellClick}
        noEndBorder
      />
    </Card>
  );
}

BookingTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoaded: PropTypes.bool.isRequired,
};

export default BookingTable;
