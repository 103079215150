// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import CreateTransactionForm from "layouts/cash/components/CreateTransactionForm";
import Preloader from "layouts/preloader";

// React
import { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

// Models
import Cash from "service/Models/Cash";

function UpdateTransaction() {
  document.title = "Обновить операцию";
  const { id } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [route, setRoute] = useState(useLocation().pathname.split("/").slice(2));

  useEffect(() => {
    const getTransaction = async () => {
      const response = await Cash.Read(id);
      if (response.success === true) {
        response.cash.value = Math.abs(response.cash.value);
        setTransaction(response.cash);
        setIsLoaded(true);

        route[route.indexOf(id)] = {
          name: `Операция ${response.cash.id}`,
          linkTo: `crm/cash/${id}`,
        };
        setRoute([...route]);

        return true;
      }
      navigate("/crm/cash");
      return false;
    };

    getTransaction();
  }, []);

  // if loaded complete
  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title="Обновить" _route={route} />
        <MDBox pt={2} px={2} mx="auto" sx={{ maxWidth: "md", width: "100%" }}>
          <Card>
            <MDBox pt={2} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Обновление операции
              </MDTypography>
            </MDBox>
            <CreateTransactionForm transaction={transaction} isUpdateAction />
          </Card>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default UpdateTransaction;
