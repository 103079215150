// react
import { useState } from "react";

// validate
import validate from "service/Validate/validate";

/**
 * use text input hook
 * @param id {string}
 * @param validators {[Validators]}
 * @param init {string}
 * @param confirm {boolean}
 */
export default function usePasswordInput(id, validators, init = "") {
  const [value, setValue] = useState(init);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);

  const updateError = (val) => setError(val);

  const handleChangeInput = async (event) => {
    const val = event.target.value;

    setValue(val);
    setIsDirty(true);
    setError(await validate(val, validators));
  };

  const handleChangeVisible = () => setVisible(!visible);

  return {
    id,
    value,
    error,
    updateError,
    handleChangeInput,
    isDirty,
    visible,
    handleChangeVisible,
  };
}
