// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// Components
import CustomDialog from "examples/Dialog";
import ModalClientForm from "layouts/clients/components/ModalClientForm";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

// Models
import Client from "service/Models/Client";

// react
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// utilities
import { getCurrentDate } from "service/utilities";

function Header({ children, client, updateClient }) {
  const navigate = useNavigate();
  const [dialogInfo, setDialogInfo] = useState(null);
  const [modalClientOpen, setModalClientOpen] = useState(null);

  const handleDeleteClient = () => {
    setDialogInfo({
      content: (
        <DialogContent>
          <DialogContentText>
            Вы действительно хотите безвозвратно удалить данного клиента?
          </DialogContentText>
        </DialogContent>
      ),
      agreeText: "Удалить",
      agreeColor: "error",
      disagreeColor: "light",
      agreeAction: async () => {
        const response = await Client.Delete(client.id);
        if (response) navigate("/crm/clients");
        else {
          // TODO сделать обработку ошибки через уведомление и/или модальное окно
          console.log(response);
        }
      },
      disagreeAction: () => setDialogInfo(null),
    });
  };

  const handleTrashClient = () => {
    setDialogInfo({
      content: (
        <DialogContent>
          <DialogContentText>Вы действительно хотите удалить данного клиента?</DialogContentText>
          <DialogContentText>
            <MDTypography variant="caption">
              После удаления запись о клиенте попадет в архив.
            </MDTypography>
          </DialogContentText>
        </DialogContent>
      ),
      agreeText: "удалить",
      agreeColor: "error",
      disagreeColor: "light",
      agreeAction: async () => {
        const response = await Client.Trash(client.id);
        if (response.success === true) updateClient({ ...client, deleted_at: getCurrentDate() });
        else {
          // TODO сделать обработку ошибки через уведомление и/или модальное окно
          console.log(response);
        }
      },
      disagreeAction: () => setDialogInfo(null),
    });
  };

  const handleRestoreClient = () => {
    setDialogInfo({
      content: (
        <DialogContent>
          <DialogContentText>
            Вы действительно хотите восстановить данные данного клиента из архива?
          </DialogContentText>
        </DialogContent>
      ),
      agreeText: "восстановить",
      agreeColor: "success",
      disagreeColor: "light",
      agreeAction: async () => {
        const response = await Client.Restore(client.id);
        if (response.success === true) updateClient({ ...client, deleted_at: null });
        else {
          // TODO сделать обработку ошибки через уведомление и/или модальное окно
          console.log(response);
        }
      },
      disagreeAction: () => setDialogInfo(null),
    });
  };

  return (
    <MDBox mb={5}>
      {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
      {Boolean(modalClientOpen) && (
        <ModalClientForm
          client={modalClientOpen}
          onSuccess={(newClientData) => updateClient(newClientData)}
          onClose={() => setModalClientOpen(null)}
        />
      )}
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 0,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar alt="profile-image" size="xl" shadow="sm" bgColor="light">
              <Icon fontSize="lg" color="disabled">
                local_phone
              </Icon>
            </MDAvatar>
          </Grid>
          <Grid item alignSelf="flex-start">
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {client.abbreviate_name}
              </MDTypography>
              {client.deleted_at !== null && (
                <MDBox ml={-1}>
                  <MDBadge size="small" variant="gradient" badgeContent="В архиве" color="error" />
                </MDBox>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2} sx={{ ml: "auto" }} display="flex" flexDirection="column">
            {!client.deleted_at ? (
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{ marginBottom: 1 }}
                onClick={() => setModalClientOpen(client)}
              >
                <Icon>edit</Icon>&nbsp;Редактировать
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                sx={{ marginBottom: 1 }}
                onClick={handleRestoreClient}
              >
                <Icon>restore</Icon>&nbsp;Восстановить
              </MDButton>
            )}
            {!client.deleted_at ? (
              <MDButton variant="gradient" color="error" size="small" onClick={handleTrashClient}>
                <Icon>delete</Icon>&nbsp;Удалить
              </MDButton>
            ) : (
              <MDButton variant="gradient" color="error" size="small" onClick={handleDeleteClient}>
                <Icon>delete_forever</Icon>&nbsp;Удалить
              </MDButton>
            )}
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  client: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateClient: PropTypes.func.isRequired,
};

export default Header;
