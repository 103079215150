// Mui components
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 components
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { PhoneMaskInput } from "components/PhoneMaskInput";

// React
import { useState } from "react";

// inputs
import inputs from "layouts/clients/data/clientFormInputs";

// validate func
import { Validate } from "service/validator";

// propTypes
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";

// models
import Client from "service/Models/Client";

function ClientForm({ client, onSuccess, onClose }) {
  // construct form validation state element
  const formValidationElement = (validationErrorArray = [], isDirty = false) => ({
    isValid: validationErrorArray.length === 0,
    validationErrorMessage: validationErrorArray,
    isDirty,
  });

  // client form initial state
  const [formState, setFormState] = useState(() => {
    const freshFormStateObject = {};
    const inputsNames = Object.keys(inputs);
    if (client.id === undefined) {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = inputs[inputName].defaultValue;
      });
    } else {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = client[inputName];
      });
    }
    return freshFormStateObject;
  });

  // form validation initial state
  const [formValidation, setFormValidation] = useState(() => {
    const freshFormValidationObject = {};
    // initial validate for inputs needed validation
    Object.keys(inputs).forEach((inputName) => {
      if (inputs[inputName].validationNeeded) {
        const errorsArray = Validate(
          client[inputName] || inputs[inputName].defaultValue,
          inputs[inputName].validationRules,
          inputs[inputName].attribute || inputName
        );
        freshFormValidationObject[inputName] = formValidationElement(errorsArray, false);
      } else freshFormValidationObject[inputName] = formValidationElement([], false);
    });
    return freshFormValidationObject;
  });

  // critical server errors
  const [serverError, setServerError] = useState({});

  // change input value and validation if it needed
  const handleInputChange = (event) => {
    const { name } = event.target;
    const value =
      name === "phone" && event.target.value.length !== 0
        ? `+${event.target.value}`
        : event.target.value;
    if (inputs[name].validationNeeded) {
      const errorsArray = Validate(value, inputs[name].validationRules, inputs[name].attribute);
      setFormValidation({ ...formValidation, [name]: formValidationElement(errorsArray, true) });
    } else setFormValidation({ ...formValidation, [name]: formValidationElement([], true) });
    setFormState({ ...formState, [name]: value });
  };

  // check form valid or input valid
  const isValid = (inputName = null) => {
    let valid = true;
    if (inputName === null) {
      Object.keys(formValidation).forEach((input) => {
        if (!formValidation[input].isValid) {
          valid = false;
        }
      });
    } else {
      valid = formValidation[inputName].isValid;
    }
    return valid;
  };

  // create client function
  const createClient = async () => {
    const response = await Client.Create(formState);
    if (response.success === true) {
      onSuccess(response.client);
      onClose();
    } else {
      setServerError(response.errors);
    }
  };

  // update client function
  const updateClient = async () => {
    const response = await Client.Update(client.id, formState);
    if (response.success === true) {
      onSuccess(response.client, true);
      onClose();
    } else {
      setServerError(response.errors);
    }
  };

  // agree handling
  const handleWithAgree = async () => {
    if (client.id !== undefined) updateClient();
    else createClient();
  };

  // close form with disagree
  const handleWithDisagree = () => onClose();

  // action to clean server error state
  const serverErrorClean = (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        setServerError({});
      }}
    >
      <Icon fontSize="small">close</Icon>
    </IconButton>
  );

  return (
    <Dialog
      open
      onClose={handleWithDisagree}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <MDTypography>
          {client.id !== undefined ? "Изменение данных клиента" : "Создание клиента"}
        </MDTypography>
      </DialogTitle>
      <Grid container px={2} spacing={2}>
        <Grid item xs={12}>
          {/* server errors */}
          <Collapse in={Object.keys(serverError).length !== 0}>
            <Alert severity="error" action={serverErrorClean}>
              <AlertTitle>Клиент не {client.id !== undefined ? "обновлен" : "добавлен"}</AlertTitle>
              {Object.keys(serverError).map((error) =>
                serverError[error].map((message) => (
                  <MDTypography
                    key={message}
                    color="error"
                    variant="subtitle2"
                    fontWeight="medium"
                    sx={{ fontSize: "caption.fontSize" }}
                  >
                    {message}
                  </MDTypography>
                ))
              )}
            </Alert>
          </Collapse>
          {/* validation errors */}
          <Collapse in={!isValid()}>
            <Alert severity="error" icon={false}>
              {Object.keys(formValidation).map((validatedValue) => (
                <MDTypography
                  color="error"
                  variant="subtitle2"
                  fontWeight="medium"
                  sx={{ fontSize: "caption.fontSize" }}
                  key={validatedValue}
                >
                  {formValidation[validatedValue].validationErrorMessage[0]}
                </MDTypography>
              ))}
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <MDInput
            name="last_name"
            type="text"
            label="Фамилия*"
            sx={{ width: "100%", marginBottom: 2 }}
            size="small"
            value={formState.last_name}
            onChange={handleInputChange}
            error={!isValid("last_name")}
            success={isValid("last_name")}
          />
          <MDInput
            name="first_name"
            type="text"
            label="Имя*"
            sx={{ width: "100%", marginBottom: 2 }}
            size="small"
            value={formState.first_name}
            onChange={handleInputChange}
            error={!isValid("first_name")}
            success={isValid("first_name")}
          />
          <MDInput
            name="patronymic"
            type="text"
            label="Отчество"
            sx={{ width: "100%", marginBottom: 2 }}
            size="small"
            value={formState.patronymic}
            onChange={handleInputChange}
            error={!isValid("patronymic")}
            success={isValid("patronymic")}
          />
          <PhoneMaskInput
            name="phone"
            type="text"
            label="Телефон"
            sx={{ width: "100%", marginBottom: 2 }}
            size="small"
            mask="+{7}(000)000-00-00"
            unmask
            defaultValue={formState.phone}
            onAccept={(value) => handleInputChange({ target: { value, name: "phone" } })}
            error={!isValid("phone")}
            success={isValid("phone")}
          />
          <MDInput
            name="email"
            type="text"
            label="Email"
            sx={{ width: "100%", marginBottom: 2 }}
            size="small"
            value={formState.email}
            onChange={handleInputChange}
            error={!isValid("email")}
            success={isValid("email")}
          />
          {/* Telegram id */}
          <MDInput
            name="telegram_id"
            type="text"
            label="Telegram ID"
            sx={{ width: "100%", marginBottom: 2 }}
            size="small"
            value={formState.telegram_id}
            onChange={handleInputChange}
            error={!isValid("telegram_id")}
            success={isValid("telegram_id")}
          />
          {/* Комментарий */}
          <MDInput
            name="commentary"
            type="text"
            multiline
            rows={3}
            label="Комментарий"
            sx={{ width: "100%", marginBottom: 2 }}
            size="small"
            value={formState.commentary}
            onChange={handleInputChange}
            error={!isValid("commentary")}
            success={isValid("commentary")}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <MDButton onClick={handleWithDisagree}>Отмена</MDButton>
        <MDButton color="success" onClick={handleWithAgree} disabled={!isValid()}>
          {client.id !== undefined ? "Изменить" : "Добавить"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

// Typechecking props
ClientForm.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ClientForm;
