const allClientsTableFilterList = () => [
  {
    name: "statusFilter",
    label: "По статусу",
    value: {
      label: "Все клиенты",
      id: 0,
    },
    options: [
      {
        label: "Все клиенты",
        id: 0,
      },
      {
        label: "Архивные",
        id: 1,
      },
    ],
    select: true,
  },
];

export default allClientsTableFilterList;
