// mui components
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// Components
import CustomDialog from "examples/CustomDialog";

// proptypes
import PropTypes from "prop-types";

// Models
import RentObject from "service/Models/RentObject";

function ReturnProductsDialog({ open, rent, onAgreeSuccess, onAgreeFailure, onClose }) {
  const returnProductsDialogContent = () => (
    <DialogContent>
      <DialogContentText>Отметить все товары как возвращенные?</DialogContentText>
    </DialogContent>
  );

  const returnProductRequest = (object) =>
    RentObject.Update({ ...object, is_returned: true }).then((response) => {
      if (response.success === true) return response.rent_object;
      throw new Error(response);
    });

  const returnProductsRequest = () => Promise.all(rent.objects.map(returnProductRequest));

  const freshDialogInfo = () => ({
    content: returnProductsDialogContent(),
    title: "Подтвердите действие",
    buttons: [
      {
        name: "Отменить",
        color: "light",
        action: () => {
          onClose();
        },
      },
      {
        name: "Подтвердить",
        color: "success",
        action: () => {
          returnProductsRequest()
            .then(() => {
              onAgreeSuccess();
            })
            .catch((err) => {
              onAgreeFailure(err);
              console.error(err);
            });
        },
      },
    ],
    onClose: () => {
      onClose();
    },
  });

  return <CustomDialog open={open} {...freshDialogInfo()} />;
}

// CancelRentDialog default prop-types
ReturnProductsDialog.defaultProps = {
  onAgreeSuccess: () => {},
  onAgreeFailure: () => {},
};

// CancelRentDialog prop-types
ReturnProductsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
  }).isRequired,
  onAgreeSuccess: PropTypes.func,
  onAgreeFailure: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default ReturnProductsDialog;
