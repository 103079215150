// mui components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// material dashboard 2 components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Components
import CustomDialog from "examples/Dialog";
import Preloader from "layouts/preloader";

// proptypes
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";

// Models
import Rent from "service/Models/Rent";
import Product from "service/Models/Product";

// utilities
import { getCurrentDate } from "service/utilities";

function ProductIssueConfirmationDialog({ open, rent, updateRentCallback }) {
  const [dialogInfo, setDialogInfo] = useState(null);
  const [notReturned, setNotReturned] = useState(null);
  const [formState, setFormState] = useState({
    issue_date: getCurrentDate(),
    is_open: false,
  });
  useEffect(async () => {
    const getRentObjects = async (productId) => {
      const response = await Product.GetRentObjects(productId);
      if (response.success) return response.rent_objects;
      throw new Error(response);
    };
    const getRent = async (object) => {
      const response = await Rent.Read(object.rent_id);
      if (response.success === true) {
        const copyObject = { ...object };
        copyObject.rent = response.rents;
        return copyObject;
      }
      throw new Error(response);
    };
    const getProduct = async (object) => {
      const copyObject = object;
      const response = await Product.GetById(object.product_id);
      if (response.success === true) {
        copyObject.product = response.products;
        return copyObject;
      }
      throw new Error(response);
    };

    Promise.all(rent.objects.map((object) => getRentObjects(object.product_id)))
      .then((response) => {
        const notReturnedObjects = [];
        response.forEach((objectsArray) => {
          objectsArray.forEach((object) => {
            if (object.rent_id !== rent.id && !object.is_returned)
              notReturnedObjects[notReturnedObjects.length] = object;
          });
        });
        return Promise.all(notReturnedObjects.map(getRent));
      })
      .then((response) => {
        const noBookingsObjects = response.filter(
          (object) => !(object.rent.is_booking && !object.rent.issue_date)
        );
        return Promise.all(noBookingsObjects.map(getProduct));
      })
      .then((response) => {
        setNotReturned(response);
      })
      .catch((err) => console.error(err));
  }, []);
  const productGivenRequest = async () => {
    const response = await Rent.Update(rent.id, { issue_date: formState.issue_date });
    if (response.success === true) return response.rent;
    throw new Error(response);
  };

  const handleChangeInput = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const freshDialogInfo = () => ({
    content:
      notReturned !== null ? (
        <DialogContent>
          {notReturned.length !== 0 ? (
            <DialogContentText>
              Невозможно подтвердить получение товара. Следующие товары находятся в аренде:
            </DialogContentText>
          ) : (
            <DialogContentText>Вы подтверждаете, что товары получены клиентом?</DialogContentText>
          )}
          {notReturned.length !== 0 ? (
            notReturned.map((object) => (
              <MDTypography
                key={object.product.name}
                variant="body2"
                fontWeight="medium"
                color="text"
              >
                {object.product.name} - Договор аренды №{object.rent_id}
              </MDTypography>
            ))
          ) : (
            <MDInput
              sx={{ marginTop: 1, width: "100%" }}
              name="issue_date"
              type="date"
              label="Дата получения"
              value={formState.issue_date}
              onChange={handleChangeInput}
            />
          )}
        </DialogContent>
      ) : (
        <Preloader fullScreen={false} />
      ),
    agreeText: "Подтвердить",
    agreeColor: "success",
    agreeDisabled: notReturned !== null ? notReturned.length !== 0 : true,
    disagreeColor: "light",
    agreeAction: () => {
      productGivenRequest()
        .then((response) => {
          updateRentCallback(response);
        })
        .catch((error) => console.log(error));
    },
    disagreeAction: () => {
      setDialogInfo(null);
    },
  });

  useEffect(() => {
    if (formState.is_open) setDialogInfo(freshDialogInfo());
  }, [formState]);

  useEffect(() => {
    if (formState.is_open && notReturned) setDialogInfo(freshDialogInfo());
  }, [notReturned]);

  return (
    <Collapse in={open} unmountOnExit>
      {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
      <MDButton
        variant="gradient"
        size="small"
        color="warning"
        onClick={() => setFormState({ ...formState, is_open: true })}
        sx={{ marginBottom: 1, width: "100%" }}
      >
        <Icon fontSize="small">local_shipping</Icon>&nbsp; Товары получены клиентом
      </MDButton>
    </Collapse>
  );
}

// RenewalObjectForm prop-types
ProductIssueConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rent_date: PropTypes.string.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
  }).isRequired,
  updateRentCallback: PropTypes.func.isRequired,
};

export default ProductIssueConfirmationDialog;
