const inputs = {
  product_group_id: {
    defaultValue: null,
    validationNeeded: true,
    attribute: "'Группа продукта'",
    validationRules: ["required"],
  },
  name: {
    defaultValue: "",
    validationNeeded: true,
    attribute: "'Название'",
    validationRules: ["min:1", "max:100"],
  },
  description: {
    defaultValue: "",
    validationNeeded: false,
  },
  site_description: {
    defaultValue: "",
    validationNeeded: false,
  },
  preparing_time: {
    defaultValue: 0,
    validationNeeded: true,
    validationRules: ["between:0, 20", "fixed:0", "required"],
    attribute: "'Время подготовки'",
  },
  prepare_price: {
    defaultValue: 0,
    validationNeeded: true,
    validationRules: ["between:0, 100000", "fixed:2", "required"],
    attribute: "'Цена подготовки'",
  },
  prepayment_price: {
    defaultValue: 0,
    validationNeeded: true,
    validationRules: ["between:0, 100000", "fixed:2", "required"],
    attribute: "'Предоплата'",
  },
  complectation: {
    defaultValue: "",
    validationNeeded: false,
  },
  age_range_bottom: {
    defaultValue: 0,
    validationNeeded: true,
    attribute: "'От'",
    validationRules: ["between:0, 18", "fixed:0", "required"],
  },
  age_range_top: {
    defaultValue: 0,
    validationNeeded: true,
    attribute: "'До'",
    validationRules: ["between:0, 18", "fixed:0", "required"],
  },
  same_description: {
    defaultValue: true,
    validationNeeded: false,
  },
  active: {
    defaultValue: false,
    validationNeeded: false,
  },
};

export default inputs;
