// import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// TODO сделать этот Header общим для элементов типа Товар и Группа
function ProductGroupHeader({ children, image, name, caption, actions }) {
  return (
    <MDBox position="relative" mb={5} pt={6} pb={3}>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item>
            <MDAvatar
              src={image}
              alt="product-image"
              size="xxl"
              bgColor="light"
              shadow="sm"
              variant="rounded"
            >
              <Icon fontSize="lg" color="disabled">
                no_photography
              </Icon>
            </MDAvatar>
          </Grid>
          <Grid item flexGrow="1">
            <MDBox height="100%" lineHeight={1} display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
              {caption}
              <MDBox display="flex" flexDirection="column" alignItems="flex-end">
                {actions.map((action) => (
                  <MDBox mb={0.6} key={action.tooltip}>
                    <MDButton
                      component={Link}
                      to={action.route}
                      variant="outlined"
                      color={action.color || "info"}
                      size="small"
                      onClick={action.onClick}
                    >
                      <Icon>{action.icon}</Icon>&nbsp;{action.tooltip}
                    </MDButton>
                  </MDBox>
                ))}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
ProductGroupHeader.defaultProps = {
  children: "",
  image: "",
  caption: "",
};

// Typechecking props for the Header
ProductGroupHeader.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  caption: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      icon: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      tooltip: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductGroupHeader;
