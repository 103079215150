const getAgeFilterOptions = () => {
  const res = [];
  for (let i = 0; i < 19; i += 1) {
    res[i] = {
      label: i.toString(10),
      id: i,
    };
  }
  return res;
};

const productsFiltersList = [
  {
    name: "groupFilter",
    label: "По группам",
    value: {
      label: "Все",
      id: 0,
    },
    options: [
      {
        label: "Все",
        id: 0,
      },
    ],
    select: true,
  },
  {
    name: "statusFilter",
    label: "По активности",
    value: {
      label: "Все товары",
      id: 0,
    },
    options: [
      {
        label: "Все товары",
        id: 0,
      },
      {
        label: "Активные",
        id: 1,
      },
      {
        label: "Неактивные",
        id: 2,
      },
      {
        label: "Архивные",
        id: 3,
      },
    ],
    select: true,
  },
  {
    name: "ageFilter",
    label: "По возрасту",
    value: {
      label: "0",
      id: 0,
    },
    options: getAgeFilterOptions(),
    select: true,
  },
  {
    name: "readyFilter",
    label: "По готовности",
    value: {
      label: "Все",
      id: 0,
    },
    options: [
      {
        label: "Все",
        id: 0,
      },
      {
        label: "Готовые",
        id: 1,
      },
      {
        label: "Не готовые",
        id: 2,
      },
    ],
    select: true,
  },
];

export default productsFiltersList;
