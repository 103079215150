// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Preloader from "layouts/preloader";
import ProductForm from "layouts/products/components/ProductForm";

// React
import { useState, useEffect } from "react";
import { useParams, Navigate, useLocation } from "react-router-dom";

// Models
import Product from "service/Models/Product";

function Tables() {
  // get product info
  const { productId } = useParams();
  const [productData, setProductData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [route, setRoute] = useState(useLocation().pathname.split("/").slice(2));
  const [criticalError, setCriticalError] = useState(false);

  useEffect(async () => {
    const response = await Product.GetById(productId);
    if (response.success === true) {
      if (response.products.deleted_at !== null) {
        setCriticalError(true);
        setIsLoaded(true);
        return false;
      }
      setProductData(response.products);
      setIsLoaded(true);

      route[route.indexOf(productId)] = {
        name: response.products.name,
        linkTo: `crm/products/${productId}`,
      };
      setRoute([...route]);

      document.title = "Обновить товар";
      return true;
    }
    setCriticalError(true);
    setIsLoaded(true);
    return false;
  }, []);

  if (criticalError) {
    return <Navigate to="/crm/products" />;
  }

  // if loaded complete
  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title="Обновить" _route={route} />
        <MDBox>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card id="delete-account">
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Обновление товара
                  </MDTypography>
                </MDBox>
                <ProductForm product={productData} isUpdateAction />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Tables;
