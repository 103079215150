// utilities
import { getCurrentDate } from "service/utilities";

const inputs = {
  is_booking: {
    defaultValue: false,
    validationNeeded: false,
  },
  value: {
    defaultValue: "0.0",
    validationNeeded: true,
    initialValidate: true,
    validationRules: ["between:0.01, 999999.99", "fixed:2", "required"],
    attribute: "'Сумма'",
  },
  commentary: {
    defaultValue: "",
    validationNeeded: false,
  },
  products: {
    defaultValue: [],
    validationNeeded: true,
    initialValidate: true,
    attribute: "'Товары'",
    validationRules: ["required"],
  },
  rent_date: {
    defaultValue: getCurrentDate(),
    validationNeeded: false,
  },
  client_id: {
    defaultValue: null,
    validationNeeded: true,
    initialValidate: true,
    attribute: "'Клиент'",
    validationRules: ["required"],
  },
  prepayment: {
    defaultValue: 0,
    validationNeeded: false,
  },
  is_shipping: {
    defaultValue: false,
    validationNeeded: false,
  },
  shipping_cost: {
    defaultValue: 0,
    validationNeeded: true,
    initialValidate: false,
    validationRules: ["between:0.01, 999999.99", "fixed:2", "required"],
    attribute: "'Стоимость доставки'",
  },
};

export default inputs;
