/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import Menu from "@mui/material/Menu";
import MDBox from "components/MDBox";

// Models
import Cash from "service/Models/Cash";

// react
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// components
import NotificationItem from "examples/Items/NotificationItem";
import TransactionStatus from "layouts/cash/components/TransactionStatus";

// utilities
import { dateWord } from "service/utilities";

export default function data(cash, cashUpdateCallback, modalCallback) {
  const navigate = useNavigate();
  const rows = [];
  const [menuOwner, setMenuOwner] = useState(null);

  // handle context menu open
  const handleOpenMenu = (owner) => setMenuOwner(owner);

  // handle close context menu
  const handleCloseMenu = () => setMenuOwner(null);

  // handle delete product
  const handleDeleteProduct = () => {
    modalCallback({
      message: "Вы действительно хотите удалить данную операцию?",
      agreeText: "Удалить",
      agreeColor: "error",
      async action() {
        setMenuOwner(null);
        const response = await Cash.Delete(menuOwner.id);
        if (response) {
          const nCash = [...cash];
          cash.forEach((transaction, index) => {
            if (transaction.id === menuOwner.id) nCash.splice(index, 1);
          });
          cashUpdateCallback(nCash);
        } else {
          console.log(response);
        }
      },
    });
  };

  // render context menu
  const renderMenu = () => (
    <Menu
      anchorEl={menuOwner ? menuOwner.anchor : null}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(menuOwner)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={handleDeleteProduct}
        icon={<Icon>delete</Icon>}
        title="Удалить операцию"
      />
      <NotificationItem
        onClick={() => navigate(`/crm/cash/${menuOwner.id}/update`)}
        icon={<Icon>edit</Icon>}
        title="Редактировать"
      />
    </Menu>
  );

  if (cash.length !== 0) {
    cash
      .filter((transaction) => transaction.view === true)
      .forEach((transaction, i) => {
        rows[i] = {
          id: {
            link: `/crm/cash/${transaction.id}`,
            jsx: (
              <MDTypography
                component={Link}
                to={`/crm/cash/${transaction.id}`}
                variant="button"
                color="text"
                fontWeight="bold"
              >
                #{transaction.id}
              </MDTypography>
            ),
          },
          date: {
            link: `/crm/cash/${transaction.id}`,
            jsx: (
              <MDTypography
                component={Link}
                to={`/crm/cash/${transaction.id}`}
                variant="button"
                color="text"
                fontWeight="regular"
              >
                {dateWord(transaction.date)}
              </MDTypography>
            ),
          },
          value: {
            link: `/crm/cash/${transaction.id}`,
            jsx: (
              <MDTypography
                variant="button"
                color={transaction.status === "+" ? "success" : "error"}
                fontWeight="regular"
              >
                {`${transaction.status === "+" ? "+" : ""} ${transaction.value}`} руб.
              </MDTypography>
            ),
          },
          status: {
            link: `/crm/cash/${transaction.id}`,
            jsx: <TransactionStatus transactionStatus={transaction.status} />,
          },
          action: (
            <MDBox>
              <MDTypography
                onClick={(e) => handleOpenMenu({ ...transaction, anchor: e.target })}
                component="div"
                color="text"
                sx={{ cursor: "pointer" }}
              >
                <Icon>more_vert</Icon>
              </MDTypography>
              {renderMenu()}
            </MDBox>
          ),
        };
      });
  }

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left", Cell: ({ value }) => value.jsx },
      {
        Header: "Дата",
        accessor: "date",
        width: "35%",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      { Header: "Значение", accessor: "value", align: "left", Cell: ({ value }) => value.jsx },
      { Header: "Статус", accessor: "status", align: "center", Cell: ({ value }) => value.jsx },
      { Header: "Действия", accessor: "action", align: "right" },
    ],

    rows,
  };
}
