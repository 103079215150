// react
import { useState } from "react";

/**
 * use checkbox hook
 * @param id {string}
 * @param init {boolean}
 */
export default function useCheckbox(id, init = false) {
  const [value, setValue] = useState(init);
  const [isDirty, setIsDirty] = useState(false);

  const handleChangeInput = async (event) => {
    const val = event.target.checked;

    setValue(val);
    setIsDirty(true);
  };

  return {
    id,
    value,
    handleChangeInput,
    isDirty,
  };
}
