// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// utilities
import { dateWord, reduceAccByColName } from "service/utilities";

// components
import CancelRentDialog from "layouts/rents/components/CancelRentDialog";
import ProductIssueConfirmationDialog from "layouts/rents/components/ProductIssueConfirmationDialog";
import AddPaymentToRentDialog from "layouts/rents/components/AddPaymentToRentDialog";
import RentPaymentStatus from "layouts/rents/components/RentPaymentStatus";
import RentStatus from "layouts/rents/components/RentStatus";
import ChangeShippingCostDialog from "layouts/rents/components/ChangeShippingCostDialog";

// data
import getRentStatus from "layouts/rents/data/getRentStatus";

// React
import { useState } from "react";

function Header({ children, rent, updateRentCallback }) {
  const status = getRentStatus(rent);
  const [cancelRentDialog, setCancelRentDialog] = useState(null);
  const handleCancelRent = () => {
    setCancelRentDialog({
      rent: { ...rent },
      onAgreeSuccess: (changedRent) => {
        setCancelRentDialog(null);
        updateRentCallback(changedRent);
      },
      onClose: () => setCancelRentDialog(null),
    });
  };

  return (
    <MDBox position="relative" mb={5} pt={6} pb={3} maxWidth="lg">
      <Card
        sx={{
          position: "relative",
          mt: -6,
          mx: { xs: 0, sm: "auto" },
          py: 0,
          px: 2,
        }}
      >
        <MDBox py={2} px={2}>
          <Grid container justifyContent="space-between" rowSpacing={1}>
            <Grid item xs={12} sm={8} display="flex" flexDirection="column">
              <MDTypography variant="h6" fontWeight="medium" mb={0.7}>
                Детали договора
              </MDTypography>
              <MDTypography variant="button" color="text" mb={0.3} fontWeight="bold">
                Договор №{rent.id} от {dateWord(rent.created_at.split("T")[0])}
              </MDTypography>
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="bold"
                  sx={{ marginRight: "0.3rem" }}
                >
                  Комментарий:
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {rent.commentary || "-"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="bold"
                  sx={{ marginRight: "0.3rem" }}
                >
                  Статус:
                </MDTypography>
                <RentStatus rent={rent} />
              </MDBox>
              <MDBox display="flex">
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="bold"
                  sx={{ marginRight: "0.3rem" }}
                >
                  Оплата:
                </MDTypography>
                <RentPaymentStatus rent={rent} />
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="bold"
                  sx={{ marginRight: "0.3rem" }}
                >
                  Стоимость доставки:
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {parseFloat(rent.shipping_cost) ? `${rent.shipping_cost} руб.` : "-"}
                </MDTypography>
              </MDBox>
            </Grid>
            {!rent.is_cancel && (
              <Grid item xs={12} sm={4} display="flex" flexDirection="column">
                <AddPaymentToRentDialog
                  updateRentCallback={updateRentCallback}
                  rent={rent}
                  open={
                    reduceAccByColName(rent.objects, "price") +
                      parseFloat(rent.shipping_cost || 0) -
                      reduceAccByColName(rent.cash, "value") >
                    0
                  }
                />
                <ProductIssueConfirmationDialog
                  rent={rent}
                  updateRentCallback={updateRentCallback}
                  open={status === "booked"}
                  mb={3}
                />
                <ChangeShippingCostDialog
                  updateRentCallback={updateRentCallback}
                  rent={rent}
                  open={
                    !rent.issue_date || (status !== "closed" && !parseFloat(rent.shipping_cost))
                  }
                />
                <Collapse in={status === "booked"}>
                  {Boolean(cancelRentDialog) && <CancelRentDialog open {...cancelRentDialog} />}
                  <MDButton
                    sx={{ marginBottom: 1, width: "100%" }}
                    variant="gradient"
                    size="small"
                    color="error"
                    onClick={handleCancelRent}
                  >
                    <Icon fontSize="small">cancel</Icon>&nbsp;Отменить бронирование
                  </MDButton>
                </Collapse>
              </Grid>
            )}
          </Grid>
          {children}
        </MDBox>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  updateRentCallback: PropTypes.func.isRequired,
  rent: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Header;
