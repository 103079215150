/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// utilities
import { dateWord, reduceAccByColName, compareDates } from "service/utilities";

// react
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// material dashboard 2 components
import MDTypography from "components/MDTypography";

// Models
import Rent from "service/Models/Rent";
import Client from "service/Models/Client";

export default function data() {
  const [rents, setRents] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const rows = [];

  useEffect(() => {
    const getRents = async () => {
      const response = await Rent.Read();
      if (response.success === true) return response.rents;
      throw new Error(response);
    };
    const getClient = async (rent) => {
      const copyRent = { ...rent };
      const response = await Client.Read(rent.client_id);
      if (response.success === true) {
        copyRent.client = response.clients;
        return copyRent;
      }
      throw new Error(response);
    };

    getRents()
      .then((respRents) => Promise.all(respRents.map(getClient)))
      .then((rentsWithClients) => {
        const bookings = rentsWithClients.filter((rent) => rent.is_booking);
        const actualRents = bookings.filter((rent) => !rent.issue_date && !rent.is_cancel);
        setRents(actualRents);
        setIsLoaded(true);
      })
      .catch((err) => console.error(err));
  }, []);

  const getReturnDate = (rent) => {
    const objects = [...rent.objects];
    const maxReturnDateObject = objects.reduce((acc, curr) =>
      compareDates(acc.return_date, ">", curr.return_date) ? acc : curr
    );
    return maxReturnDateObject.return_date;
  };

  if (rents.length !== 0) {
    rents.forEach((rent) => {
      rows[rows.length] = {
        id: {
          link: `/crm/rents/${rent.id}`,
          jsx: (
            <MDTypography
              component={Link}
              to={`/crm/rents/${rent.id}`}
              variant="button"
              fontWeight="regular"
              color="text"
            >
              {rent.id}
            </MDTypography>
          ),
        },
        rentDate: {
          link: `/crm/rents/${rent.id}`,
          jsx: dateWord(rent.rent_date),
        },
        returnDate: {
          link: `/crm/rents/${rent.id}`,
          jsx: dateWord(getReturnDate(rent)),
        },
        price: {
          link: `/crm/rents/${rent.id}`,
          jsx: (
            <MDTypography variant="button" color="text" fontWeight="regular">
              {reduceAccByColName(rent.objects, "price") + parseFloat(rent.shipping_cost || 0)} руб.
            </MDTypography>
          ),
        },
        client: {
          link: `/crm/clients/${rent.client_id}`,
          jsx: (
            <MDTypography
              component={Link}
              to={`/crm/clients/${rent.client_id}`}
              variant="button"
              fontWeight="regular"
              color="text"
            >
              {rent.client.full_name}
            </MDTypography>
          ),
        },
      };
    });
  }

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left", Cell: ({ value }) => value.jsx },
      {
        Header: "Дата аренды",
        accessor: "rentDate",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Дата возврата",
        accessor: "returnDate",
        align: "left",
        Cell: ({ value }) => value.jsx,
      },
      { Header: "Стоимость", accessor: "price", align: "left", Cell: ({ value }) => value.jsx },
      { Header: "Клиент", accessor: "client", align: "left", Cell: ({ value }) => value.jsx },
    ],
    rows,
    isLoaded,
  };
}
