const DEFAULT_MAX_CHAR_COUNT = 255;
const DEFAULT_MESSAGE = "Поле должно содержать не больше :maxCharCount символов.";

/**
 * max length validator
 * @param value
 * @param maxCharCount
 * @param message
 * @returns {Promise<null|*>}
 */
const maxLength = async (
  value,
  maxCharCount = DEFAULT_MAX_CHAR_COUNT,
  message = DEFAULT_MESSAGE
) => {
  if (maxCharCount <= 0)
    throw new Error(
      `Валидатор maxLength ожидает положительное максимальное значение длины строки, получил ${maxCharCount}`
    );

  return value.length < maxCharCount ? null : message.replace(":maxCharCount", maxCharCount);
};

export default maxLength;
