/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ImageCard from "examples/Cards/ImageCard";

function Gallery({ images, mainImage }) {
  if (images.length !== 0) {
    return (
      <MDBox p={2}>
        <Grid container spacing={6}>
          {images.map((image) => (
            <Grid key={image.name} item xs={12} md={6} xl={3}>
              <ImageCard
                image={image.path}
                label={image.name === mainImage.name ? "Основное изображение" : ""}
                description={image.description}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
    );
  }

  return (
    <MDBox
      my={3}
      mx={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Icon fontSize="large" color="disabled">
        no_photography
      </Icon>
      <MDTypography variant="subtitle1" color="text">
        У этого товара пока нет изображений
      </MDTypography>
    </MDBox>
  );
}

export default Gallery;
