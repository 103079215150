const inputs = {
  name: {
    defaultValue: "",
    validationNeeded: true,
    validationRules: ["min:1", "max:100"],
    attribute: "'Название'",
  },
  active: {
    defaultValue: true,
    validationNeeded: false,
  },
  description: {
    defaultValue: "",
    validationNeeded: false,
  },
  site_description: {
    defaultValue: "",
    validationNeeded: false,
  },
  same_description: {
    defaultValue: true,
    validationNeeded: false,
  },
};

export default inputs;
