// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import ProductForm from "layouts/products/components/ProductForm";

function CreateProduct() {
  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title="Создание нового товара" />
        <MDBox>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card id="delete-account">
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Создание нового товара
                  </MDTypography>
                </MDBox>
                <ProductForm />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default CreateProduct;
