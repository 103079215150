// DEFINE API DOMAIN
const API_DOMAIN =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_DOMAIN
    : process.env.REACT_APP_DEV_API_DOMAIN;

/**
 * Retrieve token from storage.
 *
 * @return string, null
 */
function GET_LOCAL_ACCESS_TOKEN() {
  return sessionStorage.getItem("API_TOKEN") || localStorage.getItem("API_TOKEN") || null;
}

/**
 * Remove client-side token
 */
const REMOVE_LOCAL_TOKEN = () => {
  sessionStorage.removeItem("API_TOKEN");
  localStorage.removeItem("API_TOKEN");
};

export { API_DOMAIN, GET_LOCAL_ACCESS_TOKEN, REMOVE_LOCAL_TOKEN };
