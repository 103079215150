// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 components
import MDTypography from "components/MDTypography";

// PropTypes
import PropTypes from "prop-types";

function Preloader({ caption, fullScreen }) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: fullScreen ? "100vh" : "10rem" }}
    >
      <Grid item xs={12} textAlign="center">
        <CircularProgress color="secondary" />
        {caption && (
          <MDTypography variant="body1" color="text">
            {caption}
          </MDTypography>
        )}
      </Grid>
    </Grid>
  );
}

Preloader.defaultProps = {
  caption: "",
  fullScreen: true,
};

Preloader.propTypes = {
  caption: PropTypes.string,
  fullScreen: PropTypes.bool,
};

export default Preloader;
