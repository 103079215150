// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";

function ImageCard({ image, label, title, description, action, fixedHeight, fullWidth }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "hidden",
      }}
    >
      <MDBox
        position="relative"
        borderRadius="xl"
        {...(fullWidth
          ? { width: "100.25%" }
          : { display: "flex", justifyContent: "center", maxWidth: "100.25%" })}
      >
        {image !== "" ? (
          <CardMedia
            src={image}
            component="img"
            title={title}
            sx={{
              maxWidth: "100%",
              width: "auto",
              height: fixedHeight,
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
        ) : (
          <Card>
            <MDBox
              height={fixedHeight}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Icon fontSize="large">no_photography</Icon>
            </MDBox>
          </Card>
        )}
      </MDBox>
      {label !== "" || title !== "" || description !== "" || Object.keys(action).length !== 0 ? (
        <MDBox mt={1} mx={0.5} textAlign="left">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {label}
          </MDTypography>
          {action.type !== undefined ? (
            <MDTypography component={Link} to={action.route} variant="h5">
              {title}
            </MDTypography>
          ) : (
            <MDTypography variant="h5">{title}</MDTypography>
          )}
          <MDBox mb={3} lineHeight={0} textAlign="left">
            <MDTypography variant="button" fontWeight="light" color="text">
              {description ? `${description.substr(0, 60)}...` : ""}
            </MDTypography>
          </MDBox>
          {action.type !== undefined && (
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              {action.type === "internal" ? (
                <MDButton
                  component={Link}
                  to={action.route}
                  variant="outlined"
                  size="small"
                  color={action.color}
                >
                  {action.label}
                </MDButton>
              ) : (
                <MDButton
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="outlined"
                  size="small"
                  color={action.color}
                >
                  {action.label}
                </MDButton>
              )}
            </MDBox>
          )}
        </MDBox>
      ) : (
        <div />
      )}
    </Card>
  );
}

// Default props
ImageCard.defaultProps = {
  label: "",
  title: "",
  description: "",
  action: {},
  fixedHeight: "auto",
  fullWidth: true,
};

// Typechecking props for the ImageCard
ImageCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.object]),
  fixedHeight: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default ImageCard;
