// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Filter from "examples/Filter";

// Data
import rentsTableData from "layouts/rents/data/rentsTableData";

// React
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import Preloader from "layouts/preloader";
import CancelRentDialog from "layouts/rents/components/CancelRentDialog";
import DeleteRentDialog from "layouts/rents/components/DeleteRentDialog";
import ReturnProductsDialog from "layouts/rents/components/ReturnProductsDialog";
import CloseRentDialog from "layouts/rents/components/CloseRentDialog";

// Models
import Rent from "service/Models/Rent";

// filters
import filtersList from "layouts/rents/data/rentsFilterList";

// utilities
import { getCurrentDate, compareDates } from "service/utilities";

function AllRents() {
  // filters state
  const [filters, setFilters] = useState(JSON.parse(JSON.stringify(filtersList)));
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const handleResetFilters = () => setFilters(JSON.parse(JSON.stringify(filtersList)));
  const updateFiltersCallback = (value) => setFilters(value);

  // all company rents state
  const [rents, setRents] = useState([]);
  const updateRentsCallback = (value) => {
    setRents(value);
    setFilters([...filters]);
  };

  // filter out
  useEffect(() => {
    rents.forEach((rent, index) => {
      rents[index].view = false;
    });
    rents.forEach((rent, index) => {
      if (filters[0].value.id === 0) rents[index].view = true;
      else if (filters[0].value.id === 1 && rent.is_close) rents[index].view = true;
      else if (
        filters[0].value.id === 2 &&
        !rent.is_close &&
        compareDates(getCurrentDate(), ">=", rent.rent_date)
      )
        rents[index].view = true;
      else if (
        filters[0].value.id === 3 &&
        !rent.is_close &&
        compareDates(getCurrentDate(), "<", rent.rent_date)
      )
        rents[index].view = true;
      else rents[index].view = false;
    });

    rents.forEach((rent, index) => {
      if (rent.view === true) {
        if (filters[1].value.id === 0) rents[index].view = true;
        else if (filters[1].value.id === 1 && rent.is_payed === 2) rents[index].view = true;
        else if (filters[1].value.id === 2 && rent.is_payed === 1) rents[index].view = true;
        else if (filters[1].value.id === 3 && !rent.is_payed) rents[index].view = true;
        else rents[index].view = false;
      }
    });

    setRents([...rents]);
  }, [filters]);

  useEffect(() => {
    const getRents = async () => {
      const response = await Rent.Read();
      if (response.success === true) {
        setRents(response.rents);
        setIsLoaded(true);
        setFilters([...filters]);
        return true;
      }
      // TODO добавить обработку неудачного запроса
      console.log(response);
      return false;
    };

    getRents();
  }, []);

  const [cancelRentDialog, setCancelRentDialog] = useState(null);
  const updateCancelRentDialog = (value) => setCancelRentDialog(value);
  const [deleteRentDialog, setDeleteRentDialog] = useState(null);
  const updateDeleteRentDialog = (value) => setDeleteRentDialog(value);
  const [returnProductsDialog, setReturProductsDialog] = useState(null);
  const updateReturnProductsDialog = (value) => setReturProductsDialog(value);
  const [closeRentDialog, setCloseRentDialog] = useState(null);
  const updateCloseRentDialog = (value) => setCloseRentDialog(value);

  const { columns, rows } = rentsTableData(
    rents,
    updateRentsCallback,
    updateCancelRentDialog,
    updateDeleteRentDialog,
    updateReturnProductsDialog,
    updateCloseRentDialog
  );

  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };

  return (
    <AuthorizeUser>
      {Boolean(cancelRentDialog) && <CancelRentDialog open {...cancelRentDialog} />}
      {Boolean(deleteRentDialog) && <DeleteRentDialog open {...deleteRentDialog} />}
      {Boolean(returnProductsDialog) && <ReturnProductsDialog open {...returnProductsDialog} />}
      {Boolean(closeRentDialog) && <CloseRentDialog open {...closeRentDialog} />}
      <DashboardLayout>
        <DashboardNavbar title="Аренда" />
        <MDBox pt={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Аренды
                  </MDTypography>
                  <Link to="/crm/rents/create">
                    <MDButton variant="outlined" color="white" size="small">
                      <Icon>add</Icon>&nbsp;Добавить договор
                    </MDButton>
                  </Link>
                </MDBox>
                <Grid
                  container
                  px={3}
                  mt={3}
                  columnSpacing={2}
                  rowSpacing={0.5}
                  alignItems="flex-end"
                >
                  <Grid item xs={12} textAlign="right">
                    <MDButton variant="outlined" size="small" sx={{ padding: "0" }}>
                      <Icon color="error">refresh</Icon>&nbsp;
                      <MDTypography
                        fontWeight="medium"
                        variant="caption"
                        color="error"
                        onClick={handleResetFilters}
                        sx={{ cursor: "pointer" }}
                      >
                        Сбросить фильтры
                      </MDTypography>
                    </MDButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Filter
                      filters={filters}
                      onChangeFilters={updateFiltersCallback}
                      widthParams={{ xs: 12, md: 3 }}
                    />
                  </Grid>
                </Grid>
                <MDBox>
                  {!isLoaded ? (
                    <Preloader fullScreen={false} />
                  ) : (
                    <DataTable
                      entriesPerPage={{
                        defaultValue: 20,
                        entries: [5, 10, 15, 20, 25],
                      }}
                      table={{ columns, rows }}
                      isSorted={false}
                      showTotalEntries={false}
                      onCellClick={onCellClick}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default AllRents;
