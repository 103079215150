// mui components
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Autocomplete from "@mui/material/Autocomplete";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// material dashboard 2 components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Components
import CustomDialog from "examples/Dialog";

// proptypes
import PropTypes from "prop-types";

// utilities
import { numDays, shiftDate, getCurrentDate } from "service/utilities";

// React
import { useState, useEffect } from "react";

// Models
import RentObject from "service/Models/RentObject";
import Cash from "service/Models/Cash";

function RenewalObjectForm({ open, object, onSuccess, rent }) {
  const [dialogInfo, setDialogInfo] = useState(null);
  const [transactionChecked, setTransactionChecked] = useState({
    value: true,
    isDirty: false,
  });
  const [renewalDays, setRenewalDays] = useState(() => {
    const renewalPrices = object.product.prices.filter((price) => price.price_type === 1);
    return {
      value: renewalPrices[0],
      options: renewalPrices,
    };
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  function Content() {
    return (
      <DialogContent>
        <DialogContentText>
          Вы уверены, что хотите продлить данный товар на {renewalDays.value.days}&nbsp;
          {numDays(renewalDays.value.days)}?
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={transactionChecked.value}
              onClick={() =>
                setTransactionChecked({
                  value: !transactionChecked.value,
                  isDirty: true,
                })
              }
            />
          }
          label={
            <MDTypography variant="caption" color="text">
              Создать операцию списания средств
            </MDTypography>
          }
        />
      </DialogContent>
    );
  }

  const createTransactionRequest = async () => {
    const response = await Cash.Create({
      date: getCurrentDate(),
      note: `Продление товара ${object.product.name} по договору аренды №${object.id}`,
      value: renewalDays.value.price,
      rent_id: object.rent_id,
    });
    if (response.success === true) return response.cash;
    console.log(response);
    return false;
  };

  const renewalObjectRequest = async () => {
    const response = await RentObject.Create({
      rent_id: object.rent_id,
      product_id: object.product.id,
      return_date: shiftDate(object.return_date, 0, 0, renewalDays.value.days),
      price: renewalDays.value.price,
      is_returned: false,
    });
    if (response.success === true) return response.rent_object;
    console.log(response);
    return false;
  };

  const returnObjectRequest = async () => {
    const response = await RentObject.Update({
      id: object.id,
      is_returned: true,
      is_actual: false,
    });
    if (response.success === true) {
      const nRent = { ...rent };
      renewalObjectRequest()
        .then((newObject) => {
          rent.objects.forEach((pObject, index) => {
            if (pObject.id === object.id) {
              nRent.objects[index].is_actual = false;
              nRent.objects[index].is_returned = true;
            }
          });
          nRent.objects[nRent.objects.length] = {
            ...object,
            return_date: newObject.return_date,
            id: newObject.id,
            price: newObject.price,
            is_actual: true,
            is_returned: false,
            is_original: false,
          };
        })
        .then(() => {
          if (transactionChecked.value)
            createTransactionRequest()
              .then((newTransaction) => {
                nRent.cash[nRent.cash.length] = newTransaction;
              })
              .then(() => onSuccess(nRent));
          else onSuccess(nRent);
        });
    } else {
      console.log(response);
    }
  };

  const freshDialogInfo = () => ({
    content: <Content />,
    agreeText: "Продлить",
    agreeColor: "success",
    disagreeColor: "light",
    agreeAction: () => {
      returnObjectRequest();
    },
    disagreeAction: () => {
      setDialogInfo(null);
      setTransactionChecked({
        value: true,
        isDirty: false,
      });
    },
  });

  useEffect(() => {
    if (transactionChecked.isDirty) setDialogInfo(freshDialogInfo());
  }, [transactionChecked]);

  const handleRenewalObject = () => setDialogInfo(freshDialogInfo());

  return (
    <Collapse in={open === object.id} unmountOnExit>
      {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10}>
          <Autocomplete
            name="renewal_days"
            disableClearable
            options={renewalDays.options}
            size="small"
            autoHighlight={false}
            selectOnFocus={false}
            sx={{ width: "100%", marginTop: "0.4rem" }}
            getOptionLabel={(option) => `${option.days} ${numDays(option.days)}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={renewalDays.value}
            renderInput={(params) => (
              <MDInput
                {...params}
                inputProps={{ ...params.inputProps, readOnly: true }}
                label="Кол-во дней"
              />
            )}
            noOptionsText="Цены не указаны"
            onChange={(event, value) => setRenewalDays({ ...renewalDays, value })}
          />
        </Grid>
        <Grid item xs={2}>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            iconOnly
            onClick={handleRenewalObject}
            disabled={renewalDays.options.length === 0}
          >
            <Icon fontSize="small">add</Icon>
          </MDButton>
        </Grid>
      </Grid>
    </Collapse>
  );
}

// RenewalObjectForm prop-types
RenewalObjectForm.propTypes = {
  open: PropTypes.number.isRequired,
  rent: PropTypes.shape({
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
  }).isRequired,
  object: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rent_id: PropTypes.number.isRequired,
    return_date: PropTypes.string.isRequired,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          days: PropTypes.number,
        })
      ).isRequired,
    }),
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default RenewalObjectForm;
