import {
  API_DOMAIN,
  GET_LOCAL_ACCESS_TOKEN,
  REMOVE_LOCAL_TOKEN,
} from "service/requests/requestConfig";

export default class RentObject {
  /**
   * Read rent objects
   */
  static async Read(rentObjectId = null) {
    const url = `${API_DOMAIN}api/rent_objects${rentObjectId ? `/${rentObjectId}` : ""}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Update rent object info
   *
   */
  static async Update(rentObjectData) {
    const url = `${API_DOMAIN}api/rent_objects/${rentObjectData.id}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(rentObjectData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Create new rent object
   *
   */
  static async Create(rentObjectData) {
    const url = `${API_DOMAIN}api/rent_objects`;
    const response = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(rentObjectData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Delete rent object
   *
   */
  static async Delete(id) {
    const url = `${API_DOMAIN}api/rent_objects/${id}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    return response.ok;
  }
}
