// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProductInfoCard from "components/InfoCard";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import DataTable from "examples/Tables/DataTable";
import Gallery from "examples/Gallery";

// Models
import Product from "service/Models/Product";
import Cash from "service/Models/Cash";

// Data
import prepareProductData from "layouts/products/data/preparedProductData";
import pricesData from "layouts/products/data/pricesData";
import bookingTableData from "layouts/products/data/bookingTableData";
import rentHistoryTableData from "layouts/products/data/rentHistoryTableData";

// Components
import RentHistoryTable from "examples/Tables/RentHistoryTable";
import BookingTable from "examples/Tables/BookingTable";
import Header from "layouts/products/components/Header";
import Preloader from "layouts/preloader";
import ModalPriceForm from "layouts/products/components/ModalPriceForm";
import CustomizableDialog from "layouts/products/components/CustomizableDialog";

// react
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// utilities
import { compareDates, getCurrentDate } from "service/utilities";

function Overview() {
  /**
   * product states and functions
   */
  // get product information
  const { productId } = useParams();
  const [product, setProduct] = useState({});
  const updateProductCallback = (value) => {
    setProduct(value);
  };

  const navigate = useNavigate();
  const { productInfo } = prepareProductData(product);
  const [isLoaded, setIsLoaded] = useState(false);

  // get product info
  useEffect(async () => {
    const response = await Product.GetById(productId);
    if (response.success === true) {
      if (response.products.deleted_at === null) {
        setProduct(response.products);
        setIsLoaded(true);
        document.title = response.products.name;
        return true;
      }
      navigate("/crm/products");
      return false;
    }
    // TODO добавить обработку ошибки
    console.log(response);
    navigate("/crm/products");
    return false;
  }, []);

  const [transactionCheck, setTransactionCheck] = useState(false);
  const updateTransaction = (value) => setTransactionCheck(value);
  const setProductReadyMessage = (transactionCallback) => (
    <MDBox>
      <MDTypography component="span" color="text" variant="body2" fontWeight="regular">
        Вы уверены, что хотите отметить товар как {product.ready ? "не готовый" : "готовый"}?
      </MDTypography>
      {!product.ready && (
        <MDBox display="flex" alignItems="center">
          <Checkbox onClick={(event) => transactionCallback(event.target.checked)} />
          <MDTypography component="span" color="text" variant="caption">
            {/* !product.ready
            ? "Произвести операцию списания стоимости подготовки"
            : "Удалить операцию списания стоимости подготовки" */}
            Произвести операцию списания стоимости подготовки
          </MDTypography>
        </MDBox>
      )}
    </MDBox>
  );

  /**
   * customizable dialog states and functions
   */
  // customizable dialog state
  const [customizableDialogInfo, setCustomizableDialogInfo] = useState(null);
  // customizable dialog state callback
  const updateCustomizableDialogInfo = (value) => setCustomizableDialogInfo(value);
  // customizable dialog close action
  const handleCustomizableDialogClose = () => {
    setCustomizableDialogInfo(null);
    setTransactionCheck(false);
  };

  /**
   * price form states and functions
   */
  // price form states
  const [priceInfo, setPriceInfo] = useState(null);

  // price form state callback for components
  const updatePriceInfo = (value) => setPriceInfo(value);

  // handler add price buttons
  const handleAddPrice = () => setPriceInfo({ price: {}, type: 0 });
  const handleAddRenewalPrice = () => setPriceInfo({ price: {}, type: 1 });

  // handle close price form window
  const handlePriceFormClose = () => setPriceInfo(null);

  const { columns, priceRows, renewalPriceRows } = pricesData(
    product,
    updateProductCallback,
    updateCustomizableDialogInfo,
    updatePriceInfo
  );

  const bookingTableVars = bookingTableData(productId);
  const rentHistoryTableVars = rentHistoryTableData(productId);

  const baseProductOperations = [
    {
      route: `/crm/products/${productId}/update`,
      tooltip: "Редактировать",
      icon: "edit",
    },
    {
      route: "#",
      tooltip: "Удалить",
      icon: "delete",
      color: "error",
      onClick: (event) => {
        event.preventDefault();
        setCustomizableDialogInfo({
          message: "Вы действительно хотите удалить данный товар?",
          agreeText: "удалить",
          agreeColor: "error",
          async action() {
            const response = await Product.Trash(productId);
            if (response.json.success === true) {
              navigate("/crm/products");
            } else {
              // TODO сделать обработку ошибки через уведомление и/или модальное окно
              console.log(response);
            }
          },
        });
      },
    },
  ];

  const createTransactionFromDialog = () => {
    const createTransaction = async () => {
      const response = await Cash.Create({
        note: `Подготовка товара '${product.name}'`,
        value: -parseFloat(product.prepare_price),
        date: getCurrentDate(),
        products: [{ ...product, currentPrice: -parseFloat(product.prepare_price) }],
      });
      if (response.success !== true) {
        console.log(response);
      }
    };
    if (transactionCheck) createTransaction();
  };

  const customizableDialogInfoAction = () => {
    const setProductReady = async () => {
      const response = await Product.Update(
        productId,
        {
          ...product,
          ready: !product.ready,
        },
        product.images
      );
      if (response.success === true) {
        setProduct(response.product);
        return true;
      }
      console.log(response);
      return false;
    };
    setProductReady();

    if (!product.ready) createTransactionFromDialog();
    // else deleteTransactionFromDialog();
  };

  const isProductInRent = () => {
    if (product.rent_objects.length) {
      const nearestInterval = product.rent_objects[0];
      const isIn =
        compareDates(getCurrentDate(), ">=", nearestInterval.rent.rent_date) &&
        compareDates(getCurrentDate(), "<=", nearestInterval.return_date);
      return isIn;
    }
    return false;
  };

  const isProductOverdue = () => {
    if (product.rent_objects.length) {
      const nearestInterval = product.rent_objects[0];
      return compareDates(getCurrentDate(), ">", nearestInterval.return_date);
    }
    return false;
  };

  const headerActions = product.active
    ? [
        ...[
          !isProductInRent() && !isProductOverdue()
            ? {
                route: "#",
                tooltip: !product.ready ? "Сделать готовым" : "Сделать не готовым",
                icon: product.ready ? "close" : "done",
                color: product.ready ? "warning" : "success",
                onClick: (event) => {
                  event.preventDefault();
                  const message =
                    parseFloat(product.prepare_price) !== 0 ? (
                      setProductReadyMessage(updateTransaction)
                    ) : (
                      <MDTypography color="text" variant="body2" fontWeight="regular">
                        Вы уверены, что хотите отметить товар как &nbsp;
                        {product.ready ? "не готовый" : "готовый"}?
                      </MDTypography>
                    );
                  setCustomizableDialogInfo({
                    message,
                    agreeText: "отметить",
                    agreeColor: "info",
                  });
                },
              }
            : {
                route: "#",
                tooltip: !product.ready ? "Сделать готовым" : "Сделать не готовым",
                icon: product.ready ? "close" : "done",
                color: product.ready ? "warning" : "success",
                onClick: (event) => {
                  event.preventDefault();
                  const message = (
                    <MDTypography color="text" variant="body2" fontWeight="regular">
                      Невозможно изменить статус товара пока он находится в аренде.
                    </MDTypography>
                  );
                  setCustomizableDialogInfo({
                    message,
                    agreeText: "Принять",
                    agreeColor: "info",
                    action: () => setCustomizableDialogInfo(null),
                  });
                },
              },
        ],
        ...baseProductOperations,
      ]
    : baseProductOperations;

  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      {Boolean(customizableDialogInfo) && (
        <CustomizableDialog
          message={customizableDialogInfo.message}
          agreeColor={customizableDialogInfo.agreeColor}
          agreeText={customizableDialogInfo.agreeText}
          agreeAction={customizableDialogInfo.action || customizableDialogInfoAction}
          disagreeAction={handleCustomizableDialogClose}
        />
      )}
      <DashboardLayout>
        <DashboardNavbar title={product.name} />
        <MDBox mb={3} />
        <Header productData={product} actions={headerActions}>
          {/* Таблица актуальных бронирований */}
          <MDBox px={2} mt={4.5}>
            <BookingTable {...bookingTableVars} />
          </MDBox>
          {/* Таблица истории аренд */}
          <MDBox px={2} mt={4.5}>
            <RentHistoryTable {...rentHistoryTableVars} />
          </MDBox>
          <MDBox mt={5} mb={1}>
            <Grid container spacing={1}>
              {priceRows.length === 0 && product.deleted_at === null && (
                <Grid item xs={12}>
                  <MDAlert
                    color="warning"
                    sx={{ fontSize: "1rem", padding: "0.8rem", minHeight: "3rem" }}
                  >
                    <Icon fontSize="small">warning_amber</Icon>&nbsp; Для завершения создания товара
                    задайте ему цену аренды
                  </MDAlert>
                </Grid>
              )}
              <Grid item xs={12} xl={6} sx={{ display: "flex" }}>
                <ProductInfoCard title="информация" info={productInfo} shadow={false} />
                <Divider
                  orientation="vertical"
                  sx={{ mx: 0, display: { xs: "none", xl: "block" } }}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                {Boolean(priceInfo) && (
                  <ModalPriceForm
                    productData={product}
                    updateProductDataCallback={updateProductCallback}
                    priceInfo={priceInfo}
                    closeAction={handlePriceFormClose}
                  />
                )}
                <MDBox mb={2}>
                  <Card>
                    <MDBox
                      mx={1}
                      py={1.2}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" color="white">
                        Цены аренды
                      </MDTypography>
                      <MDButton
                        variant="outlined"
                        color="white"
                        size="small"
                        onClick={handleAddPrice}
                      >
                        <Icon>add</Icon>&nbsp;Добавить цену
                      </MDButton>
                    </MDBox>
                    <MDBox pt={1}>
                      <DataTable
                        table={{ columns, rows: priceRows }}
                        isSorted
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </MDBox>
                <MDBox>
                  <Card>
                    <MDBox
                      mx={1}
                      py={1.2}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" color="white">
                        Цены продления
                      </MDTypography>
                      <MDButton
                        variant="outlined"
                        color="white"
                        size="small"
                        onClick={handleAddRenewalPrice}
                      >
                        <Icon>add</Icon>&nbsp;Добавить цену
                      </MDButton>
                    </MDBox>
                    <MDBox pt={1}>
                      <DataTable
                        table={{ columns, rows: renewalPriceRows }}
                        isSorted
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium">
              Изображения
            </MDTypography>
            <Gallery images={product.images} mainImage={product.main_image} />
          </MDBox>
        </Header>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Overview;
