/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingTable from "examples/Tables/BookingTable";
import RentObjectsTable from "examples/Tables/RentObjectsTable";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import bookingTableData from "layouts/dashboard/data/bookingTableData";
import rentObjectsTableData from "layouts/dashboard/data/rentObjectsTableData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

// Models
import Cash from "service/Models/Cash";
import Client from "service/Models/Client";
import Product from "service/Models/Product";

// React
import { useState, useEffect } from "react";

// Future functionality
// import Error from "layouts/error";

import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;

  const [cash, setCash] = useState([]);
  const [products, setProducts] = useState([]);
  const [clients, setClients] = useState([]);
  const { columns, rows, isLoaded } = bookingTableData();

  useEffect(() => {
    const getCash = async () => {
      const response = await Cash.Read();
      if (response.success === true) return response.cash;
      throw new Error(response);
    };
    const getProducts = async () => {
      const response = await Product.GetAll();
      if (response.success === true) return response.products;
      throw new Error(response);
    };

    const getClients = async () => {
      const response = await Client.Read();
      if (response.success === true) return response.clients;
      throw new Error(response);
    };

    getCash()
      .then((respCash) => setCash(respCash))
      .catch((err) => console.error(err));
    getProducts()
      .then((respProducts) => setProducts(respProducts.filter((product) => product.active)))
      .catch((err) => console.error(err));
    getClients()
      .then((respClient) => setClients(respClient))
      .catch((err) => console.error(err));
  }, []);

  const getCurrentDate = (jsDate) => {
    const year = jsDate.getFullYear();
    const month = jsDate.getMonth() + 1;
    const day = jsDate.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day}`;
  };

  const shiftDate = (date, yearShift = 0, monthShift = 0, dayShift = 0) => {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + yearShift);
    dateCopy.setMonth(dateCopy.getMonth() + monthShift);
    dateCopy.setDate(dateCopy.getDate() + dayShift);
    return getCurrentDate(dateCopy);
  };

  const calculateCash = (interval = null, asString = false) => {
    let result = 0;
    if (interval === null) {
      cash.forEach((transaction) => {
        result += parseFloat(transaction.value);
      });
    } else {
      cash
        .filter(
          (transaction) => new Date(transaction.date).getTime() >= new Date(interval).getTime()
        )
        .forEach((transaction) => {
          result += parseFloat(transaction.value);
        });
    }
    if (asString) result = `${result > 0 ? "+" : ""} ${result} руб.`;
    return result;
  };

  const handleCalculateMonthCash = () => {
    const days = new Date().getDate();
    const toStartMonthDate = shiftDate(new Date(), 0, 0, -days + 1);
    const value = calculateCash(toStartMonthDate, true);
    return value;
  };

  const getMonthPercentage = () => {
    const days = new Date().getDate();
    const toStartMonthDate = shiftDate(new Date(), 0, 0, -days + 1);
    const toStartLastMonthDate = shiftDate(new Date(), 0, -1, -days + 1);

    const currentMonthValue = calculateCash(toStartMonthDate, false);
    const lastMonthValue = calculateCash(toStartLastMonthDate, false) - currentMonthValue;
    const profit = currentMonthValue - lastMonthValue;
    const result = {
      amount: "",
      color: "",
      label: "",
    };
    if (lastMonthValue === 0) {
      result.amount = profit;

      if (profit >= 0) result.amount = `+${profit} руб.`;
      else result.amount = `${profit} руб.`;

      if (profit < 0) result.color = "error";
      else if (profit > 0) result.color = "success";
      else result.color = "dark";
    } else {
      const amount = Math.floor(currentMonthValue / (lastMonthValue / 100) - 100);
      if (amount >= 0) result.amount = `+${amount}%`;
      else result.amount = `${amount}%`;

      if (profit < 0) result.color = "error";
      else if (profit > 0) result.color = "success";
      else result.color = "dark";
    }
    return result;
  };

  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title="Главная" />
        <MDBox py={3}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="monetization_on"
                  title="Касса"
                  count={`${calculateCash(null, true)}`}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Только что",
                  }}
                  action="/crm/cash"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Прибыль за месяц"
                  count={handleCalculateMonthCash()}
                  percentage={getMonthPercentage()}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="toys"
                  title="Активные товары"
                  count={products.length}
                  percentage={{
                    label: "Только что",
                  }}
                  action="/crm/products"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="contact_phone"
                  title="Клиенты"
                  count={clients.length}
                  percentage={{
                    label: "Только что",
                  }}
                  action="/crm/clients"
                />
              </MDBox>
            </Grid>
          </Grid>
          {/* Таблица актуальных бронирований */}
          <MDBox mt={4.5}>
            <BookingTable columns={columns} rows={rows} isLoaded={isLoaded} />
          </MDBox>
          <MDBox mt={4.5} mb={3}>
            <RentObjectsTable {...rentObjectsTableData()} />
          </MDBox>
          {/* <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="website views"
                    description="Last Campaign Performance"
                    date="campaign sent 2 days ago"
                    chart={reportsBarChartData}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="daily sales"
                    description={
                      <>
                        (<strong>+15%</strong>) increase in today sales.
                      </>
                    }
                    date="updated 4 min ago"
                    chart={sales}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="completed tasks"
                    description="Last Campaign Performance"
                    date="just updated"
                    chart={tasks}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Projects />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OrdersOverview />
              </Grid>
            </Grid>
          </MDBox> */}
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Dashboard;
