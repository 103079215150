// Material dashboard 2 components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// prop-types
import PropTypes from "prop-types";

// utilities
import { compareDates, getCurrentDate } from "service/utilities";
import Icon from "@mui/material/Icon";

function ObjectStatus({ object, rent }) {
  const isOverdue = compareDates(getCurrentDate(), ">", object.return_date);
  const isBooking = compareDates(getCurrentDate(), "<", rent.rent_date);
  if (object.is_returned)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="success">
          circle
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Возвращен
        </MDTypography>
      </MDBox>
    );
  if (isBooking && !rent.issue_date)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" sx={{ color: "#7b809a" }}>
          panorama_fish_eye
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Забронирован
        </MDTypography>
      </MDBox>
    );
  if (!rent.issue_date)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="warning">
          square
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Ожидает получения
        </MDTypography>
      </MDBox>
    );
  if (isOverdue)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="error" sx={{ transform: "rotate(45deg)" }}>
          square
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Просрочен
        </MDTypography>
      </MDBox>
    );
  return (
    <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
      <Icon fontSize="inherit" color="info">
        circle
      </Icon>
      &nbsp;
      <MDTypography variant="button" fontWeight="regular" color="text">
        В аренде
      </MDTypography>
    </MDBox>
  );
}

ObjectStatus.propTypes = {
  object: PropTypes.shape({
    is_returned: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([0, 1])]).isRequired,
    return_date: PropTypes.string.isRequired,
  }).isRequired,
  rent: PropTypes.shape({
    rent_date: PropTypes.string.isRequired,
    is_booking: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([1, 0])]).isRequired,
    issue_date: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]).isRequired,
  }).isRequired,
};

export default ObjectStatus;
