export default function data(pProduct) {
  const product = pProduct || {};

  return {
    productInfo: [
      {
        key: "siteDescription",
        label: "Описание для сайта",
        value: product.site_description || "Нет описания",
      },
      {
        key: "description",
        label: "Внутренне описание",
        value: product.description || "Нет описания",
      },
      {
        key: "complectation",
        label: "Комплектация",
        value: product.complectation || "Нет комплектации",
      },
      {
        key: "preparingTime",
        label: "Время подготовки",
        value: `${product.preparing_time || 0} дн.`,
      },
      {
        key: "prepare_price",
        label: "Цена подготовки",
        value: `${product.prepare_price} руб.`,
      },
      {
        key: "prepayment_price",
        label: "Предоплата",
        value: `${product.prepayment_price} руб.`,
      },
      {
        key: "ageRange",
        label: "Возрастной диапазон",
        value: `от ${product.age_range_bottom || 0} до ${product.age_range_top || 0} года/лет`,
      },
    ],
  };
}
