// mui components
import Icon from "@mui/material/Icon";

// material dashboard 2 components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// proptypes
import PropTypes from "prop-types";

// data
import getRentStatus from "layouts/rents/data/getRentStatus";

function RentStatus({ rent }) {
  const status = getRentStatus(rent);
  if (status === "canceled")
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="error">
          not_interested
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Отменен
        </MDTypography>
      </MDBox>
    );

  if (status === "booked")
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" sx={{ color: "#7b809a" }}>
          panorama_fish_eye
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Забронирован
        </MDTypography>
      </MDBox>
    );

  if (status === "overdue")
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="error" sx={{ transform: "rotate(45deg)" }}>
          square
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Просрочен
        </MDTypography>
      </MDBox>
    );

  if (status === "closed")
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="success">
          circle
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Закрыт
        </MDTypography>
      </MDBox>
    );

  return (
    <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
      <Icon fontSize="inherit" color="info">
        circle
      </Icon>
      &nbsp;
      <MDTypography variant="button" fontWeight="regular" color="text">
        Открыт
      </MDTypography>
    </MDBox>
  );
}

// RenewalObjectForm prop-types
RentStatus.propTypes = {
  rent: PropTypes.shape({
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
    is_cancel: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    is_booking: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    issue_date: PropTypes.string.isRequired,
    shipping_cost: PropTypes.string.isRequired,
  }).isRequired,
};

export default RentStatus;
