/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import EmailNotification from "layouts/email-notification/notice";
import EmailVerify from "layouts/email-notification/verify";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Products from "layouts/products/all";
import CreateProduct from "layouts/products/create-new";
import UpdateProduct from "layouts/products/update";
import ProductGroups from "layouts/product_groups/all";
import CreateProductGroup from "layouts/product_groups/create-new";
import UpdateProductGroup from "layouts/product_groups/update";
import Group from "layouts/product_groups/product-group";
import Logout from "layouts/authentication/logout";
import Product from "layouts/products/product";
import Greetings from "layouts/greetings";
import Contacts from "layouts/contacts";
import CustomerProductPage from "layouts/products/customer.product";
import NotFoundPublic from "layouts/not-found/not-found-public";
import NotFoundCrm from "layouts/not-found/not-found-crm";
import Cash from "layouts/cash/all";
import CreateTransaction from "layouts/cash/create";
import UpdateTransaction from "layouts/cash/update";
import Transaction from "layouts/cash/transaction";
import Clients from "layouts/clients/all";
import Rents from "layouts/rents/all";
import CreateRent from "layouts/rents/create";
import Rent from "layouts/rents/rent";
import Client from "layouts/clients/client";
import Companies from "layouts/companies";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Компании",
    key: "crm/companies",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/crm/companies",
    component: <Companies />,
    su: true,
  },
  {
    type: "title",
    title: "Тарифы",
    key: "rates",
    su: true,
  },
  {
    type: "title",
    title: "Компания",
    key: "company-title",
  },
  {
    type: "collapse",
    name: "Главная",
    key: "crm",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/crm",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Профиль",
    key: "crm/profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/crm/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Клиенты",
    key: "clients",
    icon: <Icon fontSize="small">contact_phone</Icon>,
    route: "/crm/clients",
    component: <Clients />,
  },
  {
    type: "title",
    title: "Товары",
    key: "products-title",
  },
  {
    type: "collapse",
    name: "Все товары",
    key: "products",
    icon: <Icon fontSize="small">toys</Icon>,
    route: "/crm/products",
    component: <Products />,
  },
  {
    type: "collapse",
    name: "Группы товаров",
    key: "groups",
    icon: <Icon fontSize="small">group_work</Icon>,
    route: "/crm/groups",
    component: <ProductGroups />,
  },
  {
    type: "title",
    name: "Новый товар",
    key: "products/create",
    route: "/crm/products/create",
    component: <CreateProduct />,
  },
  {
    type: "title",
    name: "Обновить товар",
    key: "products/update",
    route: "/crm/products/:productId/update",
    component: <UpdateProduct />,
  },
  {
    type: "title",
    name: "Товар",
    key: "crm.product",
    route: "/crm/products/:productId",
    component: <Product />,
  },
  {
    type: "title",
    name: "Новая группа",
    key: "groups/create",
    route: "/crm/groups/create",
    component: <CreateProductGroup />,
  },
  {
    type: "title",
    name: "Обновить группу",
    key: "groups/update",
    route: "/crm/groups/:groupId/update",
    component: <UpdateProductGroup />,
  },
  {
    type: "title",
    name: "Группа товаров",
    key: "group",
    route: "/crm/groups/:groupId",
    component: <Group />,
  },
  {
    type: "title",
    title: "Счета",
    key: "billings",
  },
  {
    type: "collapse",
    name: "Аренда",
    key: "rents",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/crm/rents",
    component: <Rents />,
  },
  {
    type: "collapse",
    name: "Касса",
    key: "cash",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/crm/cash",
    component: <Cash />,
  },
  {
    type: "title",
    name: "Клиент",
    key: "client",
    route: "/crm/clients/:clientId",
    component: <Client />,
  },
  {
    type: "title",
    name: "Операция",
    key: "transaction",
    route: "/crm/cash/:id",
    component: <Transaction />,
  },
  {
    type: "title",
    name: "Новый договор",
    key: "rents/create",
    route: "/crm/rents/create",
    component: <CreateRent />,
  },
  {
    type: "title",
    name: "Договор",
    key: "rent",
    route: "/crm/rents/:rentId",
    component: <Rent />,
  },
  {
    type: "title",
    name: "Новая операция",
    key: "cash/create",
    route: "/crm/cash/create",
    component: <CreateTransaction />,
  },
  {
    type: "title",
    name: "Обновить операцию",
    key: "cash/update",
    route: "/crm/cash/:id/update",
    component: <UpdateTransaction />,
  },
  {
    type: "divider",
    key: "divider",
  },
  {
    // type: "collapse",
    type: "title",
    name: "Таблицы",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    // type: "collapse",
    type: "title",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    // type: "collapse",
    type: "title",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    // type: "collapse",
    type: "title",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    // type: "collapse",
    type: "title",
    name: "Подтверждение электронной почты",
    key: "email-notification",
    // icon: <Icon fontSize="small">notifications</Icon>,
    route: "/email-notification",
    component: <EmailNotification />,
  },
  {
    // type: "collapse",
    type: "title",
    name: "Подтверждение электронной почты",
    key: "email-verify",
    // icon: <Icon fontSize="small">notifications</Icon>,
    route: "/email-verify/:verifyingHash",
    component: <EmailVerify />,
  },
  {
    // type: "collapse",
    type: "title",
    name: "Вход",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    // type: "collapse",
    type: "title",
    name: "Регистрация",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Выйти",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/crm/logout",
    component: <Logout />,
  },
  {
    type: "collapse",
    name: "Версия для клиента",
    icon: <Icon fontSize="small">desktop_mac</Icon>,
    key: "main",
    route: "/",
    component: <Greetings />,
  },
  {
    type: "title",
    name: "Контакты",
    key: "contacts",
    route: "/contacts",
    component: <Contacts />,
  },
  {
    type: "title",
    name: "Товар",
    key: "public.product",
    route: "/products/:id",
    component: <CustomerProductPage />,
  },
  {
    type: "title",
    name: "Страница не найдена",
    key: "not-found",
    route: "/not-found",
    component: <NotFoundPublic />,
  },
  {
    type: "title",
    name: "Страница не найдена",
    key: "crm/not-found",
    route: "/crm/not-found",
    component: <NotFoundCrm />,
  },
];

export const getRouteByKey = (key) => {
  let route = "";
  routes.forEach((item) => {
    if (item.key === key) {
      route = item;
    }
  });
  return route;
};

export default routes;
