const DEFAULT_MIN_CHAR_COUNT = 1;
const DEFAULT_MESSAGE = "Поле должно содержать не менее :minCharCount символов.";
/**
 * min length validator
 * @param value
 * @param minCharCount {number}
 * @param message {string}
 * @returns {Promise<null|string>}
 */
const minLength = async (
  value,
  minCharCount = DEFAULT_MIN_CHAR_COUNT,
  message = DEFAULT_MESSAGE
) => {
  if (minCharCount <= 0)
    throw new Error(
      `Валидатор minLength ожидает положительное минимальное значение длины строки, получил ${minCharCount}`
    );

  return value.length >= minCharCount ? null : message.replace(":minCharCount", minCharCount);
};

export default minLength;
