const DEFAULT_MESSAGE = "Поле должно состоять из символов разного регистра.";

const isUpperCase = (value) => value === value.toUpperCase();
const isLowerCase = (value) => value === value.toLowerCase();
/**
 * required validator
 * @param value
 * @param message
 * @returns {Promise<null|string>}
 */
const mixedCase = async (value, message = DEFAULT_MESSAGE) =>
  !isUpperCase(value) && !isLowerCase(value) ? null : message;

export default mixedCase;
