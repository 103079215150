// Material dashboard 2 examples components
import DataTable from "examples/Tables/DataTable";

// prop-types
import PropTypes from "prop-types";

// Models
import Client from "service/Models/Client";

// react
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import Preloader from "layouts/preloader";

// data
import rentHistoryTableData from "layouts/clients/data/rentHistoryTableData";

function RentHistoryTable({ clientId }) {
  const [rents, setRents] = useState([]);
  const { columns, rows } = rentHistoryTableData(rents);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(async () => {
    const response = await Client.ReadRents(clientId);
    if (response.success === true) {
      setRents(response.rents);
      setIsLoaded(true);
      return true;
    }
    console.log(response);
    return false;
  }, []);

  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };

  if (!isLoaded) return <Preloader fullScreen={false} />;

  return (
    <DataTable
      entriesPerPage={{ entries: [5, 10, 15, 20, 50] }}
      showTotalEntries={false}
      isSorted={false}
      table={{ columns, rows }}
      onCellClick={onCellClick}
      noEndBorder
    />
  );
}

RentHistoryTable.propTypes = {
  clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RentHistoryTable;
