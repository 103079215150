// react
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// Requests
import { RETRIEVE_TOKEN, API_LOGOUT, REMOVE_LOCAL_TOKEN } from "service/request";

// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";

function Logout() {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleCloseWithAgree = () => {
    API_LOGOUT(RETRIEVE_TOKEN());
    REMOVE_LOCAL_TOKEN();
    setOpen(false);
    navigate("/authentication/sign-in");
  };
  const handleCloseWithDisagree = () => {
    navigate(-1);
    setOpen(false);
  };

  return (
    <AuthorizeUser>
      <Dialog
        open={open}
        onClose={handleCloseWithDisagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <MDTypography fontWeight="medium" color="info">
            Выйти из системы?
          </MDTypography>
        </DialogTitle>
        {/* TODO: добавить выбор выхода со всех платформ */}
        {/* <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending anonymous
            </DialogContentText>
          </DialogContent> */}
        <DialogActions>
          <MDButton onClick={handleCloseWithDisagree}>Отмена</MDButton>
          <MDButton onClick={handleCloseWithAgree}>Принять</MDButton>
        </DialogActions>
      </Dialog>
    </AuthorizeUser>
  );
}
export default Logout;
