import {
  API_DOMAIN,
  GET_LOCAL_ACCESS_TOKEN,
  REMOVE_LOCAL_TOKEN,
} from "service/requests/requestConfig";

export default class Cash {
  /**
   * Create new transaction
   */
  static async Create(transactionData) {
    const url = `${API_DOMAIN}api/cash`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(transactionData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Get transaction by id/Get all transactions
   */
  static async Read(transactionId = null) {
    const url = `${API_DOMAIN}api/cash${transactionId ? `/${transactionId}` : ""}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Update transaction
   */
  static async Update(transactionData, transactionId) {
    const url = `${API_DOMAIN}api/cash/${transactionId}`;
    const response = await fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(transactionData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Delete transaction
   */
  static async Delete(cashId) {
    const url = `${API_DOMAIN}api/cash/${cashId}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    if (!response.ok) return false;
    return true;
  }
}
