/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// import MDProgress from "components/MDProgress";

// Mui components
import Menu from "@mui/material/Menu";

// React
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Models
import ProductGroup from "service/Models/ProductGroup";

// Material Dashboard 2 React example components
import NotificationItem from "examples/Items/NotificationItem";

export default function data(groups, updateGroupsCallback, modalCallback) {
  const navigate = useNavigate();
  const rows = [];
  // const [productGroups, setProductGroups] = useState([]);
  // useEffect(async () => {
  //   // get product groups of company
  //   const response = await ProductGroup.GetAllProductGroups();
  //   setProductGroups(response.groups);
  // }, []);

  const [menuOwner, setMenuOwner] = useState(null);
  const handleOpenMenu = (owner) => setMenuOwner(owner);
  const handleCloseMenu = () => setMenuOwner(null);
  const handleTrashGroup = () => {
    modalCallback({
      message: "Вы действительно хотите поместить данную группу товаров в архив?",
      agreeText: "Архивировать",
      agreeColor: "error",
      async action() {
        setMenuOwner(null);
        const response = await ProductGroup.Trash(menuOwner.id);
        if (response.success === true) {
          const index = ProductGroup.IndexOfGroupById([...groups], menuOwner.id);
          const nGroups = [...groups];
          nGroups[index] = response.group;
          updateGroupsCallback([...nGroups]);
        } else {
          console.log(response);
        }
      },
    });
  };
  const handleChangeStatus = () => {
    modalCallback({
      message: "Вы действительно хотите изменить статус группы?",
      agreeText: "изменить",
      agreeColor: "info",
      async action() {
        setMenuOwner(null);
        const response = await ProductGroup.InvertStatus(menuOwner.id);
        const index = ProductGroup.IndexOfGroupById([...groups], response.group.id);
        const nGroups = [...groups];
        nGroups[index] = response.group;
        updateGroupsCallback([...nGroups]);
      },
    });
  };

  const handleRestore = () => {
    modalCallback({
      message: "Вы действительно хотите восстановить данную группу?",
      agreeText: "восстановить",
      agreeColor: "success",
      async action() {
        setMenuOwner(null);
        const response = await ProductGroup.Restore(menuOwner.id);
        if (response.success === true) {
          const index = ProductGroup.IndexOfGroupById([...groups], menuOwner.id);
          const nGroups = [...groups];
          nGroups[index] = response.group;
          updateGroupsCallback([...nGroups]);
        } else {
          console.log(response);
        }
      },
    });
  };

  const handleDelete = () => {
    modalCallback({
      message: "Вы действительно хотите безвозвратно удалить данную группу товаров?",
      agreeText: "Удалить",
      agreeColor: "error",
      async action() {
        setMenuOwner(null);
        const response = await ProductGroup.Delete(menuOwner.id);
        if (response) {
          const index = ProductGroup.IndexOfGroupById([...groups], menuOwner.id);
          groups.splice(index, 1);
          updateGroupsCallback([...groups]);
        } else {
          console.log(response);
        }
      },
    });
  };

  // group name template
  const Group = ({ image, name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar variant="rounded" src={image} name={name} size="sm" bgColor="light">
        <Icon fontSize="lg" color="disabled">
          no_photography
        </Icon>
      </MDAvatar>
      <MDTypography
        component={Link}
        to={`/crm/groups/${id}`}
        variant="button"
        color="inherit"
        fontWeight="medium"
        ml={1}
      >
        {name}
      </MDTypography>
    </MDBox>
  );

  // progress template
  // const Progress = ({ color, value }) => (
  //   <MDBox display="flex" alignItems="center">
  //     <MDTypography variant="caption" color="text" fontWeight="medium">
  //       {value}%
  //     </MDTypography>
  //     <MDBox ml={0.5} width="9rem">
  //       <MDProgress variant="gradient" color={color} value={value} />
  //     </MDBox>
  //   </MDBox>
  // );

  // Render the context menu
  const renderMenu = () =>
    menuOwner.deleted_at === null ? (
      <Menu
        anchorEl={menuOwner ? menuOwner.anchor : null}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuOwner)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <NotificationItem
          onClick={handleTrashGroup}
          icon={<Icon>delete</Icon>}
          title="Архивировать"
        />
        <NotificationItem
          onClick={handleChangeStatus}
          icon={<Icon>change_circle</Icon>}
          title="Изменить статус"
        />
        <NotificationItem
          onClick={() => navigate(`/crm/groups/${menuOwner.id}/update`)}
          icon={<Icon>edit</Icon>}
          title="Редактировать"
        />
      </Menu>
    ) : (
      <Menu
        anchorEl={menuOwner ? menuOwner.anchor : null}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuOwner)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <NotificationItem
          onClick={handleRestore}
          icon={<Icon>restore</Icon>}
          title="Восстановить"
        />
        <NotificationItem
          onClick={handleDelete}
          icon={<Icon>delete_forever</Icon>}
          title="Удалить"
        />
      </Menu>
    );

  if (groups.length !== 0) {
    groups
      .filter((group) => group.view === true)
      .forEach((group, i) => {
        rows[i] = {
          group: (
            <Group
              image={group.main_image ? group.main_image.path : ""}
              name={group.name}
              id={group.id}
            />
          ),
          status: (
            <MDBox ml={-1}>
              {group.deleted_at !== null ? (
                <MDBadge badgeContent="В архиве" color="error" variant="gradient" size="sm" />
              ) : (
                <MDBadge
                  badgeContent={group.active ? "активна" : "не активна"}
                  color={group.active ? "success" : "dark"}
                  variant="gradient"
                  size="sm"
                />
              )}
            </MDBox>
          ),
          action: (
            <MDBox>
              <MDTypography
                onClick={(e) => handleOpenMenu({ ...group, anchor: e.target })}
                component="div"
                color="text"
                sx={{ cursor: "pointer" }}
              >
                <Icon>more_vert</Icon>
              </MDTypography>
              {menuOwner !== null && renderMenu()}
            </MDBox>
          ),
        };
      });
  }

  return {
    columns: [
      { Header: "группа", accessor: "group", width: "50%", align: "left" },
      // { Header: "budget", accessor: "budget", align: "left" },
      { Header: "статус", accessor: "status", align: "left" },
      // { Header: "completion", accessor: "completion", align: "center" },
      { Header: "действия", accessor: "action", align: "right" },
    ],

    rows,
    // rows: [
    //   {
    //     group: <Group image={LogoAsana} name="Asana" />,
    //     budget: (
    //       <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
    //         $2,500
    //       </MDTypography>
    //     ),
    //     status: (
    //       <MDTypography variant="caption" color="text" fontWeight="medium">
    //         working
    //       </MDTypography>
    //     ),
    //     completion: <Progress color="info" value={60} />,
    //     action: (
    //       <MDTypography component="a" href="#" color="text">
    //         <Icon>more_vert</Icon>
    //       </MDTypography>
    //     ),
    //   },
    // ],
  };
}
