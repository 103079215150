import {
  API_DOMAIN,
  GET_LOCAL_ACCESS_TOKEN,
  REMOVE_LOCAL_TOKEN,
} from "service/requests/requestConfig";

export default class ProductGroup {
  /**
   * Get total
   */
  static async GetTotal() {
    const url = `${API_DOMAIN}api/product_groups/total`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Create new product
   */
  static async Create(groupData, images) {
    const formData = groupData;
    if (images !== undefined && images.length) formData.images = images;
    else formData.images = null;
    const url = `${API_DOMAIN}api/product_groups`;
    const response = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(formData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Update product group
   */
  static async Update(groupData, images, id) {
    const formData = groupData;
    if (images !== undefined && images.length) formData.images = images;
    else formData.images = null;
    const url = `${API_DOMAIN}api/product_groups/${id}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(formData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Get all product groups
   */
  static async GetAll() {
    const url = `${API_DOMAIN}api/product_groups`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Get product group by id
   */
  static async GetById(pId) {
    const url = `${API_DOMAIN}api/product_groups/${pId}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Invert status of product group
   */
  static async InvertStatus(id) {
    const url = `${API_DOMAIN}api/product_groups/${id}/invert_status`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const json = await response.json();
    return json;
  }

  /**
   * Trash product group
   */
  static async Trash(id) {
    const url = `${API_DOMAIN}api/product_groups/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    const json = await response.json();
    return json;
  }

  /**
   * Restore product
   */
  static async Restore(id) {
    const url = `${API_DOMAIN}api/product_groups/${id}/restore`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    const json = await response.json();
    return json;
  }

  /**
   * Delete product group
   */
  static async Delete(id) {
    const url = `${API_DOMAIN}api/product_groups/${id}/delete`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    return true;
  }

  /**
   * Find group index in groups array by id
   * @param
   */
  static IndexOfGroupById(groupsArray, groupId) {
    let searchResult = -1;
    groupsArray.forEach((group, index) => {
      if (group.id === groupId) {
        searchResult = index;
      }
    });
    return searchResult;
  }
}
