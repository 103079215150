// @mui material compon
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ImageUploader from "components/ImageUploader";

// propTypes
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

// Models
import ProductGroup from "service/Models/ProductGroup";

// validate func
import { Validate } from "service/validator";

// data
import inputs from "layouts/product_groups/data/productGroupFormInputs";

function ProductGroupForm({ productGroup, isUpdateAction }) {
  const freshFormState = () => {
    const freshFormStateObject = {};
    const inputsNames = Object.keys(inputs);
    if (productGroup.id === undefined) {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = inputs[inputName].defaultValue;
      });
    } else {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = productGroup[inputName];
      });
    }
    return freshFormStateObject;
  };

  const freshFormValidation = () => {
    const freshFormValidationObject = {};
    Object.keys(inputs).forEach((inputName) => {
      if (inputs[inputName].validationNeeded) {
        const errorsArray = Validate(
          productGroup[inputName] || inputs[inputName].defaultValue,
          inputs[inputName].validationRules,
          inputs[inputName].attribute || inputName
        );
        freshFormValidationObject[inputName] = {
          isValid: errorsArray.length === 0,
          validationErrorMessage: errorsArray,
          isDirty: false,
        };
      }
    });
    return freshFormValidationObject;
  };

  const [formState, setFormState] = useState(freshFormState());
  const [images, setImages] = useState(productGroup.images);
  const imagesCallback = (value) => {
    setImages(value);
  };
  const [formValidation, setFormValidation] = useState(freshFormValidation());
  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState({});

  const isValid = (inputName) => formValidation[inputName].isValid;
  const formValidate = () => {
    let formValid = true;
    Object.keys(formValidation).forEach((input) => {
      if (!formValidation[input].isValid) {
        formValid = false;
      }
    });
    return formValid;
  };

  const alertAction = (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        setFormError({});
      }}
    >
      <Icon>close</Icon>
    </IconButton>
  );

  const handleInputChange = (event) => {
    const [target, name] = [event.target, event.target.name];
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (inputs[target.name].validationNeeded) {
      const errorsArray = Validate(
        value,
        inputs[target.name].validationRules,
        inputs[target.name].attribute
      );
      const newFormValidation = {
        isValid: errorsArray.length === 0,
        validationErrorMessage: errorsArray,
        isDirty: true,
      };
      setFormValidation({ ...formValidation, [name]: newFormValidation });
    }
    if (formState.same_description && name === "description") {
      formState.site_description = value;
    }
    setFormState({ ...formState, [name]: value });
  };

  // post new product group form data to server
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formValidate()) {
      return false;
    }
    let response;
    if (!isUpdateAction) {
      response = await ProductGroup.Create(formState, images);
    } else {
      response = await ProductGroup.Update(formState, images, productGroup.id);
    }
    if (response.success === true) {
      setSuccess(true);
      return true;
    }
    setFormError(response.data);
    return false;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [formError]);

  if (success) {
    return <Navigate to={isUpdateAction ? `/crm/groups/${productGroup.id}` : "/crm/groups"} />;
  }

  return (
    <div>
      <Collapse in={Object.keys(formError).length !== 0}>
        <Alert severity="error" action={alertAction} fontSize="caption.fontSize">
          <AlertTitle>Группа не {isUpdateAction ? "обновлена" : "добавлена"}</AlertTitle>
          {Object.keys(formError).map((error) =>
            formError[error].map((message) => (
              <MDTypography
                key={message}
                color="error"
                variant="subtitle2"
                fontWeight="medium"
                sx={{ fontSize: "caption.fontSize" }}
              >
                {message}
              </MDTypography>
            ))
          )}
        </Alert>
      </Collapse>
      <Collapse in={!formValidate()}>
        {Object.keys(formValidation).map((validatedValue) => (
          <Alert severity="error" icon={false} key={validatedValue}>
            <MDTypography
              color="error"
              variant="subtitle2"
              fontWeight="medium"
              sx={{ fontSize: "caption.fontSize" }}
            >
              {formValidation[validatedValue].validationErrorMessage[0]}
            </MDTypography>
          </Alert>
        ))}
      </Collapse>
      <MDBox p={2}>
        <Grid container spacing={4}>
          <Grid
            container
            item
            xs={12}
            md={4}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <MDInput
                label="Название"
                name="name"
                sx={{ width: "100%" }}
                size="small"
                value={formState.name}
                onChange={handleInputChange}
                title="Название не должно превышать 25 символов"
                {...(isValid("name") ? { success: true } : { error: true })}
              />
            </Grid>
            <Grid item>
              <Checkbox
                name="active"
                checked={Boolean(formState.active)}
                onClick={handleInputChange}
              />
              <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                Сделать группу активной
              </MDTypography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <MDInput
                label="Описание группы"
                name="description"
                multiline
                sx={{ width: "100%" }}
                size="small"
                rows={3}
                value={formState.description}
                onChange={handleInputChange}
                success
              />
            </Grid>
            <Grid item>
              <Checkbox
                name="same_description"
                checked={Boolean(formState.same_description)}
                onClick={handleInputChange}
              />
              <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                Использовать то же описание для сайта
              </MDTypography>
            </Grid>
            {!formState.same_description && (
              <Grid item>
                <MDInput
                  name="site_description"
                  label="Описание группы для сайта"
                  multiline
                  sx={{ width: "100%" }}
                  size="small"
                  rows={3}
                  value={formState.site_description}
                  onChange={handleInputChange}
                  success
                />
              </Grid>
            )}
          </Grid>
          {isUpdateAction ? (
            <ImageUploader
              compressImagesCallback={imagesCallback}
              imagesLimit={1}
              images={productGroup.images}
              _mainImage={productGroup.main_image}
            />
          ) : (
            <ImageUploader compressImagesCallback={imagesCallback} imagesLimit={1} />
          )}
          <Grid item xs={12} container justifyContent="flex-end">
            {formValidate() ? (
              <MDButton variant="gradient" color="success" size="small" onClick={handleSubmit}>
                <Icon>add</Icon>&nbsp;{isUpdateAction ? "Обновить" : "Добавить"} группу
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={handleSubmit}
                disabled
              >
                <Icon>add</Icon>&nbsp;{isUpdateAction ? "Обновить" : "Добавить"} группу
              </MDButton>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

ProductGroupForm.defaultProps = {
  productGroup: {},
  isUpdateAction: false,
};

ProductGroupForm.propTypes = {
  isUpdateAction: PropTypes.bool,
  productGroup: PropTypes.oneOfType([PropTypes.object]),
};

export default ProductGroupForm;
