import { API_DOMAIN, GET_LOCAL_ACCESS_TOKEN } from "service/requests/requestConfig";

export default class User {
  /**
   * Update rent object info
   */
  static async Update(formData, userId) {
    const url = `${API_DOMAIN}api/${userId}/settings`;
    return fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(formData),
    });
  }
}
