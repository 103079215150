// Mui components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// PropTypes
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";

function CustomDialog({ title, content, buttons, open, onClose }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={Card}
      >
        <DialogTitle id="alert-dialog-title">
          <MDTypography>{title}</MDTypography>
        </DialogTitle>
        {content}
        <DialogActions>
          {buttons.map((button) => (
            <MDButton
              onClick={button.action}
              color={button.color || "info"}
              disabled={button.disabled || false}
              key={button.name}
            >
              {button.icon && <Icon fontSize="small">{button.icon}</Icon>}
              <MDTypography
                variant="inherit"
                textTransform="uppercase"
                fontWeight="medium"
                color={button.color === "light" && darkMode ? "white" : "inherit"}
              >
                {button.name}
              </MDTypography>
            </MDButton>
          ))}
        </DialogActions>
      </Dialog>
    </Card>
  );
}

// Default props
CustomDialog.defaultProps = {
  open: true,
};

// Dialog typechecking props
CustomDialog.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
      disabled: PropTypes.bool,
      action: PropTypes.func.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
      ]),
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default CustomDialog;
