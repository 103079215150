import {
  API_DOMAIN,
  GET_LOCAL_ACCESS_TOKEN,
  REMOVE_LOCAL_TOKEN,
} from "service/requests/requestConfig";
import { compareDates, getCurrentDate, reduceAccByColName } from "service/utilities";

export default class Rent {
  /**
   * Status getter
   */
  static GetStatus(rent) {
    if (rent.is_cancel) {
      return {
        color: "dark",
        badgeContent: "Отменен",
      };
    }

    if (rent.is_booking && !rent.issue_date)
      return {
        color: "light",
        badgeContent: "Забронирован",
      };

    const overdueProducts = rent.objects.filter(
      (object) => !object.is_returned && compareDates(getCurrentDate(), ">", object.return_date)
    );
    if (overdueProducts.length !== 0) {
      return {
        color: "error",
        badgeContent: "Просрочен",
      };
    }

    const isAllProductsReturned = rent.objects.filter((object) => !object.is_returned).length === 0;
    const transactionsSum = reduceAccByColName(rent.cash, "value");
    const rentTotalSum = reduceAccByColName(rent.objects, "price");
    if (isAllProductsReturned && rentTotalSum - transactionsSum === 0)
      return {
        color: "success",
        badgeContent: "Закрыт",
      };

    return {
      color: "info",
      badgeContent: "Открыт",
    };
  }

  /**
   * Payment status getter
   */
  static GetPaymentStatus(rent) {
    const transactions = !rent.is_cancel
      ? rent.cash
      : rent.cash.filter((transaction) => transaction.value > 0);
    if (rent.is_cancel && rent.cash.filter((transaction) => transaction.value < 0).length !== 0)
      return {
        color: "dark",
        badgeContent: "Средства возвращены",
      };
    const transactionsSum = reduceAccByColName(transactions, "value");
    if (transactionsSum === 0)
      return {
        color: "error",
        badgeContent: "Неоплачен",
      };

    const rentTotalSum = reduceAccByColName(rent.objects, "price");
    if (rentTotalSum - transactionsSum > 0)
      return {
        color: "warning",
        badgeContent: "Частично оплачен",
      };
    return {
      color: "success",
      badgeContent: "Оплачен",
    };
  }

  /**
   * Get rents of company
   */
  static async Read(rentId = null) {
    const url = `${API_DOMAIN}api/rents${rentId ? `/${rentId}` : ""}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Create new rent
   *
   */
  static async Create(rentData) {
    const url = `${API_DOMAIN}api/rents`;
    const response = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
      },
      body: JSON.stringify(rentData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Update rent
   *
   */
  static async Update(id, rentData) {
    const url = `${API_DOMAIN}api/rents/${id}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
      body: JSON.stringify(rentData),
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return {};
    }
    const responseJson = await response.json();
    return responseJson;
  }

  /**
   * Delete rent
   */
  static async Delete(id) {
    const url = `${API_DOMAIN}api/rents/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GET_LOCAL_ACCESS_TOKEN()}`,
        Accept: "application/json",
      },
    });
    if (response.status === 401) {
      REMOVE_LOCAL_TOKEN();
      return false;
    }
    return response.ok;
  }
}
