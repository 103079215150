// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Filter from "examples/Filter";
import ModalClientForm from "layouts/clients/components/ModalClientForm";

// Data
import clientsTableData from "layouts/clients/data/clientsTableData";
import getFiltersList from "layouts/clients/data/allClientsTableFilterList";

// React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import CustomDialog from "examples/Dialog";
import Preloader from "layouts/preloader";

// Models
import Client from "service/Models/Client";
import MDInput from "../../../components/MDInput";

function AllClients() {
  const navigate = useNavigate();
  const [dialogInfo, setDialogInfo] = useState(null);
  const updateDialogInfo = (value) => setDialogInfo(value);

  const [modalClientOpen, setModalClientOpen] = useState(null);
  const updateModalClientOpen = (value) => setModalClientOpen(value);

  const [filters, setFilters] = useState(getFiltersList());
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchField, setSearchField] = useState("");
  const updateFiltersCallback = (value) => setFilters(value);
  const handleResetFilters = () => {
    setFilters(getFiltersList());
    setSearchField("");
  };

  const [clients, setClients] = useState([]);
  const updateClientsCallback = (value) => {
    setClients(value);
    setFilters([...filters]);
  };

  const handleInputChange = (event) => {
    setSearchField(event.target.value);
    setFilters([...filters]);
  };

  // filter out
  useEffect(() => {
    // reset the visibility of all products
    clients.forEach((client, index) => {
      clients[index].view = false;
    });

    if (searchField !== "") {
      clients.forEach((client, index) => {
        if (client.full_name.toLowerCase().indexOf(searchField.toLowerCase()) !== -1)
          clients[index].view = true;
      });
    } else {
      clients.forEach((client, index) => {
        clients[index].view = true;
      });
    }

    clients.forEach((client, index) => {
      if (client.view === true) {
        if (filters[0].value.id === 0 && client.deleted_at === null) clients[index].view = true;
        else if (filters[0].value.id === 1 && client.deleted_at !== null)
          clients[index].view = true;
        else clients[index].view = false;
      }
    });

    setClients([...clients]);
  }, [filters]);

  useEffect(() => {
    const getClients = async () => {
      const response = await Client.Read();
      if (response.success === true) {
        setClients(response.clients);
        setFilters([...filters]);
        setIsLoaded(true);
        return true;
      }
      // TODO добавить обработку неудачного запроса
      console.log(response);
      return false;
    };

    getClients();
  }, []);

  const successStoreClient = (newClientData, isUpdated = false) => {
    const copyClients = [...clients];
    if (!isUpdated) {
      copyClients[copyClients.length] = newClientData;
    } else {
      clients.forEach((client, index) => {
        if (client.id === newClientData.id) copyClients[index] = newClientData;
      });
    }
    setClients([...copyClients]);
    setFilters([...filters]);
  };

  const { columns, rows } = clientsTableData(
    clients,
    updateClientsCallback,
    updateDialogInfo,
    updateModalClientOpen
  );

  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };

  return (
    <AuthorizeUser>
      {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
      {Boolean(modalClientOpen) && (
        <ModalClientForm
          client={modalClientOpen}
          onSuccess={successStoreClient}
          onClose={() => setModalClientOpen(null)}
        />
      )}
      <DashboardLayout>
        <DashboardNavbar title="Клиенты" />
        <MDBox pt={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Клиенты
                  </MDTypography>
                  <MDButton
                    variant="outlined"
                    color="white"
                    size="small"
                    onClick={() => setModalClientOpen({})}
                  >
                    <Icon>add</Icon>&nbsp;Добавить клиента
                  </MDButton>
                </MDBox>
                <Grid
                  container
                  px={3}
                  mt={3}
                  columnSpacing={2}
                  rowSpacing={0.5}
                  alignItems="flex-end"
                >
                  <Grid item xs={12} textAlign="right">
                    <MDButton variant="outlined" size="small" sx={{ padding: "0" }}>
                      <Icon color="error">refresh</Icon>&nbsp;
                      <MDTypography
                        fontWeight="medium"
                        variant="caption"
                        color="error"
                        onClick={handleResetFilters}
                        sx={{ cursor: "pointer" }}
                      >
                        Сбросить фильтры
                      </MDTypography>
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={8} md={4}>
                    <MDInput
                      name="searchField"
                      label="Поиск по имени"
                      type="text"
                      size="small"
                      sx={{ width: "100%" }}
                      value={searchField}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2}>
                    <Filter
                      filters={filters}
                      onChangeFilters={updateFiltersCallback}
                      widthParams={{ xs: 12 }}
                    />
                  </Grid>
                </Grid>
                <MDBox>
                  {!isLoaded ? (
                    <Preloader fullScreen={false} />
                  ) : (
                    <DataTable
                      entriesPerPage={{
                        defaultValue: 20,
                        entries: [5, 10, 15, 20, 25],
                      }}
                      table={{ columns, rows }}
                      showTotalEntries={false}
                      onCellClick={onCellClick}
                      noEndBorder
                      isSorted
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default AllClients;
