/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// react
import { useState } from "react";
import { Link } from "react-router-dom";

// components
import NotificationItem from "examples/Items/NotificationItem";
import RentStatus from "layouts/rents/components/RentStatus";
import RentPaymentStatus from "layouts/rents/components/RentPaymentStatus";

// utilities
import { compareDates, dateWord, reduceAccByColName } from "service/utilities";

// data
import getRentStatus from "layouts/rents/data/getRentStatus";

export default function data(
  rents,
  rentsUpdateCallback,
  cancelRentDialogCallback,
  deleteRentDialogCallback,
  returnProductsDialogCallback,
  closeRentCallback
) {
  const rows = [];
  const [menuOwner, setMenuOwner] = useState(null);
  // handle context menu open
  const handleOpenMenu = (owner) => setMenuOwner(owner);

  // handle close context menu
  const handleCloseMenu = () => setMenuOwner(null);

  // booking cancellation handler
  const handleCancelRent = () => {
    setMenuOwner(null);
    cancelRentDialogCallback({
      rent: menuOwner,
      onAgreeSuccess: (changedRent) => {
        cancelRentDialogCallback(null);
        const newRents = [...rents];
        rents.forEach((rent, i) => {
          if (rent.id === changedRent.id) newRents[i] = changedRent;
        });
        rentsUpdateCallback(newRents);
      },
      onClose: () => cancelRentDialogCallback(null),
    });
  };

  // rent delete handler
  const handleDeleteRent = () => {
    setMenuOwner(null);
    deleteRentDialogCallback({
      rent: menuOwner,
      onAgreeSuccess: () => {
        deleteRentDialogCallback(null);
        const newRents = [...rents];
        rents.forEach((rent, i) => {
          if (rent.id === menuOwner.id) newRents.splice(i, 1);
        });
        rentsUpdateCallback(newRents);
      },
      onCancel: () => {
        deleteRentDialogCallback(null);
        handleCancelRent();
      },
      onClose: () => deleteRentDialogCallback(null),
    });
  };

  // return all products handler
  const handleProductsReturn = () => {
    setMenuOwner(null);
    returnProductsDialogCallback({
      rent: menuOwner,
      onAgreeSuccess: () => {
        returnProductsDialogCallback(null);
        const newRent = { ...menuOwner };
        menuOwner.objects.forEach((object, i) => {
          newRent.objects[i].is_returned = true;
        });
        const rentIndex = rents.findIndex((rent) => rent.id === newRent.id);
        const newRents = [...rents];
        newRents[rentIndex] = newRent;
        rentsUpdateCallback([...newRents]);
      },
      onClose: () => returnProductsDialogCallback(null),
    });
  };

  // close rent handler
  const handleCloseRent = () => {
    setMenuOwner(null);
    closeRentCallback({
      rent: menuOwner,
      onAgreeSuccess: (transaction) => {
        closeRentCallback(null);
        const newRent = { ...menuOwner };
        menuOwner.objects.forEach((object, i) => {
          newRent.objects[i].is_returned = true;
        });
        if (transaction !== null) newRent.cash[newRent.cash.length] = transaction;

        const rentIndex = rents.findIndex((rent) => rent.id === newRent.id);
        const newRents = [...rents];
        newRents[rentIndex] = newRent;
        rentsUpdateCallback([...newRents]);
      },
      onClose: () => closeRentCallback(null),
    });
  };

  // render context menu
  const renderMenu = () => (
    <Menu
      anchorEl={menuOwner ? menuOwner.anchor : null}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(menuOwner)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {["open", "overdue"].indexOf(getRentStatus(menuOwner)) !== -1 && (
        <NotificationItem
          onClick={handleCloseRent}
          icon={<Icon>done</Icon>}
          title="Закрыть договор"
        />
      )}
      {["open", "overdue"].indexOf(getRentStatus(menuOwner)) !== -1 && (
        <NotificationItem
          onClick={handleProductsReturn}
          icon={<Icon>replay</Icon>}
          title="Товары возвращены"
        />
      )}
      {getRentStatus(menuOwner) === "booked" && (
        <NotificationItem
          onClick={handleCancelRent}
          icon={<Icon>close</Icon>}
          title="Отменить бронирование"
        />
      )}
      <NotificationItem
        onClick={handleDeleteRent}
        icon={<Icon>delete</Icon>}
        title="Удалить договор"
      />
    </Menu>
  );

  const getNearestReturnDate = (rent) =>
    rent.objects.sort((object1, object2) =>
      compareDates(object1.return_date, ">", object2.return_date)
    )[0].return_date;

  if (rents.length !== 0) {
    rents
      .filter((rent) => rent.view === true)
      .forEach((rent, i) => {
        rows[i] = {
          id: {
            jsx: (
              <MDTypography
                component={Link}
                to={`/crm/rents/${rent.id}`}
                variant="button"
                color="text"
                fontWeight="medium"
              >
                #{rent.id}
              </MDTypography>
            ),
            link: `/crm/rents/${rent.id}`,
          },
          rent_date: {
            link: `/crm/rents/${rent.id}`,
            jsx: (
              <MDTypography variant="button" color="text" fontWeight="regular">
                {dateWord(rent.rent_date)}
              </MDTypography>
            ),
          },
          return_date: {
            link: `/crm/rents/${rent.id}`,
            jsx: (
              <MDTypography variant="button" color="text" fontWeight="regular">
                {dateWord(getNearestReturnDate(rent))}
              </MDTypography>
            ),
          },
          status: {
            link: `/crm/rents/${rent.id}`,
            jsx: <RentStatus rent={rent} />,
          },
          paymentStatus: {
            link: `/crm/rents/${rent.id}`,
            jsx: <RentPaymentStatus rent={rent} />,
          },
          client: {
            link: `/crm/clients/${rent.client_id}`,
            jsx: (
              <MDTypography
                component={Link}
                to={`/crm/clients/${rent.client_id}`}
                variant="button"
                color="text"
                fontWeight="regular"
              >
                {rent.client.abbreviate_name}
              </MDTypography>
            ),
          },
          value: {
            link: `/crm/rents/${rent.id}`,
            jsx: (
              <MDTypography variant="button" color="text" fontWeight="regular">
                {reduceAccByColName(rent.objects, "price") + parseFloat(rent.shipping_cost || 0)}
                &nbsp;руб.
              </MDTypography>
            ),
          },
          action: (
            <MDBox>
              <MDTypography
                onClick={(e) => handleOpenMenu({ ...rent, anchor: e.target })}
                component="div"
                color="text"
                sx={{ cursor: "pointer" }}
              >
                <Icon>more_vert</Icon>
              </MDTypography>
              {menuOwner !== null && renderMenu()}
            </MDBox>
          ),
        };
      });
  }

  return {
    columns: [
      {
        Header: "ID",
        accessor: "id",
        align: "left",
        width: "1%",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Дата аренды",
        accessor: "rent_date",
        align: "left",
        width: "1%",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Дата возврата",
        accessor: "return_date",
        align: "left",
        width: "1%",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Статус",
        accessor: "status",
        align: "left",
        width: "1%",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Оплата",
        accessor: "paymentStatus",
        align: "left",
        width: "1%",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Клиент",
        accessor: "client",
        align: "left",
        width: "1%",
        Cell: ({ value }) => value.jsx,
      },
      {
        Header: "Сумма",
        accessor: "value",
        align: "left",
        width: "1%",
        Cell: ({ value }) => value.jsx,
      },
      { Header: "Действия", accessor: "action", width: "1%", align: "right" },
    ],

    rows,
  };
}
