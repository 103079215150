// Mui components
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";

// Material Dashboard 2 components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// PropTypes
import PropTypes from "prop-types";

function CustomizableDialog({ message, agreeColor, agreeText, agreeAction, disagreeAction }) {
  const handleCloseWithDisagree = () => disagreeAction();
  const handleCloseWithAgree = () => {
    agreeAction();
    disagreeAction();
  };

  return (
    <Dialog
      open
      onClose={handleCloseWithDisagree}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <MDTypography>Подтвердите действие</MDTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleCloseWithDisagree}>Отмена</MDButton>
        <MDButton onClick={handleCloseWithAgree} color={agreeColor}>
          {agreeText}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

// Default props
CustomizableDialog.defaultProps = {
  agreeColor: "info",
};

// Typechecking props
CustomizableDialog.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  agreeColor: PropTypes.string,
  agreeText: PropTypes.string.isRequired,
  agreeAction: PropTypes.func.isRequired,
  disagreeAction: PropTypes.func.isRequired,
};

export default CustomizableDialog;
