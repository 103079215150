/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// mui components
import InstagramIcon from "@mui/icons-material/Instagram";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import BasicLayout from "examples/LayoutContainers/PageLayout";
import GreetingsNavbar from "examples/Navbars/GreetingsNavbar";

// Colors
import colors from "assets/theme/base/colors";

// Yandex metrika
import { YMInitializer } from "react-yandex-metrika";

function Greetings() {
  const { socialMediaColors } = colors;

  return (
    <BasicLayout>
      <YMInitializer
        accounts={[93423186]}
        options={{ clickMap: true, trackLinks: true, accurateTrackBounce: true }}
      />
      <GreetingsNavbar searchProps={{}} mainLink contacts={false} />
      <MDBox
        py={1}
        px={{ xs: 4, sm: 3, lg: 2 }}
        mt={12}
        mb={8}
        mx="auto"
        width="90%"
        borderRadius="lg"
        maxWidth="xl"
      >
        <Card>
          <MDBox py={3} px={2}>
            <Grid container columnSpacing={6} py={2} px={1}>
              <Grid item xs={12} md={6}>
                <MDTypography variant="h2" mb={4}>
                  Прокат автокресел, прокат игрушек, г.Воткинск
                </MDTypography>
                <MDTypography variant="h3" mb={4}>
                  Почему это выгодно?
                </MDTypography>
                <MDTypography variant="subtitle2" mb={4}>
                  ✔ Товары для новорожденных и качественные игрушки стоят дорого. И еще не известно
                  захочет ли ваш малыш с ними играть.
                </MDTypography>
                <MDTypography variant="subtitle2" mb={4}>
                  ✔ Многие вещи нужны на короткий промежуток времени. А игрушки, порой, быстро
                  надоедают нашим маленьким непоседам
                </MDTypography>
                <MDTypography variant="subtitle2" mb={4}>
                  ✔ Приятные цены - любая семья может позволить своему ребенку играть в самые крутые
                  дорогостоящие игрушки. Взяли на пару недель - ребенок наигрался - и легко поменяли
                  на другую!
                </MDTypography>
                <MDTypography variant="subtitle2" mb={4}>
                  ✔ Мы работаем не только on-line ( VK, Instagram), сейчас у нас есть офис, где
                  можно все своими глазами посмотреть и выбрать. Все заявки и вопросы вы можете
                  оставить в сообщении группы/по телефону/WhatsApp
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={6}>
                <MDBox mb={4}>
                  <MDTypography variant="h4">Телефон</MDTypography>
                  <MDTypography variant="h4" pl={2}>
                    +7 (912) 007-17-74
                  </MDTypography>
                </MDBox>
                <MDBox mb={4}>
                  <MDTypography variant="h4">Адрес</MDTypography>
                  <MDTypography
                    variant="h4"
                    component="a"
                    href="https://www.google.com/maps/search/?api=1&query=Воткинкск+улица+Володарского+3&hl=ru"
                    target="_blank"
                    pl={2}
                  >
                    ул. Володарского, д. 3, г. Воткинск
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h4">
                  Социальные сети
                  <br />
                  <MDBox display="flex" alignItems="center" pl={1}>
                    <MDButton
                      component="a"
                      href="https://www.instagram.com/prokat_vtk"
                      target="_blank"
                      rel="noreferrer"
                      // lineHeight={1}
                      variant="text"
                      iconOnly
                      size="large"
                    >
                      <MDBox sx={{ color: socialMediaColors.instagram.main }} pt={1} ml={4} mr={2}>
                        <MDTypography sx={{ color: socialMediaColors.instagram.main }}>
                          <InstagramIcon sx={{ transform: "scale(2)" }} />
                        </MDTypography>
                      </MDBox>
                    </MDButton>
                    {/* TODO заменить на svg-иконку */}
                    <MDBox
                      component="a"
                      href="https://vk.com/prokatvtk?w=club151060941"
                      target="_blank"
                      rel="noreferrer"
                      lineHeight={1}
                      mx={2}
                      borderRadius="lg"
                      p={0.8}
                      fontWeight="bold"
                      sx={{
                        color: socialMediaColors.instagram.main,
                        border: `2px solid ${socialMediaColors.instagram.main}`,
                      }}
                    >
                      VK
                    </MDBox>
                  </MDBox>
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </BasicLayout>
  );
}

export default Greetings;
