const filters = [
  {
    name: "statusFilter",
    label: "По статусу договора",
    value: {
      label: "Все договоры",
      id: 0,
    },
    options: [
      {
        label: "Все договоры",
        id: 0,
      },
      {
        label: "Закрытые",
        id: 1,
      },
      {
        label: "Открытые",
        id: 2,
      },
      {
        label: "Забронированные",
        id: 3,
      },
    ],
    select: true,
  },
  {
    name: "priceFilter",
    label: "По статусу оплаты",
    value: {
      label: "Все",
      id: 0,
    },
    options: [
      {
        label: "Все",
        id: 0,
      },
      {
        label: "Оплаченные",
        id: 1,
      },
      {
        label: "Частично оплаченные",
        id: 2,
      },
      {
        label: "Неоплаченные",
        id: 3,
      },
    ],
    select: true,
  },
];

export default filters;
