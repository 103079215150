import { required, maxLength, minLength, email, mixedCase } from "service/Validate/validate";

const fieldsValidatorsArray = {
  // company name
  name: [
    {
      validator: required,
    },
    {
      validator: minLength,
      param: 5,
    },
    {
      validator: maxLength,
      param: 45,
    },
  ],
  // company email
  email: [
    {
      validator: required,
    },
    {
      validator: email,
    },
    {
      validator: maxLength,
      param: 45,
    },
  ],
  // company phone
  phone: [
    {
      validator: required,
    },
    {
      validator: maxLength,
      param: 20,
    },
  ],
  // password
  password: [
    {
      validator: required,
    },
    {
      validator: minLength,
      param: 8,
    },
    {
      validator: maxLength,
      param: 256,
    },
    {
      validator: mixedCase,
    },
  ],
  // password confirmation
  password_confirm: [
    {
      validator: required,
    },
    {
      validator: minLength,
      param: 8,
    },
    {
      validator: maxLength,
      param: 256,
    },
    {
      validator: mixedCase,
    },
  ],
  // admin first name
  first_name: [
    {
      validator: required,
    },
    {
      validator: maxLength,
      param: 15,
    },
  ],
  // admin last name
  last_name: [
    {
      validator: required,
    },
    {
      validator: maxLength,
      param: 15,
    },
  ],
  // admin patronymic
  patronymic: [
    {
      validator: required,
    },
    {
      validator: maxLength,
      param: 15,
    },
  ],
};

export default fieldsValidatorsArray;
