// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// react components
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// utilities
import { compareDates, dateWord, getCurrentDate } from "service/utilities";

// Models
import Client from "service/Models/Client";

// components
import ProductStatus from "layouts/products/components/ProductStatus";

function Header({ children, productData, actions }) {
  const [client, setClient] = useState({});
  const isProductInRent = () => {
    const nearestInterval = productData.rent_objects[0];
    if (nearestInterval)
      return (
        compareDates(getCurrentDate(), ">=", nearestInterval.rent.rent_date) &&
        compareDates(getCurrentDate(), "<=", nearestInterval.return_date)
      );
    return false;
  };

  const isProductOverdue = () => {
    const nearestInterval = productData.rent_objects[0];
    if (nearestInterval) return compareDates(getCurrentDate(), ">", nearestInterval.return_date);
    return false;
  };

  useEffect(() => {
    const getClient = async (clientId) => {
      const response = await Client.Read(clientId);
      if (response.success === true) return response.clients;
      console.log(response);
      return null;
    };

    if (productData.rent_objects.length) {
      getClient(productData.rent_objects[0].rent.client_id).then((responseClient) => {
        if (responseClient !== null) setClient(responseClient);
      });
    }
  }, []);

  const renderClient = () => (
    <MDTypography
      component={Link}
      to={`/crm/clients/${client.id}`}
      variant="button"
      color="text"
      fontWeight="regular"
    >
      {client.full_name}
    </MDTypography>
  );

  const renderBooking = () => {
    if (isProductInRent())
      return (
        <MDTypography variant="button" color="text" fontWeight="regular">
          Арендован до {dateWord(productData.rent_objects[0].return_date)}
        </MDTypography>
      );
    if (!isProductOverdue())
      return (
        <MDTypography variant="button" color="text" fontWeight="regular">
          Свободен до {dateWord(productData.rent_objects[0].rent.rent_date)}
        </MDTypography>
      );
    return false;
  };

  return (
    <MDBox position="relative" mb={5} pt={6} pb={3}>
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 0,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item>
            <MDAvatar
              src={productData.main_image ? productData.main_image.path : ""}
              alt="main-image"
              size="xxl"
              shadow="sm"
              bgColor="light"
              variant="rounded"
            >
              <Icon fontSize="lg" color="disabled">
                no_photography
              </Icon>
            </MDAvatar>
          </Grid>
          <Grid
            item
            flexGrow="1"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <MDBox height="100%" lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {productData.name}
              </MDTypography>
              <MDTypography
                component="a"
                href={`/crm/groups/${productData.group.id}`}
                variant="button"
                color="text"
                fontWeight="regular"
              >
                {productData.group.name}
              </MDTypography>
              <ProductStatus
                product={{
                  ...productData,
                  in_rent: isProductInRent(productData),
                  overdue: isProductOverdue(productData),
                }}
              />
              {client.id !== undefined && (
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    Клиент:
                  </MDTypography>
                  &nbsp;
                  {renderClient()}
                </MDBox>
              )}
              {productData.rent_objects.length !== 0 && renderBooking()}
            </MDBox>
            <MDBox display="flex" flexDirection="column" alignItems="flex-end">
              {actions.map((action) => (
                <MDBox mb={0.6} key={action.tooltip}>
                  <MDButton
                    component={Link}
                    to={action.route}
                    variant="outlined"
                    color={action.color || "info"}
                    size="small"
                    onClick={action.onClick}
                  >
                    <Icon>{action.icon}</Icon>&nbsp;{action.tooltip}
                  </MDButton>
                </MDBox>
              ))}
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      icon: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      tooltip: PropTypes.string.isRequired,
    })
  ).isRequired,
  productData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default Header;
