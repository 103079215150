// Mui components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Card from "@mui/material/Card";

// Material Dashboard 2 components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// PropTypes
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";

function ContentDialog({
  content,
  agreeColor,
  agreeText,
  agreeDisabled,
  disagreeColor,
  disagreeText,
  disagreeDisabled,
  agreeAction,
  disagreeAction,
  open,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleCloseWithDisagree = () => disagreeAction();
  const handleCloseWithAgree = () => {
    agreeAction();
    disagreeAction();
  };

  return (
    <Card>
      <Dialog
        open={open}
        onClose={handleCloseWithDisagree}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={Card}
      >
        <DialogTitle id="alert-dialog-title">
          <MDTypography>Подтвердите действие</MDTypography>
        </DialogTitle>
        {content}
        <DialogActions>
          <MDButton
            onClick={handleCloseWithDisagree}
            color={disagreeColor}
            disabled={disagreeDisabled}
          >
            <MDTypography
              variant="inherit"
              textTransform="inherit"
              fontWeight="inherit"
              color={disagreeColor === "light" && darkMode ? "white" : "inherit"}
            >
              {disagreeText}
            </MDTypography>
          </MDButton>
          <MDButton onClick={handleCloseWithAgree} color={agreeColor} disabled={agreeDisabled}>
            {agreeText}
          </MDButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

// Default props
ContentDialog.defaultProps = {
  agreeColor: "info",
  agreeText: "Принять",
  agreeDisabled: false,
  disagreeColor: "error",
  disagreeText: "Отмена",
  disagreeDisabled: false,
  open: true,
};

// Dialog typechecking props
ContentDialog.propTypes = {
  content: PropTypes.element.isRequired,
  agreeColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  agreeText: PropTypes.string,
  agreeDisabled: PropTypes.bool,
  disagreeColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  disagreeText: PropTypes.string,
  disagreeDisabled: PropTypes.bool,
  agreeAction: PropTypes.func.isRequired,
  disagreeAction: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default ContentDialog;
