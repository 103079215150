// react
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

// requests
import { RETRIEVE_TOKEN, API_GET_USER } from "service/request";

// components
import Preloader from "layouts/preloader";

// propTypes
import PropTypes from "prop-types";

function AuthorizeUser({ children, userReturnRequired, userCallback }) {
  const [user, setUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  // future functional variables
  // const [criticalError, setCriticalError] = useState({});

  // get authentication
  useEffect(async () => {
    // trying retrieve bearer from storage
    const bearerToken = RETRIEVE_TOKEN();
    // if bearerToken is null stop Preloader and redirect to sign-in page
    if (bearerToken === null) {
      setIsLoaded(true);
      return false;
    }
    // trying get user data
    const userJSON = await API_GET_USER(bearerToken);
    setUser(userJSON);
    setIsLoaded(true);
    if (userReturnRequired) {
      userCallback(userJSON);
    }
    return true;
  }, []);

  // future functional
  // if (objectIsNotEmpty(criticalError)) {
  //   return <Navigate to="/error" />;
  //   // return <Error />;
  // }

  /* if the loading is not completed, show the preloader
  if loaded complete skip ahead */
  if (!isLoaded) {
    return <Preloader />;
  }
  /* if the user is not found, then the token is invalid,
  we send it to the login page */
  if (user.id === undefined) {
    return <Navigate to="/authentication/sign-in" />;
  }
  /* if token valid, but user not verified
  we send it to the email verifying page */
  if (user.email_verified_at === null) {
    return <Navigate to="/email-notification" />;
  }

  return <div>{children}</div>;
}

// Setting default props for the Header
AuthorizeUser.defaultProps = {
  children: <div />,
  userReturnRequired: false,
  userCallback: () => false,
};

// Typechecking props for the Header
AuthorizeUser.propTypes = {
  children: PropTypes.node,
  userReturnRequired: PropTypes.bool,
  userCallback: PropTypes.func,
};

export default AuthorizeUser;
