// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// propTypes
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// validate func
import { Validate } from "service/validator";

// data
import inputs from "layouts/cash/data/createTransactionFormInputs";

// Models
import Cash from "service/Models/Cash";
import Product from "service/Models/Product";
import Client from "service/Models/Client";

function CreateTransactionForm({ transaction, isUpdateAction }) {
  const navigate = useNavigate();
  // default form state or product info form state
  const [formState, setFormState] = useState(() => {
    const freshFormStateObject = {};
    const inputsNames = Object.keys(inputs);
    if (transaction.id === undefined) {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = inputs[inputName].defaultValue;
      });
    } else {
      inputsNames.forEach((inputName) => {
        if (inputName === "transaction_type") {
          freshFormStateObject.transaction_type =
            transaction.status === "+"
              ? { label: "Пополнение", id: 0 }
              : { label: "Списание", id: 1 };
        } else if (inputName === "products") {
          const productsEntries = [];
          const getPricesOptions = (pricesArray) => {
            const entries = [];
            pricesArray
              .filter((price) => price.price_type !== 2)
              .forEach((price) => {
                let type = "";
                if (price.price_type === 0) type = "Аренда";
                else type = "Пополнение";

                entries[entries.length] = {
                  type,
                  days: price.days !== null ? price.days.toString() : "",
                  price: price.price,
                  id: price.id,
                };
              });
            return entries;
          };
          transaction.products.forEach((product) => {
            productsEntries[productsEntries.length] = {
              id: product.id,
              name: product.name,
              currentPrice: {
                days: product.pivot.days,
                price: product.pivot.price,
              },
              prices: getPricesOptions(product.prices),
            };
          });
          freshFormStateObject.products = productsEntries;
        } else freshFormStateObject[inputName] = transaction[inputName];
      });
    }
    return freshFormStateObject;
  });
  // default form validation or product info form validation
  const [formValidation, setFormValidation] = useState(() => {
    const freshFormValidationObject = {};
    Object.keys(inputs).forEach((inputName) => {
      if (inputs[inputName].validationNeeded) {
        const errorsArray = Validate(
          transaction[inputName] || inputs[inputName].defaultValue,
          inputs[inputName].validationRules,
          inputs[inputName].attribute || inputName
        );
        freshFormValidationObject[inputName] = {
          isValid: errorsArray.length === 0,
          validationErrorMessage: errorsArray,
          isDirty: false,
        };
      }
    });
    return freshFormValidationObject;
  });

  // calculate value and set form state
  const calculateValue = (productsArray = null) => {
    formState.value = 0.0;
    if (productsArray !== null) formState.products = productsArray;
    formState.products.forEach(({ currentPrice }) => {
      formState.value += parseFloat(currentPrice ? currentPrice.price : 0);
    });
    setFormState({ ...formState });

    const errorsArray = Validate(
      formState.value,
      inputs.value.validationRules,
      inputs.value.attribute
    );
    const newFormValidation = {
      isValid: errorsArray.length === 0,
      validationErrorMessage: errorsArray,
      isDirty: true,
    };
    setFormValidation({ ...formValidation, value: newFormValidation });
  };

  // function check is input valid
  const isValid = (inputName) => formValidation[inputName].isValid;
  // function check full form validate
  const formValidate = () => {
    let formValid = true;
    Object.keys(formValidation).forEach((input) => {
      if (!formValidation[input].isValid) {
        formValid = false;
      }
    });
    return formValid;
  };

  // active products state
  const [products, setProducts] = useState([]);
  // clients state
  const [clients, setClients] = useState([]);
  const getClientFullName = (client) =>
    `${client.last_name} ${client.first_name} ${client.patronymic}`;

  // get active products
  useEffect(() => {
    const getPricesOptions = (pricesArray) => {
      const entries = [];
      pricesArray
        .filter((price) => price.price_type !== 2)
        .forEach((price) => {
          let type = "";
          if (price.price_type === 0) type = "Аренда";
          else type = "Пополнение";

          entries[entries.length] = {
            type,
            days: price.days !== null ? price.days.toString() : "",
            price: price.price,
            id: price.id,
          };
        });
      return entries;
    };
    const getProducts = async () => {
      const response = await Product.GetAll();
      if (response.success === true) {
        const activeProducts = response.products.filter(({ active }) => active === true);
        const entries = [];
        activeProducts.forEach(({ name, id, prices }) => {
          entries[entries.length] = {
            id,
            name,
            currentPrice: null,
            prices: getPricesOptions(prices),
          };
        });
        setProducts(entries);
        return true;
      }
      // TODO сделать обработку ошибки
      console.log(response);
      return false;
    };
    const getClients = async () => {
      const response = await Client.Read();
      if (response.success === true) {
        const activeClients = response.clients.filter((client) => client.deleted_at === null);
        const entries = [];
        activeClients.forEach((client) => {
          entries[entries.length] = {
            label: getClientFullName(client),
            id: client.id,
          };
        });
        setClients(entries);
        return true;
      }
      // TODO добавить обработку ошибок
      console.log(response);
      return false;
    };
    getProducts();
    getClients();
  }, []);

  const handleTransactionTypeChanged = (event, value) => {
    formState.transaction_type = value;
    calculateValue([]);
  };

  const handleInputChange = (event) => {
    const [name, value] = [event.target.name, event.target.value];
    if (inputs[name].validationNeeded) {
      const errorsArray = Validate(value, inputs[name].validationRules, inputs[name].attribute);
      const newFormValidation = {
        isValid: errorsArray.length === 0,
        validationErrorMessage: errorsArray,
        isDirty: true,
      };
      setFormValidation({ ...formValidation, [name]: newFormValidation });
    }
    setFormState({ ...formState, [name]: value });
  };

  const handleProductValueChange = (event, productIndex) => {
    const productsArray = formState.products;
    const { value } = event.target;
    productsArray[productIndex].currentPrice = {
      price: value || 0,
      days: null,
    };
    calculateValue(productsArray);
  };

  const handleProductsChange = (event, value) => {
    const savePrice = (pId) => {
      let result = null;
      formState.products.forEach(({ id, currentPrice }) => {
        if (id === pId) result = currentPrice;
      });
      return result;
    };

    const productsData = [];
    if (Array.isArray(value)) {
      value.forEach(({ id, name, prices }) => {
        productsData[productsData.length] = {
          id,
          prices,
          name,
          currentPrice: savePrice(id),
        };
      });
    } else if (value) {
      productsData[0] = {
        id: value.id,
        prices: value.prices,
        name: value.name,
        currentPrice: null,
      };
    }

    calculateValue(productsData);
  };

  const handleDaysChange = (value, productIndex) => {
    const productsArray = formState.products;
    productsArray[productIndex].currentPrice = value || null;
    calculateValue(productsArray);
  };

  // post new product group form data to server
  const handleSubmit = (event) => {
    event.preventDefault();
    const transactionData = { ...formState };
    transactionData.value =
      transactionData.transaction_type.id === 0 ? transactionData.value : -transactionData.value;
    transactionData.products.forEach((product, index) => {
      if (transactionData.transaction_type.id !== 0)
        transactionData.products[index].currentPrice.price = -product.currentPrice.price;
    });
    const createTransactionRequest = async () => {
      const response = await Cash.Create(transactionData);
      if (response.success === true) {
        navigate("/crm/cash");
        return true;
      }
      return false;
    };

    const updateTransactionRequest = async () => {
      const response = await Cash.Update(transactionData, transaction.id);
      if (response.success === true) {
        navigate("/crm/cash");
        return true;
      }
      return false;
    };

    if (isUpdateAction) updateTransactionRequest();
    else createTransactionRequest();
  };

  return (
    <div>
      <Collapse in={!formValidate()}>
        <Alert severity="error" icon={false}>
          {Object.keys(formValidation).map((validatedValue) => (
            <MDTypography
              color="error"
              variant="subtitle2"
              fontWeight="medium"
              sx={{ fontSize: "caption.fontSize" }}
              key={validatedValue}
            >
              {formValidation[validatedValue].validationErrorMessage[0]}
            </MDTypography>
          ))}
        </Alert>
      </Collapse>
      <MDBox p={2}>
        <Grid container spacing={4}>
          <Grid
            container
            item
            xs={12}
            md={4}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            {/* Тип операции */}
            <Grid item>
              <Autocomplete
                name="transaction_type"
                disableClearable
                autoHighlight={false}
                selectOnFocus={false}
                options={inputs.transaction_type.options}
                size="small"
                sx={{ width: "13rem" }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    label="Тип операции"
                  />
                )}
                onChange={handleTransactionTypeChanged}
                value={formState.transaction_type}
              />
            </Grid>
            {/* Значение */}
            <Grid item>
              <MDInput
                label="Сумма"
                name="value"
                type="number"
                sx={{ width: "100%" }}
                size="small"
                value={formState.value}
                onChange={handleInputChange}
                {...(isValid("value") ? { success: true } : { error: true })}
                {...(formState.products.length ? { disabled: true } : "")}
              />
            </Grid>
            {/* Дата */}
            <Grid item>
              <MDInput
                name="date"
                label="Дата"
                type="date"
                sx={{ width: "100%" }}
                size="small"
                value={formState.date}
                InputProps={{ inputProps: { min: "2023-01-01" } }}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <MDInput
                label="Комментарий к операции"
                name="commentary"
                multiline
                sx={{ width: "100%" }}
                size="small"
                rows={3}
                value={formState.commentary}
                onChange={handleInputChange}
                min="1"
                max="100"
                {...(isValid("commentary") ? { success: true } : { error: true })}
              />
            </Grid>
          </Grid>
          {/* Товары */}
          <Grid item xs={12}>
            <MDTypography variant="h6">Товары</MDTypography>
            <Autocomplete
              name="products"
              options={products}
              size="small"
              sx={{ width: "100%" }}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <MDInput {...params} label="Товары" />}
              onChange={handleProductsChange}
              {...(formState.transaction_type.id === 0 ? { multiple: true } : "")}
              {...(formState.transaction_type.id === 0
                ? { value: formState.products }
                : { value: !formState.products.length ? {} : formState.products[0] })}
            />
            <MDBox mt={2}>
              {formState.products.map(({ currentPrice, name, prices }, productIndex) => (
                <Grid container mb={1} key={name}>
                  <Grid item xs={3}>
                    <MDTypography variant="button" color="text" fontWeight="medium">
                      {name}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    {formState.transaction_type.id === 0 ? (
                      <Autocomplete
                        name={`${name}_days`}
                        options={prices}
                        size="small"
                        sx={{ width: "100%" }}
                        groupBy={(option) => option.type}
                        getOptionLabel={(option) => option.days}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <MDInput {...params} label="Кол-во дней" />}
                        value={currentPrice}
                        onChange={(event, value) => handleDaysChange(value, productIndex)}
                      />
                    ) : (
                      <MDInput
                        label="Сумма"
                        name={`${name}_value`}
                        type="number"
                        sx={{ width: "100%" }}
                        size="small"
                        value={formState.value}
                        onChange={(event) => handleProductValueChange(event, productIndex)}
                        {...(isValid("value") ? { success: true } : { error: true })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3} md={6} textAlign="right">
                    <MDTypography variant="button" color="text" fontWeight="medium">
                      {currentPrice ? currentPrice.price : 0} руб.
                    </MDTypography>
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          </Grid>
          {/* Клиенты */}
          <Grid item xs={12}>
            <MDTypography variant="h6">Клиенты</MDTypography>
            <Autocomplete
              name="client"
              options={clients}
              size="small"
              sx={{ width: "100%" }}
              getOptionLabel={(option) => (option.label ? option.label : "")}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <MDInput {...params} label="Клиенты" />}
              onChange={(event, value) =>
                setFormState({ ...formState, client_id: value ? value.id : null })
              }
              value={clients.filter((client) => client.id === formState.client_id)[0] || ""}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            {formValidate() ? (
              <MDButton variant="gradient" color="success" size="small" onClick={handleSubmit}>
                <Icon>add</Icon>&nbsp;{isUpdateAction ? "Обновить" : "Добавить"} операцию
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={handleSubmit}
                disabled
              >
                <Icon>add</Icon>&nbsp;{isUpdateAction ? "Обновить" : "Добавить"} операцию
              </MDButton>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

CreateTransactionForm.defaultProps = {
  transaction: {},
  isUpdateAction: false,
};

CreateTransactionForm.propTypes = {
  isUpdateAction: PropTypes.bool,
  transaction: PropTypes.oneOfType([PropTypes.object]),
};

export default CreateTransactionForm;
