// @mui material components
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ImageUploader from "components/ImageUploader";

// React
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

// Models
import ProductGroup from "service/Models/ProductGroup";
import Product from "service/Models/Product";

// propTypes
import PropTypes from "prop-types";

// Components
import Preloader from "layouts/preloader";

// data
import inputs from "layouts/products/data/productFormInputs";

// validate func
import { Validate } from "service/validator";

function ProductForm({ product, isUpdateAction }) {
  // fresh form state object constructor
  const freshFormState = () => {
    const freshFormStateObject = {};
    const inputsNames = Object.keys(inputs);
    if (!product.id) {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = inputs[inputName].defaultValue;
      });
    } else {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = product[inputName];
      });
    }
    return freshFormStateObject;
  };

  // form validation state node constructor
  const formValidationElement = (validationErrorArray = [], isDirty = false) => ({
    isValid: validationErrorArray.length === 0,
    validationErrorMessage: validationErrorArray,
    isDirty,
  });

  // fresh form validation object constructor
  const freshFormValidation = () => {
    const freshFormValidationObject = {};
    Object.keys(inputs).forEach((inputName) => {
      if (inputs[inputName].validationNeeded) {
        const errorsArray = Validate(
          product[inputName] || inputs[inputName].defaultValue,
          inputs[inputName].validationRules,
          inputs[inputName].attribute || inputName
        );
        freshFormValidationObject[inputName] = formValidationElement(errorsArray, false);
      }
    });
    freshFormValidationObject.age_range = formValidationElement();
    return freshFormValidationObject;
  };

  const [formState, setFormState] = useState(freshFormState());
  const [formValidation, setFormValidation] = useState(freshFormValidation());
  const [returnedProduct, setReturnedProduct] = useState({});
  const [formError, setFormError] = useState({});
  const [productGroups, setProductGroups] = useState(["Загрузка"]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [images, setImages] = useState(product.images);
  const imagesCallback = (value) => {
    setImages(value);
  };

  // get all groups and preparing to option arr
  useEffect(async () => {
    const response = await ProductGroup.GetAll();
    if (response.success === true) {
      const entries = [];
      response.groups.forEach((group, i) => {
        entries[i] = {
          label: group.name,
          id: group.id,
        };
      });
      setProductGroups(entries);
      setIsLoaded(true);
    } else {
      console.log(response);
    }
  }, []);

  const ageRangeValidate = (value, name) => {
    let error = "";
    const numericValue = parseInt(value, 10);
    if (name === "age_range_top") {
      const numericAgeRangeBottom = parseInt(formState.age_range_bottom, 10);
      if (numericAgeRangeBottom > numericValue) {
        error = `Поле ${inputs.age_range_bottom.attribute} не может быть больше поля ${inputs.age_range_top.attribute}`;
      }
    } else if (name === "age_range_bottom") {
      const numericAgeRangeTop = parseInt(formState.age_range_top, 10);
      if (numericAgeRangeTop < numericValue) {
        error = `Поле ${inputs.age_range_bottom.attribute} не может быть больше поля ${inputs.age_range_top.attribute}`;
      }
    }
    return error;
  };

  const handleInputChange = (event) => {
    const [target, name] = [event.target, event.target.name];
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (inputs[name].validationNeeded) {
      const errorsArray = Validate(value, inputs[name].validationRules, inputs[name].attribute);

      if (name.indexOf("age_range") !== -1) {
        const ageRangeError = ageRangeValidate(value, name);
        const ageRangeValidation = {
          isValid: ageRangeError.length === 0,
          validationErrorMessage: [ageRangeError],
          isDirty: true,
        };
        setFormValidation({
          ...formValidation,
          [name]: formValidationElement(errorsArray, true),
          age_range: ageRangeValidation,
        });
      } else {
        setFormValidation({ ...formValidation, [name]: formValidationElement(errorsArray, true) });
      }
    }

    if (formState.same_description && name === "description") {
      formState.site_description = value;
    }

    setFormState({ ...formState, [name]: value });
  };

  const handleAutoCompleteChange = (event, value) => {
    event.preventDefault();
    const newFormValidation = {
      isValid: true,
      validationErrorMessage: [""],
      isDirty: true,
    };
    setFormValidation({ ...formValidation, product_group_id: newFormValidation });
    setFormState({ ...formState, product_group_id: value.id });
  };

  const isValid = (inputName) => formValidation[inputName].isValid;
  const formValidate = () => {
    let formValid = true;
    Object.keys(formValidation).forEach((input) => {
      if (!formValidation[input].isValid) {
        formValid = false;
      }
    });
    return formValid;
  };

  // handling serverError
  const alertAction = (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        setFormError({});
      }}
    >
      <Icon>close</Icon>
    </IconButton>
  );

  // post new product form data to server
  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;
    if (isUpdateAction) {
      response = await Product.Update(product.id, formState, images);
    } else {
      response = await Product.Create(formState, images);
    }
    if (response.success !== true) {
      setFormError(response.errors);
      return false;
    }
    setReturnedProduct(response.product);
    return true;
  };

  if (!isLoaded) {
    return <Preloader />;
  }

  if (returnedProduct.id !== undefined) {
    return <Navigate to={`/crm/products/${returnedProduct.id}`} />;
  }

  return (
    <MDBox>
      <MDBox>
        <Collapse in={Object.keys(formError).length !== 0}>
          <Alert severity="error" action={alertAction} fontSize="caption.fontSize">
            <AlertTitle>Товар не {isUpdateAction ? "обновлен" : "добавлен"}</AlertTitle>
            {Object.keys(formError).map((error) =>
              formError[error].map((message) => (
                <MDTypography
                  key={message}
                  color="error"
                  variant="subtitle2"
                  fontWeight="medium"
                  sx={{ fontSize: "caption.fontSize" }}
                >
                  {message}
                </MDTypography>
              ))
            )}
          </Alert>
        </Collapse>
        <Collapse in={!formValidate()}>
          <Alert severity="error" icon={false}>
            {Object.keys(formValidation).map((validatedValue) => (
              <MDTypography
                color="error"
                variant="subtitle2"
                fontWeight="medium"
                sx={{ fontSize: "caption.fontSize" }}
                key={validatedValue}
              >
                {formValidation[validatedValue].validationErrorMessage[0]}
              </MDTypography>
            ))}
          </Alert>
        </Collapse>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={4}>
          <Grid
            container
            item
            xs={12}
            md={4}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            {/* Группа продукта */}
            <Grid item>
              <Autocomplete
                name="product_group_id"
                disableClearable
                autoSelect={false}
                disablePortal
                options={productGroups}
                size="small"
                sx={{ width: "13rem" }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    label="Группа продукта"
                  />
                )}
                onChange={handleAutoCompleteChange}
                {...(product.id
                  ? { defaultValue: { label: product.group.name, id: product.group.id } }
                  : "")}
              />
            </Grid>
            {/* Название */}
            <Grid item>
              <MDInput
                label="Название"
                name="name"
                sx={{ width: "100%" }}
                size="small"
                value={formState.name}
                onChange={handleInputChange}
                {...(isValid("name") ? { success: true } : { error: true })}
              />
            </Grid>
            {/* Время подготовки */}
            <Grid item>
              <MDInput
                name="preparing_time"
                type="number"
                default="0"
                label="Время подготовки (дн.)"
                sx={{ width: "100%" }}
                size="small"
                value={formState.preparing_time}
                onChange={handleInputChange}
                {...(isValid("preparing_time") ? { success: true } : { error: true })}
              />
            </Grid>
            {/* Цена подготовки */}
            <Grid item>
              <MDInput
                name="prepare_price"
                type="number"
                label="Цена подготовки"
                sx={{ width: "100%" }}
                size="small"
                value={formState.prepare_price}
                onChange={handleInputChange}
                {...(isValid("prepare_price") ? { success: true } : { error: true })}
              />
            </Grid>
            <Grid item>
              <MDInput
                name="prepayment_price"
                type="number"
                label="Предоплата"
                sx={{ width: "100%" }}
                size="small"
                value={formState.prepayment_price}
                onChange={handleInputChange}
                {...(isValid("prepayment_price") ? { success: true } : { error: true })}
              />
            </Grid>
            {/* Возрастной интервал */}
            <Grid item container columnSpacing={2}>
              <Grid item xs={12}>
                <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                  Возрастной интервал
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDInput
                  name="age_range_bottom"
                  type="number"
                  label="От"
                  sx={{ width: "100%" }}
                  size="small"
                  value={formState.age_range_bottom}
                  onChange={handleInputChange}
                  {...(isValid("age_range_bottom") && isValid("age_range")
                    ? { success: true }
                    : { error: true })}
                />
              </Grid>
              <Grid item xs={6}>
                <MDInput
                  name="age_range_top"
                  type="number"
                  label="До"
                  sx={{ width: "100%" }}
                  size="small"
                  value={formState.age_range_top}
                  onChange={handleInputChange}
                  {...(isValid("age_range_top") && isValid("age_range")
                    ? { success: true }
                    : { error: true })}
                />
              </Grid>
            </Grid>
            {isUpdateAction && (
              <Grid item>
                <Checkbox
                  name="active"
                  onClick={handleInputChange}
                  checked={formState.active}
                  disabled={!product.prices.filter((price) => price.price_type === 0).length}
                />
                <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                  Активировать товар
                </MDTypography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            rowSpacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            {/* Внутреннее описание */}
            <Grid item>
              <MDInput
                name="description"
                label="Описание товара"
                multiline
                sx={{ width: "100%" }}
                size="small"
                rows={3}
                onChange={handleInputChange}
                value={formState.description}
                success
              />
            </Grid>
            {/* Флаг одинкого описания */}
            <Grid item>
              <Checkbox
                name="same_description"
                onClick={handleInputChange}
                checked={formState.same_description}
              />
              <MDTypography variant="caption" fontWeight="bold" color="info" textGradient>
                Использовать то же описание для сайта
              </MDTypography>
            </Grid>
            {/* Описание для сайта */}
            {!formState.same_description && (
              <Grid item>
                <MDInput
                  name="site_description"
                  label="Описание товара для сайта"
                  multiline
                  sx={{ width: "100%" }}
                  size="small"
                  rows={3}
                  onChange={handleInputChange}
                  value={formState.site_description}
                  success
                />
              </Grid>
            )}
            {/* Комплектация */}
            <Grid item>
              <MDInput
                name="complectation"
                label="Комплектация товара"
                multiline
                sx={{ width: "100%" }}
                size="small"
                rows={3}
                onChange={handleInputChange}
                value={formState.complectation}
                success
              />
            </Grid>
          </Grid>
          {/* Загрузчик изображений */}
          {isUpdateAction ? (
            <ImageUploader
              compressImagesCallback={imagesCallback}
              imagesLimit={5}
              images={product.images}
              _mainImage={product.main_image}
            />
          ) : (
            <ImageUploader compressImagesCallback={imagesCallback} imagesLimit={5} />
          )}
          {/* Кнопка создания */}
          <Grid item xs={12} container justifyContent="flex-end">
            {formValidate() ? (
              <MDButton variant="gradient" color="success" size="small" onClick={handleSubmit}>
                <Icon>add</Icon>&nbsp;{isUpdateAction ? "Обновить" : "Добавить"} товар
              </MDButton>
            ) : (
              <MDButton variant="gradient" color="success" size="small" disabled>
                <Icon>add</Icon>&nbsp;{isUpdateAction ? "Обновить" : "Добавить"} товар
              </MDButton>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// Setting default props for the Header
ProductForm.defaultProps = {
  product: {},
  isUpdateAction: false,
};

// Typechecking props for the Header
ProductForm.propTypes = {
  product: PropTypes.oneOfType([PropTypes.object]),
  isUpdateAction: PropTypes.bool,
};

export default ProductForm;
