// mui components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@mui/material/Alert";

// material dashboard 2 components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Components
import CustomDialog from "examples/Dialog";

// proptypes
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// utilities
import { getCurrentDate, reduceAccByColName } from "service/utilities";

// Validator
import { Validate } from "service/validator";

// Models
import Cash from "service/Models/Cash";
import Rent from "service/Models/Rent";

function ChangeShippingDateDialog({ open, rent, updateRentCallback }) {
  const [dialogInfo, setDialogInfo] = useState(null);
  const [formState, setFormState] = useState({
    transaction_checked: {
      value: false,
      is_dirty: false,
    },
    shipping_cost: {
      value: 0,
      validation: {
        is_valid: true,
        message: [],
      },
      is_dirty: false,
    },
  });

  const createReturnShippingCostTransaction = async () => {
    const response = await Cash.Create({
      note: `Возврат средств за доставку по договору №${rent.id}`,
      value: -parseFloat(rent.shipping_cost),
      date: getCurrentDate(),
      rent_id: rent.id,
    });
    if (response.success === true) return response.cash;
    throw new Error(response);
  };

  const deleteShippingCost = async () => {
    const copyRent = { ...rent };
    copyRent.shipping_cost = null;
    const response = await Rent.Update(rent.id, copyRent);
    if (response.success === true) return response.rent;
    throw new Error(response);
  };

  const addShippingCost = async () => {
    const copyRent = { ...rent };
    copyRent.shipping_cost = formState.shipping_cost.value;
    const response = await Rent.Update(rent.id, copyRent);
    if (response.success === true) return response.rent;
    throw new Error(response);
  };

  const createShippingCostTransaction = async () => {
    const response = await Cash.Create({
      note: `Оплата доставки по договору №${rent.id}`,
      value: parseFloat(formState.shipping_cost.value),
      date: getCurrentDate(),
      rent_id: rent.id,
    });
    if (response.success === true) return response.cash;
    throw new Error(response);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const validationErrors = Validate(
      value,
      [`between:0,999999.99`, "fixed:2", "required"],
      "'Стоимость доставки'"
    );
    setFormState({
      ...formState,
      shipping_cost: {
        value,
        validation: {
          is_valid: validationErrors.length === 0,
          message: validationErrors,
        },
        is_dirty: true,
      },
    });
  };

  const freshDialogInfo = () => ({
    content: (
      <DialogContent>
        <DialogContentText>
          Вы уверены, что хотите {parseFloat(rent.shipping_cost) ? "отменить" : "добавить"} доставку
          товаров?
        </DialogContentText>
        {reduceAccByColName(rent.cash, "value") >= rent.shipping_cost && (
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.transaction_checked.value}
                onClick={() =>
                  setFormState({
                    ...formState,
                    transaction_checked: {
                      value: !formState.transaction_checked.value,
                      is_dirty: true,
                    },
                  })
                }
              />
            }
            label={
              <MDTypography variant="caption" color="text">
                Создать операцию {parseFloat(rent.shipping_cost) ? "возврата" : "получения"} средств
              </MDTypography>
            }
          />
        )}
        {/* Вывод ошибок валидации полей */}
        <Collapse in={!formState.shipping_cost.validation.is_valid}>
          <Alert severity="error" icon={false}>
            {formState.shipping_cost.validation.message.map((message) => (
              <MDTypography
                color="error"
                variant="subtitle2"
                fontWeight="medium"
                sx={{ fontSize: "caption.fontSize" }}
                key={message}
              >
                {message}
              </MDTypography>
            ))}
          </Alert>
        </Collapse>
        {/* Поле ввода */}
        <Collapse in={!parseFloat(rent.shipping_cost)}>
          <MDInput
            sx={{ marginTop: 3, width: "100%" }}
            name="shipping_cost"
            type="number"
            label="Стоимость доставки"
            onChange={handleInputChange}
            value={formState.shipping_cost.value}
            success={formState.shipping_cost.validation.is_valid}
            error={!formState.shipping_cost.validation.is_valid}
          />
        </Collapse>
      </DialogContent>
    ),
    agreeText: "Подтвердить",
    agreeColor: "info",
    disagreeColor: "light",
    agreeDisabled: !formState.shipping_cost.validation.is_valid,
    agreeAction: () => {
      if (parseFloat(rent.shipping_cost)) {
        deleteShippingCost()
          .then(() => {
            if (formState.transaction_checked.value) return createReturnShippingCostTransaction();
            return null;
          })
          .then((respCash) => {
            if (respCash !== null) {
              updateRentCallback({
                ...rent,
                shipping_cost: null,
                cash: [...rent.cash, respCash],
              });
            } else {
              updateRentCallback({ ...rent, shipping_cost: null });
            }
          })
          .catch((err) => console.error(err));
      } else {
        addShippingCost()
          .then(() => {
            if (formState.transaction_checked.value) return createShippingCostTransaction();
            return null;
          })
          .then((respCash) => {
            if (respCash !== null) {
              updateRentCallback({
                ...rent,
                shipping_cost: formState.shipping_cost.value,
                cash: [...rent.cash, respCash],
              });
            } else {
              updateRentCallback({ ...rent, shipping_cost: formState.shipping_cost.value });
            }
          })
          .catch((err) => console.error(err));
      }
    },
    disagreeAction: () => {
      setFormState({
        transaction_checked: {
          value: false,
          is_dirty: false,
        },
        shipping_cost: {
          value: 0,
          validation: {
            is_valid: true,
            message: [],
          },
          is_dirty: false,
        },
      });
      setDialogInfo(null);
    },
  });

  useEffect(() => {
    if (formState.transaction_checked.is_dirty || formState.shipping_cost.is_dirty)
      setDialogInfo(freshDialogInfo());
  }, [formState]);

  return (
    <Collapse in={open}>
      {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
      <MDButton
        sx={{ marginBottom: 1, width: "100%" }}
        variant="gradient"
        size="small"
        color="info"
        onClick={() => setDialogInfo(freshDialogInfo())}
      >
        <Icon fontSize="small">{parseFloat(rent.shipping_cost) ? "remove" : "add"}</Icon>&nbsp;
        {parseFloat(rent.shipping_cost) ? "Отменить" : "Добавить"} доставку
      </MDButton>
    </Collapse>
  );
}

// RenewalObjectForm prop-types
ChangeShippingDateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rent_date: PropTypes.string.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
    shipping_cost: PropTypes.string.isRequired,
  }).isRequired,
  updateRentCallback: PropTypes.func.isRequired,
};

export default ChangeShippingDateDialog;
