// custom hooks
import useTextInput from "service/CustomHooks/useTextInput";
import useForm from "service/CustomHooks/useForm";
import usePhoneInput from "service/CustomHooks/usePhoneInput";
import usePasswordInput from "service/CustomHooks/usePasswordInput";
import useCheckbox from "service/CustomHooks/useCheckbox";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Alert from "@mui/material/Alert";
// import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Models
import Company from "service/Models/Company";

// data
import registerFormValidators from "layouts/authentication/data/registerFormValidators";

// Components
import Preloader from "layouts/preloader";

// colors
import colors from "assets/theme/base/colors";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

function RegisterForm() {
  const navigate = useNavigate();
  const companyNameInput = useTextInput("company_name", registerFormValidators.name);
  const firstNameInput = useTextInput("first_name", registerFormValidators.first_name);
  const lastNameInput = useTextInput("last_name", registerFormValidators.last_name);
  const patronymicInput = useTextInput("patronymic", registerFormValidators.patronymic);
  const emailInput = useTextInput("email", registerFormValidators.email);
  const phoneInput = usePhoneInput("phone", registerFormValidators.phone);
  const passwordInput = usePasswordInput("password", registerFormValidators.password);
  const confirmPasswordInput = usePasswordInput("password_confirmation", []);
  const agreeTerms = useCheckbox("agree_terms");

  const form = useForm(
    [
      companyNameInput,
      firstNameInput,
      lastNameInput,
      patronymicInput,
      emailInput,
      phoneInput,
      passwordInput,
      confirmPasswordInput,
    ],
    async (formData) =>
      Company.Register({ ...formData, international_phone: phoneInput.internationalValue }),
    (apiResponse) => {
      localStorage.setItem("API_TOKEN", apiResponse.company.access_token);
      navigate("/email-notification");
    },
    () => {}
  );

  const handleChangePassword = (e) => {
    passwordInput.handleChangeInput(e);
    confirmPasswordInput.updateError(
      e.target.value !== confirmPasswordInput.value ? "Поля не совпадают" : null
    );
  };

  const handleChangeConfirmPassword = (e) => {
    confirmPasswordInput.handleChangeInput(e).then(() => {
      confirmPasswordInput.updateError(
        e.target.value !== passwordInput.value ? "Поля не совпадают" : null
      );
    });
  };

  if (form.isSending) return <Preloader fullScreen={false} />;

  return (
    <MDBox component="form" role="form">
      <Grid container columnSpacing={6}>
        <Grid item xs={12} mb={2}>
          {Boolean(form.sendingError) && (
            <Collapse in>
              <Alert severity="error" icon={false}>
                {Object.keys(form.sendingError).map((key) =>
                  form.sendingError[key].map((error) => (
                    <MDTypography
                      color="error"
                      variant="subtitle2"
                      fontWeight="medium"
                      sx={{ fontSize: "caption.fontSize" }}
                      key={error}
                    >
                      {error}
                    </MDTypography>
                  ))
                )}
              </Alert>
            </Collapse>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <MDBox>
            <MDInput
              id={companyNameInput.id}
              name="company_name"
              onChange={companyNameInput.handleChangeInput}
              value={companyNameInput.value}
              type="text"
              label="Название компании*"
              variant="standard"
              fullWidth
              success={companyNameInput.isDirty && !companyNameInput.error}
              error={companyNameInput.isDirty && !!companyNameInput.error}
              helperText={companyNameInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
          </MDBox>
          <MDBox>
            <MDInput
              id={lastNameInput.id}
              name="last_name"
              onChange={lastNameInput.handleChangeInput}
              type="text"
              value={lastNameInput.value}
              label="Фамилия*"
              variant="standard"
              fullWidth
              success={lastNameInput.isDirty && !lastNameInput.error}
              error={lastNameInput.isDirty && !!lastNameInput.error}
              helperText={lastNameInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
          </MDBox>
          <MDBox>
            <MDInput
              id={firstNameInput.id}
              name="first_name"
              onChange={firstNameInput.handleChangeInput}
              type="text"
              value={firstNameInput.value}
              label="Имя*"
              variant="standard"
              fullWidth
              success={firstNameInput.isDirty && !firstNameInput.error}
              error={firstNameInput.isDirty && !!firstNameInput.error}
              helperText={firstNameInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
          </MDBox>
          <MDBox>
            <MDInput
              id={patronymicInput.id}
              name="patronymic"
              onChange={patronymicInput.handleChangeInput}
              type="text"
              value={patronymicInput.value}
              label="Отчество*"
              variant="standard"
              fullWidth
              success={patronymicInput.isDirty && !patronymicInput.error}
              error={patronymicInput.isDirty && !!patronymicInput.error}
              helperText={patronymicInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <MDBox>
            <MDInput
              id={emailInput.id}
              name="email"
              onChange={emailInput.handleChangeInput}
              type="text"
              label="Email*"
              variant="standard"
              value={emailInput.value}
              fullWidth
              success={emailInput.isDirty && !emailInput.error}
              error={emailInput.isDirty && !!emailInput.error}
              helperText={emailInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
          </MDBox>
          <MDBox>
            <MDInput
              name="phone"
              id={phoneInput.id}
              onChange={phoneInput.handleChangeInput}
              value={phoneInput.value}
              type="tel"
              label="Телефон компании*"
              variant="standard"
              fullWidth
              success={phoneInput.isDirty && !phoneInput.error}
              error={phoneInput.isDirty && !!phoneInput.error}
              helperText={phoneInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDInput
              id={passwordInput.id}
              name="password"
              onChange={(e) => handleChangePassword(e)}
              label="Пароль*"
              variant="standard"
              type={passwordInput.visible ? "text" : "password"}
              fullWidth
              value={passwordInput.value}
              success={passwordInput.isDirty && !passwordInput.error}
              error={passwordInput.isDirty && !!passwordInput.error}
              helperText={passwordInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
            <MDBox ml={2}>
              <MDButton
                onClick={passwordInput.handleChangeVisible}
                variant="gradient"
                color="info"
                iconOnly
              >
                {passwordInput.visible ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDInput
              id={confirmPasswordInput.id}
              name="password_confirmation"
              onChange={(e) => handleChangeConfirmPassword(e)}
              label="Повторите пароль*"
              variant="standard"
              value={confirmPasswordInput.value}
              onFocus={(e) => e.preventDefault()}
              type={confirmPasswordInput.visible ? "text" : "password"}
              fullWidth
              success={confirmPasswordInput.isDirty && !confirmPasswordInput.error}
              error={confirmPasswordInput.isDirty && !!confirmPasswordInput.error}
              helperText={confirmPasswordInput.error || " "}
              FormHelperTextProps={{
                sx: {
                  color: colors.error.main,
                },
              }}
            />
            <MDBox ml={2}>
              <MDButton
                onClick={confirmPasswordInput.handleChangeVisible}
                variant="gradient"
                color="info"
                iconOnly
              >
                {confirmPasswordInput.visible ? (
                  <Icon>visibility_off</Icon>
                ) : (
                  <Icon>visibility</Icon>
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox display="flex" alignItems="center" ml={-1}>
        <Checkbox
          id={agreeTerms.id}
          name="agree_terms"
          checked={agreeTerms.value}
          onClick={agreeTerms.handleChangeInput}
        />
        <MDTypography
          variant="caption"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;Я согласен с&nbsp;
        </MDTypography>
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          fontWeight="bold"
          color="info"
          textGradient
        >
          Условиями использования
        </MDTypography>
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton
          onClick={() => form.handleSubmit()}
          variant="gradient"
          color="info"
          fullWidth
          disabled={form.hasFieldsErrors || !agreeTerms.value || !form.isAllDirty}
        >
          зарегистрироваться
        </MDButton>
      </MDBox>
      <MDBox mt={3} mb={1} textAlign="center">
        <MDTypography variant="button" color="text">
          Уже есть учетная запись?{" "}
          <MDTypography
            component={Link}
            to="/authentication/sign-in"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Войдите
          </MDTypography>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default RegisterForm;
