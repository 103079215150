// Material Dashboard 2 React components
import Preloader from "layouts/preloader";
import AuthorizeUser from "layouts/authentication/is-authorized/email-notice-pages";

// Requests
import { API_EMAIL_VERIFY } from "service/request";

// React
import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";

function EmailVerify() {
  const [isLoaded, setIsLoaded] = useState(false);
  const { verifyingHash } = useParams();

  useEffect(async () => {
    const result = await API_EMAIL_VERIFY(verifyingHash);
    if (result.ok) {
      setIsLoaded(true);
      return true;
    }
    setIsLoaded(true);
    return false;
  }, []);

  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      <Navigate to="/email-notification" />;
    </AuthorizeUser>
  );
}

export default EmailVerify;
