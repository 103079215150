import { API_DOMAIN } from "service/requests/requestConfig";

export default class Company {
  /**
   * Register company request
   * @param formData {object}
   */
  static async Register(formData) {
    const url = `${API_DOMAIN}api/register`;
    return fetch(url, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    });
  }

  /**
   * Get product group by id
   */
  static async GetPublicContacts(pId) {
    const url = `${API_DOMAIN}api/company/public/${pId}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const responseJson = await response.json();
    return responseJson;
  }
}
