/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// mui components
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 Components
import MDBox from "components/MDBox";
import ImageCard from "examples/Cards/ImageCard";
import MDTypography from "components/MDTypography";

// React
import { useState } from "react";

function Gallery({ images, mainImage }) {
  const [_mainImage, setMainImage] = useState({ ...mainImage });

  if (images.length !== 0) {
    return (
      <MDBox maxWidth="400px">
        <MDBox>
          <ImageCard image={_mainImage.path} />
        </MDBox>
        <MDBox display="flex">
          {images.map((image) => (
            <MDBox
              key={image.name}
              width="5em"
              m={0.3}
              sx={{ cursor: "pointer" }}
              onClick={() => setMainImage(image)}
            >
              <ImageCard image={image.path} />
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    );
  }

  return (
    <Card sx={{ display: { xs: "none", md: "block" } }}>
      <MDBox
        height="100vh"
        maxWidth="400px"
        maxHeight="400px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Icon fontSize="large">no_photography</Icon>
        <MDTypography variant="button" color="secondary" fontWeight="regular">
          У этого товара нет изображений
        </MDTypography>
      </MDBox>
    </Card>
  );
}

export default Gallery;
