// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Components
import Header from "layouts/clients/components/Header";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Preloader from "layouts/preloader";
import RentHistoryTable from "layouts/clients/components/RentHistoryTable";
import RentProductsTable from "layouts/clients/components/RentProductsTable";

// react
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Models
import Client from "service/Models/Client";

function Overview() {
  const { clientId } = useParams();
  const [client, setClient] = useState({});
  const updateClient = (value) => setClient(value);
  const [isLoaded, setIsLoaded] = useState(false);

  // get managers
  useEffect(async () => {
    const response = await Client.Read(clientId);
    if (response.success === true) {
      setClient(response.clients);
      setIsLoaded(true);
      return true;
    }
    // TODO добавить обработку ошибки
    console.log(response);
    return false;
  }, []);

  useEffect(() => {
    if (Object.keys(client).length !== 0 && client.constructor === Object)
      document.title = client.full_name;
  }, [client]);

  if (!isLoaded) return <Preloader />;

  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title={client.full_name} />
        <MDBox mb={8} />
        <Header client={client} updateClient={updateClient}>
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} xl={4} sx={{ display: "flex", justifyContent: "space-between" }}>
                <ProfileInfoCard
                  title="Информация"
                  description={client.commentary || "Нет комментария"}
                  info={{
                    имя: client.full_name,
                    телефон: client.phone || "-",
                    email: client.email || "-",
                    telegramID: client.telegram_id || "-",
                  }}
                  social={[]}
                  action={{ route: "", tooltip: "Редактировать" }}
                  shadow={false}
                />
                <Divider
                  orientation="vertical"
                  sx={{ mx: 0, display: { xs: "none", xl: "block" } }}
                />
              </Grid>
              <Grid container item xs={12} lg={8}>
                <Grid item xs={12}>
                  <MDBox pt={2} px={2}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Арендуемые товары
                    </MDTypography>
                    <RentProductsTable clientId={clientId} />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox pt={2} px={2}>
                    <MDTypography variant="h6" fontWeight="medium">
                      История аренды
                    </MDTypography>
                    <RentHistoryTable clientId={clientId} />
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Overview;
