/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// mui components
import Card from "@mui/material/Card";

// Material Dashboard 2 Components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import BasicLayout from "examples/LayoutContainers/PageLayout";
import GreetingsNavbar from "examples/Navbars/GreetingsNavbar";
import ProductInfo from "layouts/products/components/C.ProductInfo";
import Preloader from "layouts/preloader";

// React
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// Models
import Product from "service/Models/Product";
import Company from "service/Models/Company";

// Yandex metrika
import { YMInitializer } from "react-yandex-metrika";

function CustomerProductPage() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getProduct = async () => {
      const response = await Product.GetTotal(id);
      if (response.json.success === true) {
        const productJson = response.json.products;
        if (productJson.active === true && productJson.company_active === true) {
          const nProduct = productJson;
          const contactsResponse = await Company.GetPublicContacts(nProduct.group.company_id);
          if (contactsResponse.success === true) {
            nProduct.contacts = contactsResponse.contacts;
          }
          setProduct(nProduct);
          setIsLoaded(true);
        } else {
          navigate("/greetings");
        }
        return true;
      }
      // TODO добавить обработку ошибки
      console.log(response);
      navigate("/greetings");
      return false;
    };

    getProduct();
  }, []);

  return (
    <BasicLayout>
      <YMInitializer
        accounts={[93423186]}
        options={{ clickMap: true, trackLinks: true, accurateTrackBounce: true }}
      />
      <GreetingsNavbar searchProps={{}} mainLink />
      {isLoaded ? (
        <MDBox
          py={1}
          px={{ xs: 1, sm: 3, lg: 4 }}
          mt={12}
          mb={8}
          mx="auto"
          borderRadius="lg"
          maxWidth="lg"
        >
          <Card>
            <MDBox py={3} px={2}>
              <MDTypography variant="h5">Информация о товаре</MDTypography>
              <ProductInfo productData={product} />
            </MDBox>
          </Card>
        </MDBox>
      ) : (
        <Preloader />
      )}
    </BasicLayout>
  );
}

export default CustomerProductPage;
