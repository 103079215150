const getCurrentDate = () => {
  const jsDate = new Date();
  const year = jsDate.getFullYear();
  const month = jsDate.getMonth() + 1;
  const day = jsDate.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

const inputs = {
  transaction_type: {
    defaultValue: {
      label: "Пополнение",
      id: 0,
    },
    validationNeeded: false,
    options: [
      {
        label: "Пополнение",
        id: 0,
      },
      {
        label: "Списание",
        id: 1,
      },
    ],
  },
  value: {
    defaultValue: "0.0",
    validationNeeded: true,
    validationRules: ["between:0.01, 999999.99", "fixed:2", "required"],
    attribute: "'Сумма'",
  },
  commentary: {
    defaultValue: "",
    validationNeeded: true,
    attribute: "'Комментарий'",
    validationRules: ["min:1", "max:100", "required"],
  },
  products: {
    defaultValue: [],
    validationNeeded: false,
  },
  date: {
    defaultValue: getCurrentDate(),
    validationNeeded: false,
  },
  client_id: {
    defaultValue: null,
    validationNeeded: false,
  },
};

export default inputs;
