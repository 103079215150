// mui components
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";

// Models
import Rent from "service/Models/Rent";
import RentObject from "service/Models/RentObject";

// Components
import Preloader from "layouts/preloader";
import ObjectStatus from "layouts/rents/components/ObjectStatus";
import RenewalObjectForm from "layouts/rents/components/RenewalObjectForm";
import HistoryTimeline from "layouts/rents/components/HistoryTimeline";
import Header from "layouts/rents/components/Header";
import ClientCard from "layouts/cash/components/clientCard";
import CustomDialog from "examples/Dialog";

// react
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// utilities
import { dateWord, reduceAccByColName } from "service/utilities";
import { useMaterialUIController } from "context";

function RentPage() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { rentId } = useParams();
  const [rent, setRent] = useState({});
  const updateRent = (value) => setRent(value);
  const [dialogInfo, setDialogInfo] = useState(null);
  const [openRenewal, setOpenRenewal] = useState(-1);

  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  // get rent info
  useEffect(async () => {
    const getRent = async () => {
      const response = await Rent.Read(rentId);
      if (response.success === true) return response.rents;
      throw new Error(response);
    };
    getRent()
      .then((response) => {
        document.title = `Договор №${response.id}`;
        setRent(response);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error(error);
        navigate(`/crm/rents`);
      });
  }, []);

  const returnProductRequest = async (object) => {
    const response = await RentObject.Update(object);
    if (response.success === true) return response;
    console.log(response);
    return null;
  };
  const handleProductReturn = (object) => {
    setDialogInfo({
      content: (
        <DialogContent>
          <DialogContentText>Отметить товар как возвращенный?</DialogContentText>
        </DialogContent>
      ),
      agreeText: "Подтвердить",
      agreeColor: "success",
      disagreeColor: "light",
      agreeAction: () => {
        // eslint-disable-next-line no-param-reassign
        object.is_returned = true;
        returnProductRequest(object).then((response) => {
          if (response !== null) setRent({ ...rent });
        });
      },
      disagreeAction: () => setDialogInfo(null),
    });
  };

  const handleChangeRenewal = (objectId) => {
    if (objectId === openRenewal) setOpenRenewal(-1);
    else setOpenRenewal(objectId);
  };

  const handleProductDelete = (objectId) => {
    setDialogInfo({
      content: (
        <DialogContent>
          <DialogContentText>
            Вы действительно хотите убрать данный товар из договора бронирования?
          </DialogContentText>
        </DialogContent>
      ),
      agreeText: "Убрать",
      agreeColor: "error",
      disagreeColor: "light",
      agreeAction: async () => {
        const response = await RentObject.Delete(objectId);
        if (response) {
          const copyRent = { ...rent };
          rent.objects.forEach((object, index) => {
            if (object.id === objectId) copyRent.objects.splice(index, 1);
          });
          setRent(copyRent);
          return true;
        }
        console.log(response);
        return false;
      },
      disagreeAction: () => setDialogInfo(null),
    });
  };

  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title={`Договор №${rentId}`} />
        <Header rent={rent} updateRentCallback={updateRent}>
          {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
          <Divider light={darkMode} />
          {rent.objects.length !== 0 && (
            <MDTypography variant="h6" fontWeight="medium">
              Товары
            </MDTypography>
          )}
          {rent.objects
            .filter((object) => object.is_actual)
            .map((object) => (
              <MDBox
                display="flex"
                flexWrap="wrap"
                key={object.product.name}
                mb={2}
                justifyContent="space-between"
              >
                <MDBox display="flex">
                  <MDAvatar
                    src={object.product.main_image ? object.product.main_image.path : ""}
                    alt="main-image"
                    size="xxl"
                    shadow="sm"
                    bgColor="light"
                  >
                    <Icon fontSize="lg" color="disabled">
                      no_photography
                    </Icon>
                  </MDAvatar>
                  <MDBox p={1} display="flex" flexDirection="column">
                    <MDTypography
                      component={Link}
                      to={`/crm/products/${object.product.id}`}
                      variant="h6"
                      fontWeight="medium"
                    >
                      {object.product.name}
                    </MDTypography>
                    <MDTypography
                      component={Link}
                      to={`/crm/groups/${object.product.id}`}
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {object.product.group.name}
                    </MDTypography>
                    <ObjectStatus object={object} rent={rent} />
                  </MDBox>
                </MDBox>
                <MDBox py={1}>
                  {rent.objects
                    .filter((pObject) => pObject.product_id === object.product_id)
                    .map((pObject) => (
                      <MDBox display="flex" flexDirection="column" key={pObject.id}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          До {dateWord(pObject.return_date)}:
                        </MDTypography>
                        <MDTypography variant="button" fontWeight="bold">
                          {pObject.price} руб.
                        </MDTypography>
                      </MDBox>
                    ))}
                </MDBox>
                <MDBox p={1} display="flex" flexDirection="column" alignItems="right">
                  {/* Дата возврата */}
                  <MDTypography variant="h6" fontWeight="medium">
                    Дата возврата: {dateWord(object.return_date)}
                  </MDTypography>
                  {/* Функиональные кнопки объекта аренды */}
                  {/* Кнопка возврата товара */}
                  <MDButton
                    sx={{ marginTop: 1 }}
                    variant="gradient"
                    size="small"
                    color="success"
                    onClick={() => handleProductReturn(object)}
                    disabled={object.is_returned || !rent.issue_date}
                  >
                    <Icon fontSize="small">replay</Icon>&nbsp; Товар возвращен
                  </MDButton>
                  {/* Кнопка открытия поля выбора кол-ва дней продления */}
                  <MDButton
                    sx={{ marginTop: 1 }}
                    variant="gradient"
                    size="small"
                    color="info"
                    onClick={() => handleChangeRenewal(object.id)}
                    disabled={object.is_returned || !rent.issue_date}
                  >
                    <Icon fontSize="small">alarm_add</Icon>&nbsp; Продлить
                  </MDButton>
                  {/* Поле выбора кол-ва дней продления */}
                  <RenewalObjectForm
                    open={openRenewal}
                    rent={rent}
                    object={object}
                    onSuccess={updateRent}
                  />
                  {/* Кнопка удаления товара */}
                  <Collapse
                    in={
                      rent.is_booking &&
                      !rent.issue_date &&
                      !rent.is_cancel &&
                      rent.objects.length > 1
                    }
                  >
                    <MDButton
                      sx={{ marginTop: 1, width: "100%" }}
                      variant="gradient"
                      size="small"
                      color="error"
                      onClick={() => handleProductDelete(object.id)}
                    >
                      <Icon fontSize="small">delete</Icon>&nbsp; Убрать
                    </MDButton>
                  </Collapse>
                </MDBox>
              </MDBox>
            ))}
          {rent.client_id && (
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium">
                Клиент
              </MDTypography>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <ClientCard client={rent.client} noGutter />
              </MDBox>
            </MDBox>
          )}
          {/* История операций */}
          <Divider light={darkMode} />
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              История действий
            </MDTypography>
            <HistoryTimeline rent={rent} />
          </MDBox>
          {rent.objects.length !== 0 && <Divider light={darkMode} />}
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              Итог
            </MDTypography>
            <MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="body1" fontWeight="regular" color="text">
                  Всего:
                </MDTypography>
                <MDTypography variant="body1" fontWeight="bold">
                  {reduceAccByColName(rent.objects, "price") + parseFloat(rent.shipping_cost || 0)}
                  &nbsp;руб.
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Header>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default RentPage;
