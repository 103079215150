// тут собраны всякие полезные функции

// функция проверки объекта на пустоту
const objectIsNotEmpty = (pObject) =>
  !(Object.keys(pObject).length === 0 && pObject.constructor === Object);

// функция проверки наличия XSRF-токена
const isXSRFExist = () => document.cookie.indexOf("XSRF-TOKEN=") !== -1;

// функция извлечения XSRF-токена из cookie
const retrieveXSRF = () => document.cookie.split("XSRF-TOKEN=")[1].split("%3D")[0];

// функция получения текущей даты в виде строки формата гггг-мм-дд
const getCurrentDate = () => new Date().toISOString().split("T")[0];

// функция сравнения двух дат в виде строк формата гггг-мм-дд
const compareDates = (date1, sign, date2) => {
  const msDate1 = new Date(date1).getTime();
  const msDate2 = new Date(date2).getTime();
  if (sign === "=") return msDate1 === msDate2;
  if (sign === ">") return msDate1 > msDate2;
  if (sign === "<") return msDate1 < msDate2;
  if (sign === "<>") return msDate1 !== msDate2;
  if (sign === "<=") return msDate1 <= msDate2;
  if (sign === ">=") return msDate1 >= msDate2;
  return false;
};

// функция сдвига даты
const shiftDate = (date, yearShift = 0, monthShift = 0, dayShift = 0) => {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + yearShift);
  dateCopy.setMonth(dateCopy.getMonth() + monthShift);
  dateCopy.setDate(dateCopy.getDate() + dayShift);
  return new Date(dateCopy).toISOString().split("T")[0];
};

// функция добавления обозначения дней в правильном склонении
const numDays = (value) => {
  const prepVal = Math.abs(value) % 100;
  const num = value % 10;
  if (value === 7) return "дней (неделя)";
  if (value === 14) return "дней (2 недели)";
  if (value === 21) return "день (3 недели)";
  if (value === 28) return "дней (месяц)";
  if (prepVal > 10 && prepVal < 20) return "дней";
  if (num > 1 && num < 5) return "дня";
  if (num === 1) return "день";
  return "дней";
};

// функция преобразования даты формата гггг-мм-дд к человекочитаемой строке
const dateWord = (date) => {
  const [year, month, day] = date.split("-");
  const monthWords = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];
  return `${day} ${monthWords[parseInt(month, 10) - 1]} ${year}`;
};

const dateTimeWord = (dateTime) => {
  const [date, time] = dateTime.split("T");
  return `${dateWord(date)} ${time}`;
};

const trimSQLFormatTime = (sqlDate) => sqlDate.split("T")[0];

const reduceAccByColName = (array, colName) =>
  array.reduce((acc, iterate) => acc + parseFloat(iterate[colName]), 0);

export {
  objectIsNotEmpty,
  isXSRFExist,
  retrieveXSRF,
  getCurrentDate,
  compareDates,
  shiftDate,
  numDays,
  dateWord,
  trimSQLFormatTime,
  reduceAccByColName,
  dateTimeWord,
};
