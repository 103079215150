// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// utilities
import { numDays } from "service/utilities";

// PropTypes
import PropTypes from "prop-types";

export default function data(rent) {
  const rows = [];
  rent.products.forEach((object, i) => {
    rows[i] = {
      name: (
        <MDTypography variant="body2" color="text">
          {object.name}
        </MDTypography>
      ),
      days: (
        <MDTypography variant="body2" color="text">
          {object.currentPrice.days} {numDays(parseInt(object.currentPrice.days, 10))}
        </MDTypography>
      ),
      price: (
        <MDTypography variant="body2" color="text">
          {object.currentPrice.price}
        </MDTypography>
      ),
    };
  });

  if (rent.shipping_cost) {
    rows[rows.length] = {
      name: (
        <MDTypography variant="body2" color="text">
          Доставка
        </MDTypography>
      ),
      days: (
        <MDTypography variant="body2" color="text">
          —
        </MDTypography>
      ),
      price: (
        <MDTypography variant="body2" color="text">
          {parseFloat(rent.shipping_cost)}
        </MDTypography>
      ),
    };
  }

  return {
    columns: [
      { Header: "Товар", accessor: "name", width: "50%", align: "left" },
      { Header: "Срок", accessor: "days", align: "left" },
      { Header: "Стоимость", accessor: "price", align: "right" },
    ],
    rows,
  };
}

data.PropTypes = {
  rentObjects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      currentPrice: PropTypes.shape({
        days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }).isRequired,
    })
  ).isRequired,
};
