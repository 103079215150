// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Filter from "examples/Filter";

// Data
import productsTableData from "layouts/products/data/productsTableData";
import productsFiltersList from "layouts/products/data/productsFiltersList";

// React
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import CustomDialog from "examples/Dialog";
import Preloader from "layouts/preloader";

// Models
import ProductGroup from "service/Models/ProductGroup";
import Product from "service/Models/Product";

function Products() {
  const [dialogInfo, setDialogInfo] = useState(null);
  const updateDialogInfo = (value) => setDialogInfo(value);

  const [isLoaded, setIsLoaded] = useState(false);
  const [searchField, setSearchField] = useState("");
  const navigate = useNavigate();
  const [filters, setFilters] = useState(JSON.parse(JSON.stringify(productsFiltersList)));
  const updateFiltersCallback = (value) => setFilters(value);

  const [products, setProducts] = useState([]);
  const [groups, setGroups] = useState([]);
  const updateProductCallback = (value) => {
    setProducts(value);
    setFilters([...filters]);
  };

  // filter out
  useEffect(() => {
    // reset the visibility of all products
    products.forEach((product, index) => {
      products[index].view = false;
    });

    if (searchField !== "") {
      products.forEach((product, index) => {
        if (product.name.toLowerCase().indexOf(searchField.toLowerCase()) !== -1)
          products[index].view = true;
      });
    } else {
      products.forEach((product, index) => {
        products[index].view = true;
      });
    }

    products.forEach((product, index) => {
      if (product.view === true) {
        if (filters[0].value.id === 0) products[index].view = true;
        else if (product.product_group_id === filters[0].value.id) products[index].view = true;
        else products[index].view = false;
      }
    });

    products.forEach((product, index) => {
      if (product.view === true) {
        if (filters[1].value.id === 0 && !product.deleted_at) products[index].view = true;
        else if (filters[1].value.id === 1 && product.active && !product.deleted_at)
          products[index].view = true;
        else if (filters[1].value.id === 2 && !product.active && !product.deleted_at)
          products[index].view = true;
        else if (filters[1].value.id === 3 && product.deleted_at) products[index].view = true;
        else products[index].view = false;
      }
    });

    products.forEach((product, index) => {
      if (product.view === true) {
        if (filters[2].value.id === 0) products[index].view = true;
        else if (
          product.age_range_bottom <= filters[2].value.id &&
          product.age_range_top >= filters[2].value.id
        )
          products[index].view = true;
        else products[index].view = false;
      }
    });

    products.forEach((product, index) => {
      if (product.view === true) {
        if (filters[3].value.id === 0) products[index].view = true;
        else if (filters[3].value.id === 1 && product.ready) products[index].view = true;
        else if (filters[3].value.id === 2 && !product.ready) products[index].view = true;
        else products[index].view = false;
      }
    });

    setProducts([...products]);
  }, [filters]);

  const handleInputChange = (event) => {
    setSearchField(event.target.value);
    setFilters([...filters]);
  };

  const handleResetFilters = () => {
    setSearchField("");
    const nFilters = JSON.parse(JSON.stringify(productsFiltersList));
    nFilters[0].options = [...groups];
    setFilters(nFilters);
  };

  useEffect(() => {
    const getProducts = async () => {
      const response = await Product.GetAll();
      if (response.success === true) return response;
      throw new Error(response);
    };

    const getGroups = async () => {
      const response = await ProductGroup.GetAll();
      if (response.success === true) return response;
      throw new Error(response);
    };

    Promise.all([getProducts(), getGroups()])
      .then((responses) => {
        setProducts(responses.filter((response) => response.products !== undefined)[0].products);

        // TODO переместить обработку групп для фильтров в модуль фильтров
        const entityGroups = responses
          .filter((response) => response.groups !== undefined)[0]
          .groups.map(({ id, name }) => ({ id, label: name }));
        filters[0].options = [...filters[0].options, ...entityGroups];
        setGroups(entityGroups);

        setIsLoaded(true);
        setFilters([...filters]);
      })
      .catch((error) => console.error(error));
  }, []);

  const { columns, rows } = productsTableData(products, updateProductCallback, updateDialogInfo);

  const onCellClick = (cell) => {
    const row = rows[cell.row.index];
    const accessor = cell.column.id;
    if (row[accessor].link) navigate(row[accessor].link);
  };

  return (
    <AuthorizeUser>
      {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
      <DashboardLayout>
        <DashboardNavbar title="Все товары" />
        <MDBox pt={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Товары
                  </MDTypography>
                  <Link to="/crm/products/create">
                    <MDButton variant="outlined" color="white" size="small">
                      <Icon>add</Icon>&nbsp;Добавить товар
                    </MDButton>
                  </Link>
                </MDBox>
                <MDBox>
                  <Grid
                    container
                    px={3}
                    mt={3}
                    columnSpacing={2}
                    rowSpacing={0.5}
                    alignItems="flex-end"
                  >
                    <Grid item xs={12} textAlign="right">
                      <MDButton variant="outlined" size="small" sx={{ padding: "0" }}>
                        <Icon color="error">refresh</Icon>&nbsp;
                        <MDTypography
                          fontWeight="medium"
                          variant="caption"
                          color="error"
                          onClick={handleResetFilters}
                          sx={{ cursor: "pointer" }}
                        >
                          Сбросить фильтры
                        </MDTypography>
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        name="searchField"
                        label="Поиск по названию"
                        type="text"
                        size="small"
                        sx={{ width: "100%" }}
                        value={searchField}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Filter
                        widthParams={{ xs: 12, md: 3 }}
                        filters={filters}
                        onChangeFilters={updateFiltersCallback}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox>
                  {!isLoaded ? (
                    <Preloader fullScreen={false} />
                  ) : (
                    <DataTable
                      entriesPerPage={{
                        defaultValue: 20,
                        entries: [15, 20, 25, 50, 100],
                      }}
                      table={{ columns, rows }}
                      showTotalEntries={false}
                      isSorted={false}
                      onCellClick={onCellClick}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default Products;
