/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/GreetingsNavbar/DefaultNavbarLink";

function DefaultNavbarMobile({ open, close, light, mainLink, contacts, signIn }) {
  const { width } = open && open.getBoundingClientRect();

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <Grid container p={0.5}>
        <Grid
          item
          xs={12}
          order={{ xs: 1, sm: 2 }}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          sx={{ zIndex: 0 }}
        >
          {mainLink && <DefaultNavbarLink icon="home" name="На главную" route="/" light={light} />}
          {contacts && (
            <DefaultNavbarLink light={light} icon="phone" name="Контакты" route="/contacts" />
          )}
          {signIn && <DefaultNavbarLink light={light} icon="key" name="Войти" route="/crm" />}
        </Grid>
      </Grid>
    </Menu>
  );
}

// Setting default values for the props of DefaultNavbarMobile
DefaultNavbarMobile.defaultProps = {
  light: false,
  mainLink: false,
  contacts: true,
  signIn: true,
};

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
  light: PropTypes.bool,
  mainLink: PropTypes.bool,
  contacts: PropTypes.bool,
  signIn: PropTypes.bool,
};

export default DefaultNavbarMobile;
