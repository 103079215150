// react
import { useState } from "react";

// validate
import validate from "service/Validate/validate";

/**
 * use text input hook
 * @param id {string}
 * @param validators {[Validators]}
 * @param init {string}
 */
export default function useTextInput(id, validators, init = "") {
  const [value, setValue] = useState(init);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeInput = async (event) => {
    const val = event.target.value;

    setValue(val);
    setIsDirty(true);
    setError(await validate(val, validators));
  };

  const handleBlur = async (event) => {
    const val = event.target.value;

    setError(await validate(val, validators));
  };

  return {
    id,
    value,
    error,
    handleBlur,
    handleChangeInput,
    isDirty,
  };
}
