// react
import { useState } from "react";

export default function useForm(fields, apiCall, onSuccess, onFailure) {
  const [isSending, setIsSending] = useState(false);
  const [sendingError, setSendingError] = useState(null);

  const handleSubmit = async () => {
    const errors = await Promise.all(fields.map((field) => field.error));
    const isFormValid = errors.every((error) => !error);

    if (isFormValid) {
      setIsSending(true);
      setSendingError(null);

      const formData = {};
      fields.forEach((field) => {
        formData[field.id] = field.value;
      });

      apiCall(formData)
        .then((response) => {
          if (!response.ok) return Promise.reject(response);
          return response.json();
        })
        .then((json) => {
          onSuccess(json);
        })
        .catch((err) => {
          // handle validation errors
          if (err.status === 422) err.json().then((json) => setSendingError(json.errors));
          // handle server errors
          else if (err.status >= 500)
            err.json().then((json) =>
              setSendingError(
                json.errors || {
                  server_error: ["Возникли проблемы на сервере. Попробуйте позже."],
                }
              )
            );
          onFailure(err);
        })
        .finally(() => setIsSending(false));
    }
  };

  const hasFieldsErrors = fields.some((field) => !!field.error);

  const isAllDirty = fields.every((field) => field.isDirty);

  return { isSending, sendingError, handleSubmit, hasFieldsErrors, isAllDirty };
}
