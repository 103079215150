/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Preloader from "layouts/preloader";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// Валидатор
import { validateRegExps, validate } from "service/validator";

// Запросы
import { API_LOGIN, RETRIEVE_TOKEN, API_GET_USER } from "service/request";

import { objectIsNotEmpty } from "service/utilities";

function Basic() {
  const [user, setUser] = useState({});

  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [email, setEmail] = useState("");
  const handleEmailChanged = (event) => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const handlePasswordChanged = (event) => {
    setPassword(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handlerCheckboxChanged = () => {
    setShowPassword(!showPassword);
    if (!showPassword) setPasswordType("text");
    else setPasswordType("password");
  };

  const [formError, setFormError] = useState(false);
  const [formState, setFormState] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // future functional
  // const [criticalError, setCriticalError] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await API_LOGIN(email, password, rememberMe);
    if (result.success === false) {
      setFormError(true);
      return false;
    }
    localStorage.setItem("API_TOKEN", result.access_token);
    setUser(result.user);
    return true;
  };

  useEffect(() => {
    setFormState(email && password);
  });

  useEffect(async () => {
    const bearerToken = RETRIEVE_TOKEN();
    // if bearerToken is null stop Preloader and show login form
    if (bearerToken === null) {
      setIsLoaded(true);
      return false;
    }
    // if user have bearerToken trying get user data from server
    const userDataJSON = await API_GET_USER(bearerToken);
    setUser(userDataJSON);
    setIsLoaded(true);
    return true;
  });

  // if loaded complete
  if (!isLoaded) {
    return <Preloader />;
  }
  // if user not empty
  if (objectIsNotEmpty(user)) {
    // if user verified
    if (user.email_verified_at !== null) {
      return <Navigate to="/crm" />;
    }
    // if user not verified
    return <Navigate to="/email-notification" />;
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Вход в систему
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            {formError && (
              <MDTypography color="error" variant="subtitle2" sx={{ fontSize: "caption.fontSize" }}>
                Не найдено записи с такими данными.
              </MDTypography>
            )}
            <MDBox mb={2} mt={2}>
              {validate(email, validateRegExps.email) ? (
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  onChange={handleEmailChanged}
                  success
                />
              ) : (
                <MDInput type="email" label="Email" fullWidth onChange={handleEmailChanged} error />
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked={showPassword} onClick={handlerCheckboxChanged} />
              <MDTypography
                variant="caption"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Показать пароль&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              {validate(password, validateRegExps.password) ? (
                <MDInput
                  type={passwordType}
                  label="Пароль"
                  fullWidth
                  onChange={handlePasswordChanged}
                  success
                />
              ) : (
                <MDInput
                  type={passwordType}
                  label="Пароль"
                  fullWidth
                  onChange={handlePasswordChanged}
                  error
                />
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Запомнить меня
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {formState ? (
                <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                  войти
                </MDButton>
              ) : (
                <MDButton variant="gradient" color="info" fullWidth disabled>
                  войти
                </MDButton>
              )}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Нет учетной записи?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Зарегистрироваться
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
