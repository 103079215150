// mui components
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@mui/material/Alert";

// material dashboard 2 components
import MDTypography from "components/MDTypography";

// Components
import CustomDialog from "examples/CustomDialog";

// proptypes
import PropTypes from "prop-types";

// utilities
import { reduceAccByColName } from "service/utilities";

// data
import getRentStatus from "layouts/rents/data/getRentStatus";

// Models
import Rent from "service/Models/Rent";

function DeleteRentDialog({ open, rent, onAgreeSuccess, onAgreeFailure, onCancel, onClose }) {
  const deleteRentDialogContent = () => (
    <DialogContent>
      <DialogContentText mb={1}>
        Вы действительно хотите удалить данный договор? Все данные связанные с договором будут
        удалены.
      </DialogContentText>
      {getRentStatus(rent) === "booked" && reduceAccByColName(rent.cash, "value") && (
        <Alert severity="error" icon={false}>
          <MDTypography
            color="error"
            variant="subtitle2"
            fontWeight="medium"
            sx={{ fontSize: "caption.fontSize" }}
          >
            У договора бронирования есть связанные операции в кассе. Если вы хотите сохранить эти
            операции в кассе, то&nbsp;
            <MDTypography
              color="error"
              variant="subtitle2"
              fontWeight="medium"
              sx={{ fontSize: "caption.fontSize", cursor: "pointer", textDecoration: "underline" }}
              component="span"
              onClick={onCancel}
            >
              отмените
            </MDTypography>
            &nbsp;бронирование не удаляя его.
          </MDTypography>
        </Alert>
      )}
    </DialogContent>
  );

  const deleteRentRequest = () =>
    Rent.Delete(rent.id).then((response) => {
      if (response === true) return true;
      throw new Error(response);
    });

  const freshDialogInfo = () => {
    const data = {
      content: deleteRentDialogContent(),
      title: "Подтвердите действие",
      buttons: [
        {
          name: "Отменить",
          color: "light",
          action: () => {
            onClose();
          },
        },
        {
          name: "Перейти к отмене",
          color: "error",
          action: () => {
            onCancel();
          },
        },
        {
          name: "Подтвердить",
          color: "error",
          action: () => {
            deleteRentRequest()
              .then(onAgreeSuccess)
              .catch((err) => {
                onAgreeFailure(err);
                console.error(err);
              });
          },
        },
      ],
      onClose: () => {
        onClose();
      },
    };
    if (!(getRentStatus(rent) === "booked" && reduceAccByColName(rent.cash, "value"))) {
      data.buttons.splice(1, 1);
    }
    return data;
  };

  return <CustomDialog open={open} {...freshDialogInfo()} />;
}

// CancelRentDialog default prop-types
DeleteRentDialog.defaultProps = {
  onAgreeSuccess: () => {},
  onAgreeFailure: () => {},
};

// CancelRentDialog prop-types
DeleteRentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
  }).isRequired,
  onAgreeSuccess: PropTypes.func,
  onAgreeFailure: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteRentDialog;
