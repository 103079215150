// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthorizeUser from "layouts/authentication/is-authorized/protected-pages";
import Preloader from "layouts/preloader";
import ProductGroupForm from "layouts/product_groups/components/ProductGroupForm";

// Models
import ProductGroup from "service/Models/ProductGroup";

// React
import { useState, useEffect } from "react";
import { useParams, Navigate, useLocation } from "react-router-dom";

function ProductGroups() {
  const [productGroup, setProductGroup] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [criticalError, setCriticalError] = useState(false);
  const [route, setRoute] = useState(useLocation().pathname.split("/").slice(2));
  // get product id from url param
  const { groupId } = useParams();

  // get product group info
  useEffect(async () => {
    const response = await ProductGroup.GetById(groupId);
    if (response.success === true) {
      setProductGroup(response.groups);
      setIsLoaded(true);
      route[route.indexOf(groupId)] = {
        name: response.groups.name,
        linkTo: `crm/groups/${groupId}`,
      };

      setRoute([...route]);

      document.title = "Обновить группу";
      return true;
    }
    setCriticalError(true);
    setIsLoaded(true);
    return false;
  }, []);

  if (criticalError) {
    return <Navigate to="/crm/groups" />;
  }

  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <AuthorizeUser>
      <DashboardLayout>
        <DashboardNavbar title="Обновить" _route={route} />
        <MDBox>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card id="delete-account">
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Обновление группы товаров
                  </MDTypography>
                </MDBox>
                <ProductGroupForm productGroup={productGroup} isUpdateAction />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
    </AuthorizeUser>
  );
}

export default ProductGroups;
