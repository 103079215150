// mui components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Alert from "@mui/material/Alert";

// material dashboard 2 components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Components
import CustomDialog from "examples/Dialog";

// proptypes
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";

// utilities
import { getCurrentDate, reduceAccByColName } from "service/utilities";

// Validator
import { Validate } from "service/validator";

// Models
import Cash from "service/Models/Cash";

function AddPaymentToRent({ open, rent, updateRentCallback }) {
  const [dialogInfo, setDialogInfo] = useState(null);
  const freshPayment = () => {
    const maxValue =
      reduceAccByColName(rent.objects, "price") +
      parseFloat(rent.shipping_cost || 0) -
      reduceAccByColName(rent.cash, "value");
    return {
      max_value: maxValue,
      value: maxValue,
      is_valid: true,
      message: [],
      is_dirty: true,
    };
  };
  const [formState, setFormState] = useState({ is_dirty: false });

  const paymentRequest = async () => {
    const response = await Cash.Create({
      date: getCurrentDate(),
      note: `Оплата по договору №${rent.id}`,
      value: formState.value,
      rent_id: rent.id,
    });
    if (response.success === true) return response.cash;
    throw new Error(response);
  };

  const handlePaymentChange = (event) => {
    const { value } = event.target;
    const validationErrors = Validate(
      value,
      [`between:0.01,${formState.max_value}`, "fixed:2", "required"],
      "'Оплата'"
    );
    setFormState({
      ...formState,
      value,
      is_valid: validationErrors.length === 0,
      message: validationErrors,
      is_dirty: true,
    });
  };

  const freshDialogInfo = () => ({
    content: (
      <DialogContent>
        <DialogContentText>Внести плату в размере:</DialogContentText>
        <Collapse in={!formState.is_valid}>
          <Alert severity="error" icon={false}>
            {formState.message.map((error) => (
              <MDTypography
                color="error"
                variant="subtitle2"
                fontWeight="medium"
                sx={{ fontSize: "caption.fontSize" }}
                key={error}
              >
                {error}
              </MDTypography>
            ))}
          </Alert>
        </Collapse>
        <MDInput
          name="payment"
          type="number"
          label="Плата"
          sx={{ width: "100%", marginTop: 2 }}
          size="small"
          value={formState.value}
          onChange={handlePaymentChange}
          success={formState.is_valid}
          error={!formState.is_valid}
        />
      </DialogContent>
    ),
    agreeText: "Внести",
    agreeColor: "success",
    disagreeColor: "light",
    agreeAction: () => {
      if (formState.is_valid)
        paymentRequest()
          .then((response) => {
            const newRent = { ...rent };
            newRent.cash[rent.cash.length] = response;
            updateRentCallback({ ...newRent });
          })
          .catch((error) => console.log(error));
    },
    disagreeAction: () => {
      setFormState({ ...formState, is_dirty: false });
      setDialogInfo(null);
    },
  });

  useEffect(() => {
    if (formState.is_dirty) setDialogInfo(freshDialogInfo());
  }, [formState]);

  return (
    <Collapse in={open}>
      {Boolean(dialogInfo) && <CustomDialog {...dialogInfo} />}
      <MDButton
        sx={{ marginBottom: 1, width: "100%" }}
        variant="gradient"
        size="small"
        color="info"
        onClick={() => setFormState(freshPayment())}
      >
        <Icon fontSize="small">paid</Icon>&nbsp; Внести плату по договору
      </MDButton>
    </Collapse>
  );
}

// RenewalObjectForm prop-types
AddPaymentToRent.propTypes = {
  open: PropTypes.bool.isRequired,
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rent_date: PropTypes.string.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
    shipping_cost: PropTypes.string.isRequired,
  }).isRequired,
  updateRentCallback: PropTypes.func.isRequired,
};

export default AddPaymentToRent;
