// Mui components
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import AlertTitle from "@mui/material/AlertTitle";

// Material Dashboard 2 components
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// React
import { useState } from "react";

// inputs
import inputs from "layouts/products/data/ModalPriceFormInputs";

// validate func
import { Validate } from "service/validator";

// propTypes
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";

// models
import Price from "service/Models/Price";

// function ModalPriceForm({ isUpdateAction, price, formValidCallback }) {
function PriceForm({ productData, updateProductDataCallback, priceInfo, closeAction }) {
  // copy of product to changes
  const productCopy = { ...productData };

  // construct form validation state element
  const formValidationElement = (validationErrorArray = [], isDirty = false) => ({
    isValid: validationErrorArray.length === 0,
    validationErrorMessage: validationErrorArray,
    isDirty,
  });

  // form states and variables
  const [formState, setFormState] = useState(() => {
    const freshFormStateObject = {};
    const inputsNames = Object.keys(inputs);
    // check price info
    if (priceInfo.price.id === undefined) {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = inputs[inputName].defaultValue;
      });
    } else {
      inputsNames.forEach((inputName) => {
        freshFormStateObject[inputName] = priceInfo.price[inputName];
      });
    }
    freshFormStateObject.price_type = priceInfo.price.price_type || priceInfo.type;
    if (freshFormStateObject.price_type === 2) freshFormStateObject.days = null;
    return freshFormStateObject;
  });
  const [formValidation, setFormValidation] = useState(() => {
    const freshFormValidationObject = {};
    // inital validate for inputs needed validation
    Object.keys(inputs).forEach((inputName) => {
      if (inputs[inputName].validationNeeded) {
        const errorsArray = Validate(
          priceInfo.price[inputName] || inputs[inputName].defaultValue,
          inputs[inputName].validationRules,
          inputs[inputName].attribute || inputName
        );
        freshFormValidationObject[inputName] = formValidationElement(errorsArray, false);
      }
    });
    if (priceInfo.type === 2 || priceInfo.price.price_type === 2) {
      freshFormValidationObject.days = formValidationElement([], false);
    }
    return freshFormValidationObject;
  });
  const [serverError, setServerError] = useState({});

  // change input value and validation if it needed
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (inputs[name].validationNeeded) {
      const errorsArray = Validate(value, inputs[name].validationRules, inputs[name].attribute);
      setFormValidation({ ...formValidation, [name]: formValidationElement(errorsArray, true) });
    }
    setFormState({ ...formState, [name]: value });
  };

  // check form valid or input valid
  const isValid = (inputName = null) => {
    let valid = true;
    if (inputName === null) {
      Object.keys(formValidation).forEach((input) => {
        if (!formValidation[input].isValid) {
          valid = false;
        }
      });
    } else {
      valid = formValidation[inputName].isValid;
    }
    return valid;
  };

  // close form with disagree
  const handleWithDisagree = () => closeAction();

  // agree with form
  const handleWithAgree = async () => {
    let response;
    // if price undefined then window create new price
    if (priceInfo.price.id !== undefined) {
      response = await Price.Update(formState, productData.id, priceInfo.price.id);
    } else {
      response = await Price.Create(formState, productData.id);
    }

    // error handling
    if (response.success === undefined) {
      setServerError(response.errors);
      return false;
    }
    if (response.success !== true) {
      setServerError(response.data);
      return false;
    }

    // update product info
    if (priceInfo.price.id !== undefined) {
      productData.prices.forEach((pricesItem, pricesItemIndex) => {
        if (pricesItem.id === response.price.id)
          productCopy.prices[pricesItemIndex] = response.price;
      });
    } else {
      productCopy.prices[productCopy.prices.length] = response.price;
    }
    updateProductDataCallback(productCopy);

    // fresh form and close modal window
    closeAction();
    return true;
  };

  return (
    <Dialog
      open
      onClose={handleWithDisagree}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <MDTypography>
          {Object.keys(priceInfo.price).length === 0 ? "Создание цены цены" : "Редактирование цены"}
        </MDTypography>
      </DialogTitle>
      <Grid container px={2} spacing={2}>
        <Grid item xs={12}>
          <Collapse in={Object.keys(serverError).length !== 0}>
            <Alert severity="error">
              <AlertTitle>
                Цена не {priceInfo.price.id !== undefined ? "обновлена" : "добавлена"}
              </AlertTitle>
              {Object.keys(serverError).map((error) =>
                serverError[error].map((message) => (
                  <MDTypography
                    key={message}
                    color="error"
                    variant="subtitle2"
                    fontWeight="medium"
                    sx={{ fontSize: "caption.fontSize" }}
                  >
                    {message}
                  </MDTypography>
                ))
              )}
            </Alert>
          </Collapse>
          <Collapse in={!isValid()}>
            <Alert severity="error" icon={false}>
              {Object.keys(formValidation).map((validatedValue) => (
                <MDTypography
                  color="error"
                  variant="subtitle2"
                  fontWeight="medium"
                  sx={{ fontSize: "caption.fontSize" }}
                  key={validatedValue}
                >
                  {formValidation[validatedValue].validationErrorMessage[0]}
                </MDTypography>
              ))}
            </Alert>
          </Collapse>
        </Grid>
        {formState.price_type !== 2 && (
          <Grid item xs={3}>
            <MDInput
              name="days"
              type="number"
              label="Дни"
              sx={{ width: "100%" }}
              size="small"
              value={formState.days}
              onChange={handleInputChange}
              {...(!isValid("days") ? { error: true } : { success: true })}
            />
          </Grid>
        )}
        <Grid item {...(formState.price_type === 2 ? { xs: 12 } : { xs: 9 })}>
          <MDInput
            name="price"
            type="number"
            label="Цена"
            sx={{ width: "100%" }}
            size="small"
            value={formState.price}
            onChange={handleInputChange}
            {...(!isValid("price") ? { error: true } : { success: true })}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <MDButton onClick={handleWithDisagree}>Отмена</MDButton>
        <MDButton
          color="success"
          onClick={handleWithAgree}
          {...(!isValid() ? { disabled: true } : "")}
        >
          {Object.keys(priceInfo.price).length === 0 ? "Добавить" : "Обновить"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

// Typechecking props
PriceForm.propTypes = {
  productData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateProductDataCallback: PropTypes.func.isRequired,
  priceInfo: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.object]).isRequired,
    type: PropTypes.number.isRequired,
  }).isRequired,
  closeAction: PropTypes.func.isRequired,
};

export default PriceForm;
