// mui components
import Icon from "@mui/material/Icon";

// material dashboard 2 components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// proptypes
import PropTypes from "prop-types";

// utilities
import { reduceAccByColName } from "service/utilities";

function RentPaymentStatus({ rent }) {
  const transactions = !rent.is_cancel
    ? rent.cash
    : rent.cash.filter((transaction) => transaction.value > 0);
  if (rent.is_cancel && rent.cash.filter((transaction) => transaction.value < 0).length !== 0)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="error">
          not_interested
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Средства возвращены
        </MDTypography>
      </MDBox>
    );
  const transactionsSum = reduceAccByColName(transactions, "value");
  if (transactionsSum === 0)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="error" sx={{ transform: "rotate(45deg)" }}>
          square
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Неоплачен
        </MDTypography>
      </MDBox>
    );

  const rentTotalSum =
    reduceAccByColName(rent.objects, "price") + parseFloat(rent.shipping_cost || 0);
  if (rentTotalSum - transactionsSum > 0)
    return (
      <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
        <Icon fontSize="inherit" color="warning">
          square
        </Icon>
        &nbsp;
        <MDTypography variant="button" fontWeight="regular" color="text">
          Частично оплачен
        </MDTypography>
      </MDBox>
    );
  return (
    <MDBox display="flex" alignItems="center" sx={{ fontSize: "0.89rem" }}>
      <Icon fontSize="inherit" color="success">
        circle
      </Icon>
      &nbsp;
      <MDTypography variant="button" fontWeight="regular" color="text">
        Оплачен
      </MDTypography>
    </MDBox>
  );
}

// RenewalObjectForm prop-types
RentPaymentStatus.propTypes = {
  rent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rent_date: PropTypes.string.isRequired,
    client_id: PropTypes.number.isRequired,
    cash: PropTypes.oneOfType([PropTypes.array]).isRequired,
    objects: PropTypes.oneOfType([PropTypes.array]).isRequired,
    is_cancel: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    is_booking: PropTypes.oneOfType([PropTypes.oneOf([0, 1]), PropTypes.bool]).isRequired,
    issue_date: PropTypes.string.isRequired,
    shipping_cost: PropTypes.string.isRequired,
  }).isRequired,
};

export default RentPaymentStatus;
